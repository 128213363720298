export type KaiduDeviceStatus =
  | 'NEW'
  | 'ACTIVATED'
  | 'CONFIGURED'
  | 'ONLINE'
  | 'OFFLINE'
  | 'DEACTIVATED'
  | 'BROKEN';

export enum DEVICE_STATUS {
  NEW,
  ACTIVATED,
  CONFIGURED,
  ONLINE,
  OFFLINE,
  DEACTIVATED,
  BROKEN,
}

const len = Object.keys(DEVICE_STATUS).length;
export const DEVICE_STATUS_VALUES = Object.keys(DEVICE_STATUS).slice(len / 2);
export const DEVICE_STATUS_NUMS = Object.keys(DEVICE_STATUS).slice(0, len / 2);