import _ from 'lodash';
import {helpers} from 'utils';
import {CustomerDeviceData} from './types';
import {v4 as uuidv4} from 'uuid';
import {replaceEmptyStringWithNull} from 'utils/helper';

// return a valid preconfig item
// process: generate id & customer_device_data_id, add matched customer_id
export function addIdsToCustomerDeviceData(
  data,
  customersList,
): CustomerDeviceData {
  console.log(`addIdsToCustomerDeviceData`);
  console.log(`data: ${helpers.inspect(data)}`);
  // console.log(`customersList: ${helpers.inspect(customersList)}`);
  const found = customersList.find(
    customer => customer.customer_name === data.customer_name,
  );
  // console.log(`matched customer: ${helpers.inspect(found)}`);
  const customerID = found?.customer_id;
  const result = Object.assign({}, data, {
    customer_id: customerID,
    customer_device_data_id: uuidv4(),
    id: uuidv4(),
  });
  console.log(`result: ${helpers.inspect(result)}`);
  return result;
}

const PROPS = [
  'id',
  'customer_device_data_id',
  'customer_id',
  'building',
  'location',
  'floor',
  'wifi_ssid',
  'wifi_password',
  'customers_building_id',
  'customers_floor_id',
  'customers_location_id',
  'rssi_thresholds_id',
];

export function processSubmittedCustomerDeviceData(data) {
  const trimmed = helpers.trimObjectProperties(data);
  const replaced = replaceEmptyStringWithNull(trimmed);
  const result = _.pick(replaced, PROPS) as any;
  return result;
}
