import axios from "axios";
import {
  getErrorMessageInResponse,
  handleAxiosResultData,
} from "features/kaidu-config-server/processor";
import { handle } from "utils/helper";
import {
  CUSTOMERS_DEVICE_DATA_ITEM_API,
  CUSTOMERS_DEVICE_DATA_LIST_API,
} from "./api";
import { CustomerDeviceData } from "./types";

export async function fetchCustomerDeviceDataByCustomerName(
  customerName: string
): Promise<CustomerDeviceData[]> {
  const url = `${CUSTOMERS_DEVICE_DATA_ITEM_API}/${customerName}`;
  console.log(`fetchCustomerDeviceData called for ${customerName}`);

  const [result, resultErr] = await handle(axios.get(url));

  if (!result) {
    console.error(`fetchCustomerDeviceData failed`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  return handleAxiosResultData(result);
}

export async function fetchCustomerDeviceDataItem(id: string) {
  const url = `${CUSTOMERS_DEVICE_DATA_ITEM_API}/${id}`;
  // console.log(`fetchCustomerDeviceItem called for ${id}`);

  const [result, resultErr] = await handle(axios.get(url));

  if (!result) {
    // console.error(`fetchCustomerDeviceItem failed`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  return handleAxiosResultData(result);
}

export async function fetchCustomerDeviceDataList() {
  const [result, resultErr] = await handle(
    axios.get(CUSTOMERS_DEVICE_DATA_LIST_API)
  );

  if (!result) {
    console.error(`fetchCustomerDeviceDataList failed`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  return handleAxiosResultData(result);
}
