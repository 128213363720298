// import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ErrorMsg } from "components/atomic/ErrorMsg";
import BaseModal from "components/molecule/BaseModal";
import LoadingSpinner from "components/molecule/LoadingSpinner";
import { useDeviceLatestInfo } from "features/kaidu-config-server";
import {
  DEVICE_STATUS_MODAL,
  selectDeviceMacAddress,
  selectModal,
  selectedModal,
} from "providers/redux/deviceStatusSlice";
import DeviceListItemDetails from "./DeviceListItemDetails";

export function DeviceStatusDetailModal(props) {
  const dispatch = useDispatch();
  const mac_address = useSelector(selectDeviceMacAddress);
  const modal = useSelector(selectedModal);
  const { data, isLoading, isError } = useDeviceLatestInfo(mac_address);

  return (
    <>
      <BaseModal
        show={modal === DEVICE_STATUS_MODAL.DETAILS}
        onHide={() => dispatch(selectModal(null))}
        {...props}
        title="Device state details"
      >
        {isLoading && (
          <div className={"p-4 max-w-full"}>
            <LoadingSpinner />
          </div>
        )}
        {isError && (
          <div className={"p-4 max-w-full"}>
            <ErrorMsg text={isError?.message} />
          </div>
        )}
        {!isLoading && !isError && <DeviceListItemDetails data={data} />}
      </BaseModal>
    </>
  );
}
