import Icon from 'components/atomic/Icon';

export function InfoCard({ text, ...optionals }) {
  const { subHeadingText = 'Info', icon = 'info-circle', ...rest } = optionals;

  return (
    <div className='border-l-4 pt-2 px-2 border-blue-500'>
      <div className='text-xl mb-1'>
        <Icon name={icon} /> <span className='font-bold'>{subHeadingText}</span>
      </div>
      <p className='pl-1'>{text}</p>
    </div>
  );
}
