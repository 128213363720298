import _ from "lodash";
import axios, { AxiosResponse } from "axios";

/**
 * @description set default axios header for all axios requests
 * @param  {string} token
 */
export function setAxiosDefaultAuthHeader(token: string) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export function getAxiosDefaultAuthHeader() {
  return axios?.defaults?.headers?.common["Authorization"];
}

export function addAxiosInterceptor(errorCallback: Function) {
  axios.interceptors.response.use(
    function (response) {
      // console.log(response.request._url);
      // console.log(response?.headers);
      // console.log(response?.request?._headers?.authorization);
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // TODO: if it is invalid token error
      errorCallback(error);
      return Promise.reject(error);
    }
  );
}

export function removeAxiosAuthHeader() {
  delete axios.defaults.headers.common["Authorization"];
}

export function handleAxiosResultData<T>(axiosResponse: AxiosResponse<T>): T {
  if (!axiosResponse?.data) {
    throw new Error(`Got response from server but no data in the response`);
  }
  return axiosResponse.data;
}
