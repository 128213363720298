import { VERSION } from "lib";
import {
    clearLocalStorage,
    getLocalStorage,
    setToLocalStorage,
} from "lib/persistence/local-storage";

export function handleNewVersion() {
    const oldVersion = getLocalStorage("version");

    if (oldVersion === VERSION) return;
    clearLocalStorage();
    setToLocalStorage("version", VERSION);
}
