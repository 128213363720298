import { useMemo } from 'react';
import ProgressBar from 'components/atomic/ProgressBar';

/**
 * View handling progressbar display logic
 * 100 -> success, striped & animated when it's in progress
 */
export function FirmwareUpdateProgressbar({ currentPercent, ...rest }: { currentPercent: number;[x: string]: any; }) {
  const isRunning = useMemo(() => currentPercent > 0 && currentPercent < 100, [
    currentPercent,
  ]);

  return (
    <ProgressBar
      now={currentPercent}
      label={`${Math.floor(currentPercent)}%`}
      className='flex-grow'
      striped={isRunning}
      animated={isRunning}
      variant={currentPercent >= 100 ? 'success' : null} />
  );
}
