import { SERVER_API } from "lib/constants";
import useSWR, { SWRResponse } from "swr";
import { DeviceLatestInfo } from "types/interfaces";
import { swrFetcher } from "utils";
import { KAIDU_DEVICE_STATUS_API } from "./apis";

export function useKaiduDeviceStatusEnum(
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const { data, error } = useSWR(
    KAIDU_DEVICE_STATUS_API,
    () => swrFetcher(KAIDU_DEVICE_STATUS_API),
    options
  );
  return {
    statusEnum: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useDeviceLatestInfo(
  mac: string,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
): {
  data: DeviceLatestInfo | undefined;
  isLoading: boolean;
  isError: Error | undefined;
} {
  const { data, error }: SWRResponse<DeviceLatestInfo, Error> = useSWR(
    mac ? `${SERVER_API.BASE}/latest_device_information/${mac}` : null,
    () => swrFetcher(`${SERVER_API.BASE}/latest_device_information/${mac}`),
    options
  );
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
