import Container from '../../../components/atomic/Container';
import { useBluetoothAvailability } from 'features/ble';
import { useKaiduFirmwareList } from 'features/kaidu-config-server';
import { Spinner } from '../../../components/atomic/Spinner';
import { BLEContent } from './BLEContent';

/**
 *
 */
export function BLEPage(props) {
  //Hooks
  const { isAvailable } = useBluetoothAvailability();
  const { firmwareList, latest, isLoading } = useKaiduFirmwareList();

  const firmwareData = { firmwareList, latest };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Container>
      {/* <H2 className='mt-5 mb-3'>Bluetooth Operations</H2> */}
      <BLEContent isAvailable={isAvailable} firmwareData={firmwareData} />
    </Container>
  );
}
