import React from 'react'
import { AddUserModal } from './AddUserModal';
import { DeleteUserModal } from './DeleteUserModal';

export function ModalContainer({ modalData, customers, defaultCustomer, ...rest }) {
  const { operationType, userId, userEmail } = modalData || {};

  switch (operationType) {
    case 'add':
      return <AddUserModal customers={customers} defaultCustomer={defaultCustomer} {...rest} />;
    case 'delete':
      return <DeleteUserModal user={{ userId, userEmail }} {...rest} />;
    default:
      return <AddUserModal customers={customers} defaultCustomer={defaultCustomer} {...rest} />;
  }
}
