import {
  KaiduConfigurationsListItem,
  KaiduDeviceConfiguration,
  KaiduDevicesListItem,
  MQTTConfigurationsListItem,
} from 'features/kaidu-config-server';
import {
  getOfflineItems,
  getOnlineItems,
  IoTDeviceState,
} from 'features/kaidu-config-server/iot-cloud';
import {
  getValidKaiduDevices,
  matchDeviceListWithIoTList,
} from 'features/kaidu-config-server/composition';

export function getOnlineOfflineList(
  scannerIoTStates: IoTDeviceState[],
  kaiduDevicesList: KaiduDevicesListItem[],
  configList: KaiduConfigurationsListItem[],
  mqttConfigList: MQTTConfigurationsListItem[]
) {
  const valids = getValidKaiduDevices(kaiduDevicesList, configList);
  const combined = matchDeviceListWithIoTList(
    valids,
    scannerIoTStates,
    configList,
    mqttConfigList
  );
  const onlineList = getOnlineItems(combined);
  const offlineList = getOfflineItems(combined);
  return { onlineList, offlineList };
}

/**
 * @description
 * @param  {IoTDeviceState[]|undefined} scannerIoTStates
 * @param  {} configuredDevices
 * @returns IoTDeviceState[]: array of scanners which is configured and has not sent telemetry event in 5 mins
 */
export function filterForInActiveDevices(
  scannerIoTStates: IoTDeviceState[] | undefined,
  configuredDevices
): IoTDeviceState[] {
  if (!scannerIoTStates || !configuredDevices) {
    return [] as IoTDeviceState[];
  }
  const configuredIds = configuredDevices.map((item) => item?.deviceId);
  const configuredResult = scannerIoTStates.filter((item) =>
    configuredIds.includes(item?.id)
  );

  configuredResult.forEach((item) => {
    const matched = configuredDevices.find((dev) => dev?.deviceId === item?.id);
    item = Object.assign(item, { name: matched?.name });
  });

  const filtered = configuredResult.filter((item) => !item?.isOnline);
  // console.log(
  //   `filterInActive ${helpers.inspect(filtered.map((item) => item.id))}`
  // );
  return filtered;
}

/**
 * @param  {KaiduDeviceConfiguration[]|undefined} allConfigs
 * @returns {deviceId, name}[] array of devices with configuration records in deviceId and name map
 */
export function getConfiguredDevices(
  allConfigs: KaiduDeviceConfiguration[] | undefined
): { deviceId: string; name: string }[] {
  if (!allConfigs) {
    return [];
  }

  return allConfigs.map((item) => {
    return { deviceId: item.mqtt_device_id, name: item.device_name };
  });
}

export function getOverviewCounts() {}
