import { HoursOfOperation } from 'domain/customers/types';
import { useController } from 'react-hook-form';
import { ControlledInput } from 'components/molecule/ControlledInput';
import { MuiLabel } from 'components/atomic/Label';
import MultiRangeSlider from 'domain/customers/components/atomic/MultiRangeSlider/MultiRangeSlider';
import {
  convertTimeNumToString,
  parseTimeStringToMinuteNum,
} from 'features/date';
import { useMediaQueries } from 'styles/breakpoints';
import { TimeRangeInput } from 'components/molecule/TimeRangeInput';
import styled from 'styled-components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export const Input = styled.div`
  width: 100%;
  height: 1.2rem;
  margin-top: 0;
  padding: 0.5em;
  border: 0;
  border-bottom: 2px solid gray;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;

  &:focus {
    border-bottom: 4px solid black;
  }
`;

export function HoursOfOperationInput({ control, setValue, ...optionals }) {
  const { className = '', trigger, ...rest } = optionals;

  // Hooks
  const {
    field: { onChange: onChangeStart, value: startValue },
  } = useController({
    name: 'start',
    control,
  });

  const {
    field: { onChange: onChangeEnd, value: endValue },
  } = useController({
    name: 'end',
    control,
  });

  const isLg = useMediaQueries('lg');

  const handleTimeChange = ([min, max]) => {
    // console.debug('range slider change', input);
    const start = convertTimeNumToString(min);
    const end = convertTimeNumToString(max);
    console.debug(`set start: ${start}, end: ${end}`);
    setValue('start', start, { shouldDirty: true });
    setValue('end', end, { shouldDirty: true });
  };

  const handleStartChange = (e) => {
    const timeString = e.target.value;
    onChangeStart(timeString);
  };

  const handleEndChange = (e) => {
    const timeString = e.target.value;
    onChangeEnd(timeString);
  };

  return (
    <>
      <div className='mb-2'>Hours of Operation</div>
      <div className={`flex-1 ${className}`}>
        <div className='flex flex-col lg:flex-row space-x-2 items-center max-w-full'>
          {isLg ? (
            <>
              <TimeRangeInput
                startValue={startValue}
                endValue={endValue}
                onStartChange={handleStartChange}
                onEndChange={handleEndChange}
              >
                <Slider
                  range
                  defaultValue={500}
                  min={0}
                  max={1440}
                  allowCross={false}
                  onChange={handleTimeChange}
                  value={[
                    parseTimeStringToMinuteNum(startValue),
                    parseTimeStringToMinuteNum(endValue),
                  ]}
                />
              </TimeRangeInput>
            </>
          ) : (
            <>
              <div className='flex justify-between space-x-8 mb-6 w-full'>
                <TimeRangeInput
                  startValue={startValue}
                  endValue={endValue}
                  onStartChange={handleStartChange}
                  onEndChange={handleEndChange}
                />
              </div>
              <Slider
                range
                min={0}
                max={1440}
                allowCross={false}
                onChange={handleTimeChange}
                value={[
                  parseTimeStringToMinuteNum(startValue),
                  parseTimeStringToMinuteNum(endValue),
                ]}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
