import { BASE_API } from '../constants';
import axios from 'axios';
import { helpers } from '../../../utils';
import { getErrorMessageInResponse, handleAxiosResultData } from '../processor';

const FOO = '/mqtt_configurations_list';
export const MQTT_CONFIG_LIST_API = `${BASE_API}${FOO}`;

export async function fetchMQTTConfigList() {
  const [result, resultErr] = await helpers.handle(
    axios.get(MQTT_CONFIG_LIST_API)
  );

  if (!result) {
    console.error(`fetchMQTTConfigList failed`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  return handleAxiosResultData(result);
}
