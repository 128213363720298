import { useSelector } from "react-redux";

import {
  selectIsModalOpened,
  selectModalData,
} from "domain/customers/lib/redux/customersSlice";
// import { inspect } from "utils";
// import { ToastContainer } from '../ToastContainer';
import { ModalContainer } from "./ModalsGroup";

export function CustomerModalRoot({ customerId, customersList, ...optionals }) {
  // Root component for Modal, including modal and toast

  // Global States
  const modalData = useSelector(selectModalData);
  const isOpen = useSelector(selectIsModalOpened);

  // console.log(`modalData: ${inspect(modalData)}`);

  return (
    <>
      {/* <ToastContainer /> */}
      {isOpen ? (
        <ModalContainer
          modalData={modalData}
          customerId={customerId}
          customersList={customersList}
        />
      ) : null}
    </>
  );
}
