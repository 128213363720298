// import React, { useEffect } from 'react';
import { useSelector } from "react-redux";

import { ErrorMsg } from "components/atomic/ErrorMsg";
import SpinnerWithLayout from "components/molecule/SpinnerWithLayout";
import { getAxiosAuthToken } from "domain/auth";
import { useCustomersList } from "features/kaidu-config-server/customers-list";
import { selectCurrentUser } from "providers/redux/globalSlice";
import { pickPropsInCustomersList } from "../lib";
import { MainContainer } from "./MainContainer";

/**
 * A root view for displaying the customers section
 */
export function RootFetcher(props) {
  // Hooks
  const token = getAxiosAuthToken();
  const { customersList, isLoading, isError } = useCustomersList(
    Boolean(token),
    { refreshInterval: 60 * 1000, revalidateIfStale: true } // Refetch customers list every minute
  );
  const user = useSelector(selectCurrentUser);

  if (isLoading) {
    return <SpinnerWithLayout />;
  }

  if (isError) {
    return <ErrorMsg text={isError?.message} />;
  }

  return (
    <MainContainer
      customersList={pickPropsInCustomersList(customersList) || []}
      user={user}
    />
  );
}
