import styled from 'styled-components';
import Typography from './Typography';
import { headingFontFamily } from 'styles/constants';

const StyledH2 = styled.h2`
  ${headingFontFamily}
  font-weight: 600;
  line-height: 1.2;
  color: #343a40;
  font-size: 24px;
`;


export function H2(props) {
  const { children, className } = props;
  return <StyledH2 className={`text-3xl ${className}`}>{children}</StyledH2>;
}

export default H2;

export const FullLineH2 = styled(H2)`
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
  width: 100%;
`;

const StyledH3 = styled.h3`
    ${headingFontFamily}
    font-size: 20px;
    font-weight: 600;
  `;

export function H3(props) {
  return <StyledH3 {...props} />;
}

export const PageTitle = styled.h1`
  color: ${({ theme }) => theme.palette.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.3rem;
  @media ${({ theme }) => theme.device.tablet} {
    font-size: 2.5rem;
  }
  line-height: 1.2;
  ${headingFontFamily}
  
`;

export function ModalH2(props) {
  const { className, ...rest } = props;
  return (
    <Typography {...rest} className={`text-2xl text-gray-600 font-bold ${className}`} />
  );
}
