import {
  useAllKaiduConfig,
  useCustomersDeviceData,
  useCustomersList,
  useKaiduConfigItem,
  useRSSIThresholdList,
} from "features/kaidu-config-server";
import { useUniqPreconfigs } from "features/kaidu-config-server/composition";
import { useCombinedKaiduConfigList } from "features/kaidu-configs";

export function useConfigPageData() {
  const {
    buildings,
    floors,
    locations,
    isLoading: isPreconfigLoading,
    isError: isPreconfigError,
  } = useUniqPreconfigs();

  const {
    customersList,
    isLoading: isCustomersListLoading,
    isError: isCustomersListError,
  } = useCustomersList();

  const {
    data: configList,
    isLoading: isKaiduConfigListLoading,
    isError: isKaiduConfigListError,
  } = useCombinedKaiduConfigList();

  return {
    allConfig: configList,
    buildings,
    floors,
    locations,
    customersList,
    isLoading:
      isCustomersListLoading || isPreconfigLoading || isKaiduConfigListLoading,
    isError: isCustomersListError || isPreconfigError || isKaiduConfigListError,
  };
}

export function useConfigPageDataByKaiduDeviceConfig() {
  const {
    buildings,
    floors,
    locations,
    isLoading: isPreconfigLoading,
    isError: isPreconfigError,
    key: uniqPreconfigKey,
  } = useUniqPreconfigs();

  const {
    customersList,
    isLoading: isCustomersListLoading,
    isError: isCustomersListError,
  } = useCustomersList();

  const {
    allConfig,
    isLoading: isLoadingConfigs,
    isError: isConfigsError,
    key: AllKaiduConfigKey,
  } = useAllKaiduConfig();

  return {
    allConfig: allConfig,
    buildings,
    floors,
    locations,
    customersList,
    isLoading: isCustomersListLoading || isPreconfigLoading || isLoadingConfigs,
    isError: isCustomersListError || isPreconfigError || isConfigsError,
    keys: [AllKaiduConfigKey, uniqPreconfigKey],
  };
}

export function useEditModalData(mac: string) {
  const {
    data: kaiduConfig,
    isLoading: isKaiduConfigLoading,
    isError: isKaiduConfigError,
  } = useKaiduConfigItem(mac);

  const {
    data: rssiList,
    isLoading: isRssiLoading,
    isError: isRssiError,
  } = useRSSIThresholdList();

  const {
    customersList,
    isLoading: isCustomersListLoading,
    isError: isCustomersListError,
  } = useCustomersList();

  const {
    customerDeviceData: customerDeviceDataList,
    isLoading: isCustomersDeviceDataListLoading,
    isError: isCustomersDeviceDataListError,
  } = useCustomersDeviceData();

  return {
    serverConfig: kaiduConfig,
    data: Object.assign({}, kaiduConfig),
    optionLists: {
      customersList,
      rssiList,
      customerDeviceDataList,
    },
    isLoading:
      isRssiLoading ||
      isKaiduConfigLoading ||
      isCustomersListLoading ||
      isCustomersDeviceDataListLoading,
    isError:
      isRssiError ||
      isKaiduConfigError ||
      isCustomersListError ||
      isCustomersDeviceDataListError,
    keys: [],
  };
}
