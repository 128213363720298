import { useSelector } from "react-redux";

import ErrorMsg from "components/atomic/ErrorMsg";
import LoadingSpinner from "components/molecule/LoadingSpinner";
import {
  CustomersListItem,
  useCustomersList,
} from "features/kaidu-config-server/customers-list";
import { useAllUsersList } from "features/kaidu-config-server/users-list";
// import BaseTable from 'components/organism/BaseTable';
import { SettingsH3 } from "components/Headings";
import { getAxiosAuthToken } from "domain/auth";
import { selectCurrentUser } from "providers/redux/globalSlice";
import { ModalRoot } from "./ModalRoot";
import { UserList } from "./UserList";

export function UserMainView() {
  const token = getAxiosAuthToken();
  const accountUser = useSelector(selectCurrentUser);
  const { data, isLoading, isError } = useAllUsersList(Boolean(token));
  const {
    customersList,
    isLoading: isCustomersLoading,
    isError: isCustomersError,
  } = useCustomersList(Boolean(token));

  if (isLoading || isCustomersLoading) {
    return <LoadingSpinner />;
  }
  if (isError || isCustomersError) {
    return <ErrorMsg text="Failed to load data" />;
  }

  const defaultCustomer: CustomersListItem = customersList.find(
    (customer) => customer.customer_id === accountUser?.customers_list_id
  );

  return (
    <div>
      <SettingsH3>User Information</SettingsH3>
      <UserList users={data} />
      <ModalRoot customers={customersList} defaultCustomer={defaultCustomer} />
    </div>
  );
}
