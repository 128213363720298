import { KaiduDevicesListItem } from 'features/kaidu-config-server/kaidu-devices-list';
import { DEVICE_STATUS } from 'features/kaidu-config-server';
import { filter, propEq, pipe, prop, defaultTo } from 'ramda';
import { NamedStatusCounts, NumousKeyStatusCounts } from './types';

export function getKaiduDeviceStatusCountFromList(statusId: number): Function {
  return pipe(
    defaultTo([]),
    filter((item) => propEq('kaidu_device_status', statusId)(item)),
    prop('length')
  );
}

export function getAllStatusCount(
  kaiduDevicesList: KaiduDevicesListItem[]
): NamedStatusCounts {
  const counts = {
    total: kaiduDevicesList?.length ?? 0,
    new: getKaiduDeviceStatusCountFromList(DEVICE_STATUS.NEW)(kaiduDevicesList),
    deactivated: getKaiduDeviceStatusCountFromList(DEVICE_STATUS.DEACTIVATED)(
      kaiduDevicesList
    ),
    activated: getKaiduDeviceStatusCountFromList(DEVICE_STATUS.ACTIVATED)(
      kaiduDevicesList
    ),
    online: getKaiduDeviceStatusCountFromList(DEVICE_STATUS.ONLINE)(
      kaiduDevicesList
    ),
    offline: getKaiduDeviceStatusCountFromList(DEVICE_STATUS.OFFLINE)(
      kaiduDevicesList
    ),
    broken: getKaiduDeviceStatusCountFromList(DEVICE_STATUS.BROKEN)(
      kaiduDevicesList
    ),
    configured: getKaiduDeviceStatusCountFromList(DEVICE_STATUS.CONFIGURED)(
      kaiduDevicesList
    ),
  };

  return counts;
}

export function getAllStatusCountInNumKeys(
  kaiduDevicesList: KaiduDevicesListItem[]
): NumousKeyStatusCounts {
  const counts = {
    total: kaiduDevicesList?.length ?? 0,
    0: getKaiduDeviceStatusCountFromList(DEVICE_STATUS.NEW)(kaiduDevicesList),
    5: getKaiduDeviceStatusCountFromList(DEVICE_STATUS.DEACTIVATED)(
      kaiduDevicesList
    ),
    1: getKaiduDeviceStatusCountFromList(DEVICE_STATUS.ACTIVATED)(
      kaiduDevicesList
    ),
    3: getKaiduDeviceStatusCountFromList(DEVICE_STATUS.ONLINE)(
      kaiduDevicesList
    ),
    4: getKaiduDeviceStatusCountFromList(DEVICE_STATUS.OFFLINE)(
      kaiduDevicesList
    ),
    6: getKaiduDeviceStatusCountFromList(DEVICE_STATUS.BROKEN)(
      kaiduDevicesList
    ),
    2: getKaiduDeviceStatusCountFromList(DEVICE_STATUS.CONFIGURED)(
      kaiduDevicesList
    ),
  };

  return counts;
}
