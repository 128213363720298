import React from 'react';
import Alert from '../../../components/atomic/Alert';
import { useFirmwareFile } from 'features/firmware';


function setText(latest, isLoading, isError) {
  if (latest && !isLoading && !isError) {
    return `Fetched firmware file verison ${latest}!`;
  }
  if (isError) {
    return 'Failed to fetch firmware file';
  }
  if (isLoading) {
    return 'Fetching firmware file...';
  }
  if (latest) {
    return `Confirmed Latest firmware version ${latest}`;
  }
  if (!latest) {
    return `Checking the latest firmware version`;
  }
};

export const setVariant = (data, isLoading, isError) => {
  if (data && !isLoading && !isError) {
    return 'success';
  }
  if (isError) {
    return 'danger';
  }
  if (isLoading) {
    return 'primary';
  }
  if (data) {
    return `success`;
  }
  if (!data) {
    return `primary`;
  }
};

// 
// 
/**
 * Controller of firmware file alert
 * possible status: not started, fetching, fetched, fetched but failed
 */
export function FirmwareFileAlert({ show, firmwareVersion, ...rest }) {
  //Hooks
  // const { latest } = useKaiduFirmwareList(show);
  const result = useFirmwareFile(firmwareVersion);
  const { isLoading, isError } = result;

  //Conditions
  if (!show) { return null; }

  const variant = setVariant(firmwareVersion, isLoading, isError);
  const text = setText(firmwareVersion, isLoading, isError);

  return (
    <Alert isLoading={isLoading} variant={variant}>
      {text}
    </Alert>
  );
}
