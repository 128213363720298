import _ from "lodash";
import useSWR, { SWRResponse } from "swr";
import { helpers } from "utils";
import { checkIsValidID } from "../processor";
import { CUSTOMERS_LIST_API, CUSTOMER_ITEM_ID_API } from "./api";
import { CustomersListItem } from "./types";

const { swrFetcher } = helpers;

export function useCustomersList(
  mounted = true,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const { data, error }: SWRResponse<CustomersListItem[], Error> = useSWR(
    mounted ? CUSTOMERS_LIST_API : null,
    swrFetcher,
    options
  );

  let result = data;
  if (data) {
    // remove all floorplan data
    //@ts-ignore
    result = data?.map((item) =>
      _.pick(item, [
        "id",
        "customer_id",
        "customer_name",
        "customer_config",
        "isPartner",
      ])
    );
    result = _.sortBy(result, "customer_name");
  }

  return {
    customersList: result,
    isLoading: !error && !result,
    isError: error,
  };
}

export function useCustomersListById(
  id: string,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  // console.log(`useCustomersListById with id: ${id}`);

  const { data, error }: SWRResponse<CustomersListItem, Error> = useSWR(
    checkIsValidID(id)
      ? `${CUSTOMER_ITEM_ID_API}/${encodeURIComponent(id)}`
      : null,
    swrFetcher,
    options
  );

  return {
    customer: data,
    isLoading: checkIsValidID(id) && !error && data === undefined,
    isError: error,
  };
}

export function useCustomerByName(
  customerName: string,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const name = customerName ? customerName.trim() : "";
  const { data, error }: SWRResponse<CustomersListItem, Error> = useSWR(
    name ? `${CUSTOMERS_LIST_API}/name/${encodeURIComponent(name)}` : null,
    swrFetcher,
    options
  );

  return {
    customer: data,
    isLoading: name && !error && !data,
    isError: error,
  };
}
