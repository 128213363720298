import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Container from "components/atomic/Container";
import {
  selectSelectedCustomer,
  setSelectedCustomer,
} from "domain/customers/lib/redux/customersSlice";
import {
  CustomersListItem,
  findByCustomerID,
} from "features/kaidu-config-server";
import { User } from "features/kaidu-config-server/users-list";
import ErrorMsg from "../../../components/atomic/ErrorMsg";
import { AddNewCustomerBtn } from "./AddNewCustomerBtn";
import { CustomerListView } from "./CustomerListView";
import { CustomerModalRoot } from "./modal/ModalRoot";

export function MainContainer({
  customersList,
  user,
  ...optionals
}: {
  customersList: CustomersListItem[];
  user: User;
  [x: string]: any;
}) {
  // root view container
  const dispatch = useDispatch();

  const { customerId } = useSelector(selectSelectedCustomer) || {};
  const {
    isSuperUser,
    is_partner,
    customers_list_id: defaultCustomerID,
  } = user || {};

  useEffect(() => {
    const isValidCustomerIDSelected =
      customerId && findByCustomerID(customersList, customerId);
    if (!isValidCustomerIDSelected) {
      // no valid customer id is selected
      console.debug("No Valid Customer ID Selected", user);
      if (user && user?.customers_list_id) {
        // user has a customer id, use it as default
        dispatch(setSelectedCustomer({ customerId: user?.customers_list_id }));
      }
    }
  }, [customerId, customersList, dispatch, user]);

  if (customersList && user && !user?.customers_list_id) {
    // user doesn't have a customers_list_id
    return (
      <ErrorMsg
        text={
          "No customers_list_id can be found in the user. Please login with a valid user account"
        }
      />
    );
  }

  return (
    <Container>
      <div>
        {isSuperUser || is_partner ? (
          <div className={"flex justify-end space-x-4"}>
            <AddNewCustomerBtn show={isSuperUser || is_partner} />
          </div>
        ) : null}
        <CustomerListView
          customersList={customersList}
          customerId={customerId}
          defaultCustomerID={defaultCustomerID}
        />
      </div>
      <CustomerModalRoot
        customerId={customerId}
        customersList={customersList}
      />
    </Container>
  );
}
