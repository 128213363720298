import styled from 'styled-components';
import Label from 'components/atomic/Label';
import Input from 'components/atomic/Input';
import { v4 as uuidv4 } from 'uuid';
import { useController } from 'react-hook-form';
import { RequiredErrorMsg } from 'components/atomic/ErrorMsg';

export default function InputGroup({ name, control, ...optionals }) {
  // An input item consist of <input> and <label>
  const {
    label,
    defaultValue = '',
    type = 'text',
    required = false,
    ...rest
  } = optionals;

  // generated dom id
  const id = uuidv4(); //`${name}-id`;

  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue,
  });

  return (
    <>
      <div className='w-full max-w-xs flex justify-between'>
        <Label
          style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          htmlFor={id}
          className='mr-2'
          required={required}
        >
          {label ?? name}
        </Label>
        <Input type={type} {...rest} {...inputProps} inputRef={ref} id={id} />
      </div>
      {error && <RequiredErrorMsg className='ml-2' />}
    </>
  );
}
