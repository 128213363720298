import { useState, useEffect } from 'react';
import { connect, requestBLEDevicesFilter } from './processor';
import { FirmwareUpdateController } from './FirmwareUpdateController';
import ConnectedInfo from './ConnectedInfo';
import { useForm } from 'react-hook-form';
import { BLEBtnGroup } from './BLEBtnGroup';
import CustomerDropdown from './CustomerDropdown';


import ActivityStatus from './ActivityStatus';
import styled from 'styled-components';
import { useCustomersList } from 'features/kaidu-config-server/customers-list/hooks';
import LoadingSpinner from 'components/molecule/LoadingSpinner';
import ErrorMsg from 'components/atomic/ErrorMsg';


const InfoContainer = styled.div`
  border-radius: 7px;
  min-width: 350px;
  background-color: white;
  // max-height: 61vh;
  // min-height: 33vh;
  // height: 61vh;
`

const InfoHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  border-radius: 7px 7px 0px 0px;
  background: #F2F5FF;
  padding: 10px 15px 10px 15px;
  white-space: nowrap;
  overflow: hidden;
`

const CustomerDropdownContainer = styled.div`
  border-radius: 8px;
  padding: 10px 20px 10px 20px;
  margin-top: 15px;
  background-color: #F9FAFE;
`


type BLEStatus = 'idle' | 'connected' | 'update' | 'disconnected';

/**
 * firmware should not start until the "update" button is pressed
 * Features:
 * - connect to a scanner
 */
export function BLEContent({ isAvailable, firmwareData, ...optionals }) {
  console.log('BLEContent ~ rerender');
  // console.log("BLEContent ~ rerender");
  const { latest } = firmwareData || {};

  // Hooks
  const { control, watch } = useForm({
    defaultValues: {
      firmware: latest,
    },
  });

  //States
  const [bleStatus, setBleStatus] = useState<BLEStatus>('idle');
  const [pairedDevice, setPairedDevice] = useState(null);
  const [gattServer, setGattServer] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [connectionError, setConnectionError] = useState(null);
  const [shouldFetchFirmware, setShouldFetchFirmware] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [shouldConnect, setShouldConnect] = useState(false);
  const [isUpdateFinished, setUpdateFinished] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [numOfDevices, setNumOfDevices] = useState(0)
  const { customersList, isLoading, isError } = useCustomersList();
  const ALLDEVICE = 'ANY'
  const allDeviceObject = { 'id': 'NONE', 'customer_id': 'ANY', 'customer_name': '\u2014All Devices\u2014' }
  const [selectedValue, setSelectedValue] = useState(allDeviceObject)
  
  useEffect(() => {
    if (pairedDevice) {
      // console.log("file: BLEPage.tsx:107 ~ useEffect ~ pairedDevice", pairedDevice);
      const foo = async () => {
        const connectedGattServer = await connect(pairedDevice);
        setGattServer(connectedGattServer);
      };
      setIsConnecting(true);
      foo()
        .then(() => {
          setIsConnecting(false);
        })
        .catch((err) => {
          setConnectionError(err);
        });
    }
  }, [pairedDevice]);

  function hexStringToUint8Array(hexString) {
    if (hexString.length % 2 !== 0) {
      throw new Error('Invalid hexString');
    }
    let arrayBuffer = new Uint8Array(hexString.length / 2);
    for (let i = 0; i < hexString.length; i += 2) {
      const byteValue = parseInt(hexString.substr(i, 2), 16);
      if (isNaN(byteValue)) {
        throw new Error('Invalid hexString');
      }
      arrayBuffer[i / 2] = byteValue;
    }
    return arrayBuffer;
  }

   /**
   * handles connecting to devices
   */

  const handleScanClick = async (device) => {
    setSelectedDevice(device)
    const macAddress = device.mac_address
    let filtering
    // Filters by customer_macaddress
    if (macAddress != ALLDEVICE) {
      const macWithoutColons = macAddress.replace(/:/g, '');
      filtering = [
        {
          manufacturerData: [
            {
              companyIdentifier: 0xFFFF, /* Kaidu */
              dataPrefix: hexStringToUint8Array(macWithoutColons),
            }
          ]
        }
      ]
      // If All devices selected, it will just filter by KaiduScanner
    } else {
      filtering = [
        {
          name: 'KaiduScanner',
        },
      ]
    }
    try {
      const device = await requestBLEDevicesFilter(filtering);
      setPairedDevice(device);
      setShouldConnect(true);
      setIsConnected(true);
      setBleStatus('connected');
    } catch (error) {
      if (error.code === 8) {
        console.log(error.message);
      }
    }
  };

  /**
   * trigger firmware update
   */
  const handleUpdateClick = () => {
    console.log('file: BLEContent.tsx:73 ~ handleUpdateClick is triggered');
    setBleStatus('update');
    setShouldFetchFirmware(true);
    setShouldUpdate(true);
  };

  /**
   * disconnect, should reset to init state
   */
  const handleDisconnectClick = () => {
    //disconnect
    gattServer && gattServer.disconnect();
    // console.log(connectedBLEDevice?.gatt.disconnect);
    console.log(`Device is disconnected.`);
    setBleStatus('disconnected');
    setShouldConnect(false);
    setShouldUpdate(false);
    setPairedDevice(null);
    setGattServer(null);
    setIsConnecting(false);
    setConnectionError(null);
    setUpdateFinished(false);
    setIsConnected(false);
    setShouldFetchFirmware(false);
    setSelectedDevice(null);
  };

  const handleCancelClick = () => {
    setShouldUpdate(false);
    handleDisconnectClick();
  };

  const handleUpdateResult = (data) => {
    const { isUpdateStopped, isUpdateError } = data;
    setUpdateFinished(isUpdateStopped && !isUpdateError);
  };

  const connectedBLEDevice = {
    id: pairedDevice?.id,
    name: pairedDevice?.name,
    gatt: gattServer,
  };

  const connectionResult = {
    connectedBLEDevice: connectedBLEDevice,
    isLoading: isConnecting,
    isError: connectionError,
  };

  const selectedFirmware = watch('firmware');
  const bleControlcallbacks = {
    onConnect: handleScanClick,
    onDisconnect: handleDisconnectClick,
    onUpdate: handleUpdateClick,
    onCancel: handleCancelClick,
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (isError)
    return (
      <ErrorMsg
        text={`An error has occurred while retrieving customer list. ${isError?.message}`}
      />
    );

  return (
    <CustomerDropdownContainer>
      {/* Dropdown Option for Customers */}
      <div className='flex flex-row justify-between items-center'>
        <CustomerDropdown
          placeHolder={"Select Customer"}
          // First item in options allows for scanning of all devices, then appended with the rest of customerList
          options={[allDeviceObject, ...customersList]}
          isSearchable={true}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />
        <ActivityStatus
          bleStatus={bleStatus}
          shouldFetchFirmware={shouldFetchFirmware}
          shouldUpdate={shouldUpdate}
          shouldConnect={shouldConnect}
          isUpdateFinished={isUpdateFinished}
          pairedDevice={pairedDevice}
          connectionResult={connectionResult}
          selectedFirmware={selectedFirmware}
          isConnected={isConnected}
        />
      </div>
      {/* Devices list and Connected Device info display*/}
      <div className="flex flex-row items-start" >
        {/* Devices */}
        <InfoContainer style = {(numOfDevices <= 3 && !pairedDevice)  ? { height: '31vh' } : { height: '61vh' }}>
          <InfoHeader>
            {!!gattServer ? 'Actions' : `Devices (${numOfDevices}) `}
          </InfoHeader>
          <BLEBtnGroup
            isAvailable={isAvailable}
            isConnected={!!gattServer}
            isUpdateFinished={isUpdateFinished}
            isUpdating={shouldUpdate && !isUpdateFinished}
            firmwareData={firmwareData}
            control={control}
            selectedValue={selectedValue}
            {...bleControlcallbacks}
            allDevice={ALLDEVICE}
            selectedDevice = {selectedDevice}
            setNumOfDevices = {setNumOfDevices}
            key={`ble-btns-${pairedDevice?.id}`}
          />
        </InfoContainer >
        {/* Connected Device Info  */}
        <InfoContainer className="ml-2.5 w-full" style = {(numOfDevices <= 3 && !pairedDevice) ? { height: '31vh' } : { height: '61vh' }}>
          <InfoHeader>
            Connected Devices
          </InfoHeader>
            {pairedDevice && (
              <ConnectedInfo connectionResult={connectionResult} />
            )}
        </InfoContainer>
      </div>
      {/* Firmware Update Options */}
      <FirmwareUpdateController
        bleStatus={bleStatus}
        startUpdate={shouldUpdate}
        connectedBLEDevice={connectedBLEDevice}
        onUpdateResult={handleUpdateResult}
        selectedVersion={selectedFirmware}
        key={`firmware-update-${pairedDevice?.id}`}
      />
    </CustomerDropdownContainer>
  );

}
