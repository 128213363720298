import _ from "lodash";
import { useSelector } from "react-redux";

import { getAxiosAuthToken } from "domain/auth";
import { getAllStatusCount } from "features/device-status";
import {
  useAllKaiduConfig,
  useKaiduConfigurationsList,
  useMQTTConfigurationsList,
} from "features/kaidu-config-server";
import {
  countInvalidKaiduDevices,
  countValidKaiduDevices,
} from "features/kaidu-config-server/composition";
import {
  countOnline,
  getOnlineItems,
  useIoTCloudStatus,
} from "features/kaidu-config-server/iot-cloud";
import {
  KaiduDevicesListItem,
  countDeviceNumInStatus,
  countKaiduDevicesWithActivatedStatus,
  countKaiduDevicesWithDeactivatedStatus,
  useKaiduDevicesList,
} from "features/kaidu-config-server/kaidu-devices-list";
import { selectCurrentUser } from "providers/redux/globalSlice";
import { usefulCols } from "../DeviceStatusPage/constants";
import {
  assignOnlineStatusProp,
  convertToReadable,
} from "../DeviceStatusPage/processors";
import { FormattedRowData, StatusRowData } from "../DeviceStatusPage/types";
import {
  filterForInActiveDevices,
  getConfiguredDevices,
  getOnlineOfflineList,
} from "./processors";

export function useHomePageData() {
  const token = getAxiosAuthToken();
  const {
    kaiduDevicesList,
    isLoading: isDeviceListLoading,
    isError: isDeviceListError,
  }: {
    kaiduDevicesList: KaiduDevicesListItem[];
    [x: string]: any;
  } = useKaiduDevicesList(Boolean(token));

  const {
    scannerIoTStates,
    isLoading: isIoTStatesLoading,
    isError: isIoTStatesError,
  } = useIoTCloudStatus();

  const {
    allConfig,
    isLoading: isKaiduConfigLoading,
    isError: isKaiduConfigError,
  } = useAllKaiduConfig(Boolean(token));

  const { configList, isLoading: isLoadingKaiduConfigurationList } =
    useKaiduConfigurationsList(Boolean(token));

  const data = useSelector(selectCurrentUser);

  let noAccessError;
  if (data) {
    const isSuperUser = data.isSuperUser || data.is_partner;
    if (!isSuperUser) {
      noAccessError = new Error("User does not have access to this site");
    }
  }
  const counts = {
    total: kaiduDevicesList?.length ?? 0,
    valid: countValidKaiduDevices(kaiduDevicesList, configList),
    invalid: countInvalidKaiduDevices(kaiduDevicesList, configList),
    ...getAllStatusCount(kaiduDevicesList),
  };

  return {
    activeData: getOnlineItems(scannerIoTStates),
    inactiveData: filterForInActiveDevices(
      scannerIoTStates,
      getConfiguredDevices(allConfig)
    ),
    counts,
    isLoadingCounts:
      isDeviceListLoading ||
      isIoTStatesLoading ||
      isLoadingKaiduConfigurationList,
    isConfiguredLoading: isKaiduConfigLoading,
    isActiveLoading: isIoTStatesLoading,
    isInactiveLoading: isIoTStatesLoading || isKaiduConfigLoading,
    isLoading: isLoadingKaiduConfigurationList,
    isError:
      noAccessError ||
      isIoTStatesError ||
      isKaiduConfigError ||
      isDeviceListError,
  };
}

export function useHomePageDataByFrontEndChecking() {
  const token = getAxiosAuthToken();
  const {
    kaiduDevicesList,
    isLoading: isDeviceListLoading,
    isError: isDeviceListError,
  } = useKaiduDevicesList(Boolean(token));

  const {
    allConfig,
    isLoading: isKaiduConfigLoading,
    isError: isKaiduConfigError,
  } = useAllKaiduConfig(Boolean(token));

  const {
    configList,
    isLoading: isLoadingKaiduConfigurationList,
    // isError: isKaiduConfigurationListError,
  } = useKaiduConfigurationsList(Boolean(token));

  const { mqttConfigList, isLoading: isLoadingMQTTConfigList } =
    useMQTTConfigurationsList();

  let onlineList = [];
  let offlineList = [];

  if (kaiduDevicesList && configList && mqttConfigList) {
    const result = getOnlineOfflineList(
      [],
      kaiduDevicesList,
      configList,
      mqttConfigList
    );
    onlineList = result?.onlineList ?? [];
    offlineList = result?.offlineList ?? [];
  }

  return {
    totalCount: kaiduDevicesList?.length,
    configuredCount: allConfig?.length,
    activeCount: countOnline([]),
    activeData: getOnlineItems([]),
    inactiveData: filterForInActiveDevices([], getConfiguredDevices(allConfig)),
    counts: {
      total: kaiduDevicesList?.length,
      deactivated: countKaiduDevicesWithDeactivatedStatus(kaiduDevicesList),
      activated: countKaiduDevicesWithActivatedStatus(kaiduDevicesList),
      valid: countValidKaiduDevices(kaiduDevicesList, configList),
      invalid: countInvalidKaiduDevices(kaiduDevicesList, configList),
      online: onlineList?.length,
      offline: offlineList?.length,
    },
    isLoadingCounts:
      isDeviceListLoading ||
      isLoadingKaiduConfigurationList ||
      isLoadingMQTTConfigList,
    isConfiguredLoading: isKaiduConfigLoading,
    isActiveLoading: false,
    isInactiveLoading: isKaiduConfigLoading,
    isLoading: isLoadingKaiduConfigurationList || isLoadingMQTTConfigList,
    isError: isKaiduConfigError || isDeviceListError,
  };
}

export function useNewHomePageData(options?: any) {
  const token = getAxiosAuthToken();
  const data = useSelector(selectCurrentUser);
  const {
    kaiduDevicesList,
    isLoading: isDeviceListLoading,
    isError: isDeviceListError,
  }: {
    kaiduDevicesList: KaiduDevicesListItem[];
    [x: string]: any;
  } = useKaiduDevicesList(Boolean(token), options);

  const {
    scannerIoTStates,
    isLoading: isIoTStatesLoading,
    isError: isIoTStatesError,
  } = useIoTCloudStatus();

  const {
    allConfig,
    isLoading: isKaiduConfigLoading,
    isError: isKaiduConfigError,
  } = useAllKaiduConfig(Boolean(token), options);

  const { configList, isLoading: isLoadingKaiduConfigurationList } =
    useKaiduConfigurationsList(Boolean(token));

  let formattedRows: FormattedRowData[];
  let validConfigNum = 0;
  let statusCodeAndCountMap;

  if (kaiduDevicesList && allConfig) {
    // calculate derived data
    validConfigNum = allConfig?.length;
    statusCodeAndCountMap = countDeviceNumInStatus(kaiduDevicesList);

    const addedColsToRows = kaiduDevicesList.map((row) => {
      const foundConfig = allConfig.find(
        (config) => config.mac_address === row.mac_address
      );

      let result;
      if (!foundConfig) {
        // result = Object.assign(row, {
        //   mqtt_device_id: "",
        //   customer_name: "",
        //   building: "",
        //   floor: "",
        //   location: "",
        // });
        result = {
          ...row,
          mqtt_device_id: "",
          customer_name: "",
          building: "",
          floor: "",
          location: "",
        };
      } else {
        // console.log(`Found config: ${helpers.inspect(foundConfig)}`)
        // const { mqtt_device_id, customer_name, building, floor, location} = foundConfig;
        result = { ...row, ...foundConfig };
      }
      return result;
    });
    const selectedRows = addedColsToRows.map((row) => _.pick(row, usefulCols));

    //format Rows to be human readable
    formattedRows = selectedRows.map((row) => convertToReadable(row));
  }

  let noAccessError;
  if (data) {
    const isSuperUser = data.isSuperUser || data.is_partner;

    if (!isSuperUser) {
      noAccessError = new Error("User does not have access to this site");
    }
  }
  let statusRows: StatusRowData[];

  if (scannerIoTStates && formattedRows) {
    //Put Online/Offline status in statusRows

    statusRows = formattedRows.map((row) => {
      const matchedKaiduDeviceList = kaiduDevicesList.find(
        (item) => item?.mac_address === row?.mac_address
      );

      return {
        ...assignOnlineStatusProp(row, scannerIoTStates),
        kaidu_device_list: matchedKaiduDeviceList,
      };
    });

    //pre-process -- add property selectable & checked
    const allSelectableDevices = statusRows.map((row) => {
      return { ...row, selectable: !!row.mqtt_device_id };
    });

    allSelectableDevices.forEach((row) => {
      if (row.selectable) {
        Object.assign(row, { checked: false });
      }
    });
  }

  const counts = {
    total: kaiduDevicesList?.length ?? 0,
    valid: countValidKaiduDevices(kaiduDevicesList, configList),
    invalid: countInvalidKaiduDevices(kaiduDevicesList, configList),
    ...getAllStatusCount(kaiduDevicesList),
  };

  return {
    counts,
    statusRows,
    validConfigNum,
    statusCodeAndCountMap,
    activeData: getOnlineItems(scannerIoTStates),
    inactiveData: filterForInActiveDevices(
      scannerIoTStates,
      getConfiguredDevices(allConfig)
    ),
    isLoadingCounts:
      isDeviceListLoading ||
      isIoTStatesLoading ||
      isLoadingKaiduConfigurationList,
    isConfiguredLoading: isKaiduConfigLoading,
    isActiveLoading: isIoTStatesLoading,
    isInactiveLoading: isIoTStatesLoading || isKaiduConfigLoading,
    isLoading: isLoadingKaiduConfigurationList,
    isError:
      noAccessError ||
      isIoTStatesError ||
      isKaiduConfigError ||
      isDeviceListError,
  };
}
