import { ModalH2 } from "components/atomic/Headings";
import { KeyValuePair } from "components/molecule/KeyValuePair";
import { convertVersionStringToObject } from "features/firmware";
import { NO_VALUE_PLACEHOLDER } from "lib/constants";

function getDisplayedVersion(hw_sw_version) {
  try {
    const { hw, sw } = convertVersionStringToObject(hw_sw_version) || {};
    return { hw, sw };
  } catch (error) {
    // console.error(error);
    return { hw: NO_VALUE_PLACEHOLDER, sw: NO_VALUE_PLACEHOLDER };
  }
}

export function BasicInfoSection({ data, ...optionals }) {
  // Process props
  const { mac_address, hw_sw_version, slave_firmware_version } = data || {};
  const { hw: master_hw, sw: master_sw } = getDisplayedVersion(hw_sw_version);
  const { hw: slave_hw, sw: slave_sw } = getDisplayedVersion(
    slave_firmware_version
  );
  return (
    <section>
      <ModalH2>Basic Info</ModalH2>
      <div className={"flex w-full space-x-4"}>
        <dl className={"flex-auto"}>
          <KeyValuePair
            labelClassName="w-52"
            name={"MAC Address"}
            value={mac_address}
          />
          <KeyValuePair
            labelClassName="w-52"
            name={"Master Hardware Version"}
            value={master_hw}
          />
          <KeyValuePair
            labelClassName="w-52"
            name={"Master Software Version"}
            value={master_sw}
          />
          <KeyValuePair
            labelClassName="w-52"
            name={"Slave Hardware Version"}
            value={slave_hw}
          />
          <KeyValuePair
            labelClassName="w-52"
            name={"Slave Software Version"}
            value={slave_sw}
          />
        </dl>
      </div>
    </section>
  );
}
