import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { BaseModal } from "components/molecule/BaseModal";
import { setOpenModal } from "../../lib/redux/customersSlice";
import { AddCustomerForm } from "./AddCustomerForm";

export function AddCustomerModal({
  allCustomerNames,
  ...optionals
}: {
  allCustomerNames: string[];
  [x: string]: any;
}) {
  // Hooks
  const dispatch = useDispatch();
  const { reset, control } = useForm({});

  return (
    <BaseModal
      show={true}
      onHide={() => {
        reset();
        dispatch(setOpenModal(false));
      }}
      title={"Create New Client"}
    >
      <div className="flex justify-center align-items-center">
        <AddCustomerForm
          allCustomerNames={allCustomerNames}
          control={control}
        />
      </div>
    </BaseModal>
  );
}
