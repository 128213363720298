import {BASE_API} from 'lib/constants';
import axios from 'axios';
import { helpers } from 'utils';
import { getErrorMessageInResponse, handleAxiosResultData } from '../../../lib/kaidu-server/processor';

const FOO = '/rssi_thresholds_list';
export const RSSI_THRESHOLDS_API = `${BASE_API}${FOO}`;


export async function fetchRSSIThresholdList() {
  
  const [result, resultErr] = await helpers.handle(axios.get(RSSI_THRESHOLDS_API));

  if (!result) {
    console.error(`fetchAsArrayBuffer failed`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  return handleAxiosResultData(result);
}