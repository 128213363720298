import { Card, CardContent } from '@mui/material';
import { useMediaQueries } from 'styles/breakpoints';
// import Icon from 'components/atomic/Icon';

export function InvalidDeviceContainer({ data = [], ...rest }) {
  const isLg = useMediaQueries('lg');
  // const isXl = useMediaQueries('xl');
  // const is2Xl = useMediaQueries('2xl');

  return (
    <Card
      sx={{
        width: isLg ? '345px' : '450px',
        height: '130px',
        borderRadius: '25px',
        boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#fff',
      }}
      data-tip={rest['data-tip']}
    >
      <CardContent className='flex flex-column justify-center items-center'>
        <div className='text-sm font-bold text-center mb-2'>
          Invalid Devices
        </div>
        <div className='flex flex-row justify-center items-center'>
          {data.map(({ label, tip, number }, i) => (
            <div
              key={i}
              className='flex flex-column'
              style={i === 0 ? { marginRight: '90px' } : {}}
            >
              <div className={'text-sm font-light text-center'} data-tip={tip}>
                {label}
              </div>

              <div
                className={'font-bold text-center'}
                data-tip={tip}
                style={{ fontSize: 'xx-large' }}
              >
                {Math.floor(number)}
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
