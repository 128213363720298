// import { Typography } from "components/atomic/Typography";
import { MuiSelect } from 'components/atomic/SimpleSelect';

export function FirmwarePicker({ list, register, name, ...rest }) {
  return (
    <MuiSelect
      {...register(name)}
      label={'Firmware to Update'}
      defaultValue={list[list.length - 1]}
      options={list.map((item) => ({ value: item, label: item }))}
    />
  );

  // return (
  //   <>
  //     {/* <Typography className="mr-2 mb-0">Update to:</Typography> */}
  //     <select {...register(name)} defaultValue={list[list.length - 1]}>
  //       {list.map((item, index) => (
  //         <option value={item} key={`${item}-key-${index}`}>
  //           {item}
  //         </option>
  //       ))}
  //     </select>
  //   </>
  // );
}
