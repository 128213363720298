import Button from 'components/atomic/Button';
import { setSelectedCustomer } from 'domain/customers/lib/redux/customersSlice';
import { useDispatch } from 'react-redux';


export function CustomerSelectBtn({ isSelected, customerId, defaultCustomerID, ...optionals }) {
  //Hooks
  const dispatch = useDispatch();

  const handleSelectCustomer = (customerId) => {
    console.log(`click select customer ${customerId}`);
    dispatch(setSelectedCustomer({ customerId }));
  };

  const handleUnselect = () => {
    console.log(`click unselect customer ${customerId}`);
    dispatch(setSelectedCustomer({ customerId: null }));
  };

  const showUseDefault = isSelected && customerId !== defaultCustomerID;
  const showSelect = !isSelected;

  return (
    <>
      {showUseDefault ? (
        <Button variant='secondary' size='sm' onClick={handleUnselect}>Use default</Button>
      ) : (
        null
      )}
      {showSelect ? <Button onClick={() => handleSelectCustomer(customerId)} size='sm'>
        Select
      </Button> :
        null}
    </>
  );
}
