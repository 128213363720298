import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import styled from 'styled-components';

export function NotificationGroup() {

  return (
    <>
      <ToastContainer position={'bottom-right'} autoClose={5000}/>
    </>
  )
}
