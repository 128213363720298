import {
  getTime, getUnixTime, max, min, subDays
} from 'date-fns';

export function getNow(): Date {
  return new Date();
}

/**
 * 
 * @returns the date object with (current time - 1 day)
 */
export function getYesterday(): Date {
  return subDays(getNow(), 1);
}

export function getTimeInMilliseconds(date: Date = new Date()): number {
  return getTime(date);
}

/**
 * Get the seconds timestamp of the given date
 */
 export function getUnixTimeByDate(date: Date = new Date()): number {
  return getUnixTime(date);
}

export function getMinDate(datesArray: Date[]) {
  return min(datesArray);
}

export function getMaxDate(datesArray: Date[]) {
  return max(datesArray);
}

/**
 * e.g. 'America/Toronto'
 * @returns 
 */
export function getLocalTimeZone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}