import { inspect, handle } from '../../utils';
import { AxiosResponse } from 'axios';
import { NULL_VALUE } from './constants';

export function getErrorMessageInResponse(resError): string {
  if (!resError) {
    return 'Failed to get the error object';
  }

  const resData = resError?.response?.data;
  if (resData) {
    const errorKeys = Object.keys(resData);
    // console.log(errorKeys);
    const errorMsg = errorKeys
      .map((key) => `${key}: ${resData[key]}`)
      .join(' ');
    return errorMsg;
  }

  const result = resError?.message || inspect(resError);
  return result;
}

export function handleAxiosResultData<T>(axiosResponse: AxiosResponse<T>): T {
  if (!axiosResponse.data) {
    throw new Error(`Got response from server but no data in the response`);
  }
  return axiosResponse.data;
}

export function formateHardwareVersion(str: string): string {
  if (str.startsWith('v')) {
    return str;
  } else {
    return 'v' + str;
  }
}

export async function wrapResponseErrorHandler(
  callback: Function
): Promise<any> {
  const [result, resultErr] = await handle(callback());
  if (resultErr) {
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  return handleAxiosResultData(result);
}

// check if the id is valid, include cases id is all '0's
export function checkIsValidID(id: string): boolean {
  return id && id !== 'null' && id !== 'undefined' && id !== NULL_VALUE;
}
