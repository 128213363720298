// import React from 'react';
import CheckboxIcon from "./CheckboxIcon";

export function StatusRow({
  index,
  row,
  cols,
  rowKey,
  operationComponents,
  identifier,
  onSingleSelect,
  ...rest
}: {
  row: any;
  operationComponents?: any;
  [x: string]: any;
}) {
  return (
    <tr {...rest}>
      <td>
        {identifier && (
          <CheckboxIcon
            onChange={(checked: boolean) => onSingleSelect(checked, identifier)}
            //@ts-ignore
            checked={row.checked}
          />
        )}
      </td>
      <td>{index + 1}</td>
      {cols.map((col) => {
        return <td key={`row-${index + 1}-${col}`}>{row[col]}</td>;
      })}
      <td>{operationComponents}</td>
    </tr>
  );
}
