// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from "react";
import styled from "styled-components";

const Header = styled.header`
  position: sticky;
  top: 0;
  @media ${({ theme }) => theme.device.tablet} {
    min-width: 18rem;
  }
  /* width: 100%; */
  max-height: 100vh;
  z-index: 999;
`;

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
  }
`;

export function PageTemplate({
  header,
  hero,
  footer,
  children,
  ...rest
}: {
  header?: React.ReactNode;
  hero?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <RootContainer className={"min-h-screen"} {...rest}>
      {header && <Header>{header}</Header>}
      <main className="flex flex-grow flex-col">
        {hero && <section>{hero}</section>}
        {children}
      </main>
      {footer && <footer className="mt-auto">{footer}</footer>}
    </RootContainer>
  );
}
