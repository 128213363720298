import Button from '../../../components/atomic/Button';
import { Link } from 'react-router-dom';
import { Select } from '../../../components/molecule/Select';
import { isFilledArray } from '../../../utils';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_API } from 'features/kaidu-config-server';

/**
 * available but not connected -> show Scan button
 * available and connected -> show Disconnect and Update buttons
 * connected and updating -> show Cancel button only
 * not available -> show Back to home button
 */

const ActionButton = styled(Button)`
  margin: 5px 5px 5px 5px;
  padding: 10px 14px;
  border-radius: 6px;
  border: 2px solid #f2f5ff;
  background-color: white;
  color: black;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
  width: 98%;
  line-height: 1.3;
`;

const ButtonList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  // height: 98vh;
`

const DeviceButton = styled(Button)`
  margin: 5px 5px 5px 5px;
  padding: 10px 14px;
  border-radius: 6px;
  border: 2px solid #f2f5ff;
  background-color: white;
  color: black;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
  width: 98%;
  line-height: 1.3;

  &.chosen {
    background: #0b5ed7;
  }
`;

export function BLEBtnGroup({
  onConnect,
  onDisconnect,
  onUpdate,
  onCancel,
  isAvailable,
  isConnected,
  isUpdating,
  isUpdateFinished,
  firmwareData,
  control,
  selectedValue,
  allDevice,
  selectedDevice,
  setNumOfDevices,
  ...optionals
}) {
  const { firmwareList } = firmwareData || {};
  const showScanBtn = isAvailable && !isConnected;
  const options = firmwareList?.map((versionItem) => versionItem.firmware_url);

  // Hooks
  const { field } = useController({
    name: 'firmware',
    control,
    rules: { required: true },
  });

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Change initial state to false

  useEffect(() => {
    if (selectedValue != null) {
      // Execute only when button is clicked
      let customerID = selectedValue.customer_id;
      if (customerID != allDevice) {
        const fetchData = async () => {
          setIsLoading(true); // Set loading state to true
          try {
            const jsonData = await axios
              .get(
                `${BASE_API}/kaidu_device_configuration/customer/${customerID}`
              )
              .then((res) => res.data);
            setData(jsonData);
            setNumOfDevices(jsonData.length)
          } catch (error) {
            console.log('Error:', error);
          } finally {
            setIsLoading(false); // Set loading state to false after API call is complete
          }
        };
        fetchData();
        setNumOfDevices(data.length)

      } else if (customerID == allDevice) {
        // If 'All Devices' is selected from dropdown
        setNumOfDevices(1)
        setData([{ device_name: 'KaiduScanner', mac_address: 'ANY' }])
      }
    }
  }, [selectedValue]);

  if (isUpdateFinished) {
    return <ActionButton onClick={onDisconnect}>Disconnect</ActionButton>;
  }

  // Loads the buttons in with device name and mac address
  return (
    <div>
      {showScanBtn &&
        <div>
          {isLoading ? (
            <p className='text-center mt-4 text-lg'>Loading...</p>
          ) : (
            <ButtonList >
              {/* Button List Display */}
              {data.map((item) => (
                <DeviceButton
                  key={item.device_name}
                  className='btn btn-primary hover:text-white'
                  onClick={() => onConnect(item)}
                >
                  <p className='device-name-text text-xl font-bold m-0 truncate'>
                    {item.device_name}
                  </p>
                  <p className='text-gray-400 m-0'>
                    Mac Address: {item.mac_address}
                  </p>
                </DeviceButton>
              ))}
            </ButtonList>
          )}
        </div>
      }
      {/* If Bluetooth is unavailable  */}
      {isAvailable === false && (
        <Link to='/'>
          <ActionButton>Back to home</ActionButton>
        </Link>
      )}

      {isConnected && !isUpdating && (
        <div className='flex flex-col justify-center items-center'>
          {/* Displays selected device */}
          <DeviceButton
            key={selectedDevice.device_name}
            className='chosen text-white'
          >
            <p className='device-name-text text-xl font-bold m-0 truncate'>
              {selectedDevice.device_name}
            </p>
            <p className='m-0'>Mac Address: {selectedDevice.mac_address}</p>
          </DeviceButton>
          {/* Displays Disconnect an Update options after a device is Connected */}
          <ActionButton onClick={onDisconnect}>Disconnect</ActionButton>
          <ActionButton onClick={onUpdate}>Update</ActionButton>
          {isFilledArray(firmwareList) ? (
            <Select
              options={options}
              value={field.value}
              onChange={field.onChange}
            />
          ) : null}
        </div>
      )}
      {/* Cancel update */}
      {isConnected && isUpdating && (
        <>
          <ActionButton onClick={onCancel}>Cancel</ActionButton>
        </>
      )}
    </div>
  );
}
