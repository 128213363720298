import { useEffect, useMemo, useState } from "react";

import { LabeledSelect } from "components/molecule/Select";
import {
  findByCustomerID,
  findByCustomerName,
  getCustomerNames,
  useCustomersList,
} from "features/kaidu-config-server/customers-list";
import { useController } from "react-hook-form";
import ErrorMsg from "../../../components/atomic/ErrorMsg";
import Spinner from "../../../components/atomic/Spinner";

export function CustomerDropdownWithHook({ ...optionals }) {
  // used for select a customer from the customer list in server
  // show the customer name, update customer id
  const {
    nullOptionName = "all",
    onChange,
    onCustomerIdChange,
    defaultCustomerId,
    disabled,
    ...rest
  } = optionals;

  // when default name changed, customer id should also change
  // when customers list changed, customer id should not change

  const { customersList, isLoading, isError } = useCustomersList();

  // Processed props
  const customerNames = getCustomerNames(customersList);
  const shouldDisable = customerNames?.length < 2 || disabled;
  const defaultName = useMemo(
    () => findByCustomerID(customersList, defaultCustomerId)?.customer_name,
    [customersList, defaultCustomerId]
  );

  const [selectedCustomerName, setSelectedCustomerName] = useState(
    defaultName ? defaultName : nullOptionName
  );

  useEffect(
    () => setSelectedCustomerName(defaultName || nullOptionName),
    [defaultName, nullOptionName, setSelectedCustomerName]
  );
  useEffect(() => {
    const foundCustomer = findByCustomerName(
      customersList,
      selectedCustomerName
    ) || customersList[0]

    // console.log("selectedCustomer: ", foundCustomer);
    onCustomerIdChange && onCustomerIdChange(foundCustomer?.customer_id);
  }, [customersList, onCustomerIdChange, selectedCustomerName]);

  const handleChange = (e) => {
    const customerName = e.target.value;
    setSelectedCustomerName(customerName);
    console.log("selectedCustomerName: ", customerName);
    onChange && onChange(e);
    // onCustomerIdChange && onCustomerIdChange(selectedCustomerId);
  };

  if (isLoading) return <Spinner />;
  if (isError)
    return <ErrorMsg text={"Failed to get customer list from server"} />;

  return (
    <LabeledSelect
      options={customerNames}
      label="Customer"
      id="select-customer"
      onChange={handleChange}
      disabled={shouldDisable}
      value={selectedCustomerName}
      defaultValue={defaultName}
      {...rest}
    />
  );
}

export function CustomerDropdownWithControl({ control, name, ...optionals }) {
  // A customer dropdown select with a form control
  const { defaultValue, ...rest } = optionals;

  const {
    field: { onChange },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue,
  });

  return (
    <>
      <CustomerDropdownWithHook
        defaultCustomerId={defaultValue}
        onCustomerIdChange={onChange}
        {...rest}
      />
    </>
  );
}
