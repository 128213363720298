export const USER_PROFILE_PIC_PLACEHOLDER_URL =
  "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg";

const APPLE_CLIENT_ID =
  process.env?.REACT_APP_APPLE_CLIENT_ID ||
  "REPLACE_BY_BUILD_REACT_APP_APPLE_CLIENT_ID";
const DASHBOARD_URL =
  process.env.REACT_APP_DASHBOARD_URL ||
  "REPLACE_BY_BUILD_REACT_APP_DASHBOARD_URL";

export const APPLE_AUTH_OPTIONS = {
  clientId: APPLE_CLIENT_ID, // This is the service ID we created.
  scope: "name email", // To tell apple we want the user name and emails fields in the response it sends us.
  redirectURI: `${DASHBOARD_URL}/auth`, // As registered along with our service ID
  // redirectURI: APPLE_REDIRECT_URI, // As registered along with our service ID
  usePopup: true, // Important if we want to capture the data apple sends on the client side.
  responseMode: "query",
};

export const AUTH_KEY = "auth";
export enum AuthProvider {
  GOOGLE = "google",
  APPLE = "apple",
}

export const LOGIN_API = "";

export const LOGIN_ERROR_MESSAGE = "An error occurred. Please try again later.";
export const UNREGISTERED_USER_ERROR_MESSAGE =
  "Access Denied: We were unable to retrieve your credentials from the server. If you are using a registered account and need assistance, please contact our technical support team for help";
// "Failed to get the credential from the Kaidu server. Please contact us for technical support if you are using a registered account. "
