import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "react-use";

import Button from "components/atomic/Button";
import { putCustomer } from "domain/customers/api";
import { getHoursOfOperations } from "features/hours-of-operation";
import {
  selectNewCustomerId,
  setNewCustomerId,
} from "providers/redux/deviceStatusSlice";
import { BasicInfoSection } from "./sections/BasicInfoSection";
import { ConfigurationSection } from "./sections/ConfigurationSection";
import { CustomerConfigSection } from "./sections/CustomerConfigSection";
import { DeviceSection } from "./sections/DeviceSection";

/**
 * Modal Content for editing configuration data
 * save updated configuration to server and display results
 * delete this configuration and display result
 * display error feedback if there is one
 */
export function EditModalContent({
  mac,
  onHide,
  onSubmit,
  data,
  optionLists,
  ...optionals
}) {
  //Props
  // const { ...rest } = optionals;
  const { rssiList } = optionLists || {};
  const {
    customer_list_id,
    customers_building_id,
    customers_floor_id,
    customers_location_id,
    device_name,
    customer_name,
    wifi_ssid,
    wifi_password,
    rssi_thresholds_id,
    kaidu_device_status,
    last_seen_timestamp,
    customer_config,
    partner_customer_id,
  } = data || {};

  const configSectionData = {
    customers_building_id,
    customers_floor_id,
    customers_location_id,
    rssi_thresholds_id,
    wifi_ssid,
    wifi_password,
  };

  const { flags, passer_by_interval, customer_type } = customer_config || {};
  const { realtime_flag } = flags || {};
  const { start, end, timezone } = getHoursOfOperations({ customer_config });
  //Hooks
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const new_customer_id = useSelector(selectNewCustomerId);
  const [customerId, setCustomerId] = useState(
    new_customer_id || customer_list_id
  );
  const [initialCustomerConfig, setInitialCustomerConfig] = useState({
    passer_by_interval,
    customer_type,
    realtime_flag,
    start,
    end,
    timezone,
    customer_name,
    partner_customer_id,
  });

  const defaultValues = {
    customer_id: customer_list_id,
    rssi_thresholds_id,
    wifi_ssid,
    wifi_password,
    device_name: device_name || "",
    kaidu_device_status,
  };
  const { handleSubmit, setValue, getValues, control } = useForm({
    defaultValues,
  });
  const customerConfigForm = useForm({
    defaultValues: initialCustomerConfig,
  });

  // Handlers
  const handleSaveChanges = async () => {
    await handleCustomerConfigChange().then(() =>
      handleSubmit(handleValidSubmit, handleSubmitErrors)()
    );
  };

  const handleCustomerConfigChange = async () => {
    const { getValues: getConfigValues } = customerConfigForm;
    const newConfigValues = getConfigValues();
    const customer_id = getValues("customer_id");

    if (isEqual(newConfigValues, initialCustomerConfig)) {
      return;
    }

    const {
      passer_by_interval,
      customer_type,
      realtime_flag,
      start,
      end,
      timezone,
      customer_name,
    } = newConfigValues;

    return await putCustomer({
      customer_id,
      customer_name,
      customer_config: {
        ...customer_config,
        passer_by_interval,
        customer_type,
        flags: { realtime_flag },
        hoursOfOperation: {
          start,
          end,
          timezone,
        },
      },
    });
  };

  const handleValidSubmit = async (currentValues) => {
    const { getValues: getConfigValues } = customerConfigForm;
    const next_partner_customer_id = getConfigValues("partner_customer_id");

    if (
      isEqual(currentValues, defaultValues) &&
      isEqual(next_partner_customer_id, partner_customer_id)
    ) {
      onHide();
    } else {
      if (!currentValues.rssi_thresholds_id) {
        currentValues.rssi_thresholds_id = rssiList[0]["rssi_thresholds_id"];
      }
      await onSubmit({ ...currentValues, next_partner_customer_id }, data);
    }
    dispatch(setNewCustomerId(null));
  };

  const handleSubmitErrors = async (errors) => {
    const currentValues = getValues();
    if (isEqual(currentValues, defaultValues)) onHide();
    dispatch(setNewCustomerId(null));
  };

  const handleCustomerIdChange = useCallback(
    async (id: string) => {
      setValue("customer_id", id);
      setCustomerId(id);
    },
    [setValue, setCustomerId]
  );

  useEffect(() => {
    if (new_customer_id) setValue("customer_id", new_customer_id);
  }, [setValue, new_customer_id]);

  return (
    <>
      <Modal.Body {...optionals}>
        <div className={"space-y-8"}>
          <DeviceSection
            customerName={customer_name}
            deviceName={device_name}
            control={control}
            defaultCustomerId={new_customer_id || customer_list_id}
            kaidu_device_status={kaidu_device_status}
            last_seen_timestamp={last_seen_timestamp}
            form={{ setValue, control }}
            onCustomerIdChange={handleCustomerIdChange}
          />
          <div
            className={`flex w-full ${width > 990 ? "flex-row" : "flex-col"}`}
          >
            <div className="flex-auto">
              <BasicInfoSection data={data} form={{ setValue, control }} />
            </div>
            <div className="flex-auto">
              <ConfigurationSection
                form={{ setValue, control }}
                data={configSectionData}
                optionLists={optionLists}
              />
            </div>
          </div>
          <CustomerConfigSection
            customerId={customerId}
            form={customerConfigForm}
            setInitialCustomerConfig={setInitialCustomerConfig}
            className={`flex gap-x-8 w-full ${
              width > 990 ? "flex-row" : "flex-col"
            }`}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="danger" disabled>
              Soft Delete
            </Button> */}
        <Button variant="primary" onClick={handleSaveChanges}>
          <FontAwesomeIcon className="mr-1" icon="save" />
          Confirm
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </>
  );
}
