import { OperationIcon } from '../atomic/OperationIcon';
import { withTheme } from 'styled-components';

export function OperationsGroup({theme, ...optionals }) {
  // A group of operation icons
  const { onAdd, onEdit, onDelete, showDelete,  ...rest } = optionals;

  return (
    <div className={'flex justify-start space-x-2'} {...rest}>
      {onEdit ? (
        <OperationIcon
          name='edit'
          onClick={onEdit}
          color={theme.palette.primary}
        />
      ) : null}
      {onAdd ? (
        <OperationIcon
          name='plus-circle'
          onClick={onAdd}
          color={theme.palette.primary}
        />
      ) : null}
      {onDelete && showDelete ? (
        <OperationIcon
          name='trash-alt'
          onClick={onDelete}
          color={theme.palette.danger}
        />
      ) : null}
    </div>
  );
}

export default withTheme(OperationsGroup);
