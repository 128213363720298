import { checkIsJWTTokenValid } from '../lib';

export function isAxiosTokenValid(token: string) {
  if (!token) {
    return false;
  }
  console.debug(`token:`, token);
  const bearRemoved = token?.replace('Bearer ', '');
  return Boolean(bearRemoved && checkIsJWTTokenValid(bearRemoved));
}
