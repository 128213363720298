import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { APPLE_AUTH_OPTIONS } from "domain/auth";
import { AppleLogin } from "domain/auth/components/AppleLogin";
import { mediaQueries } from "styles/breakpoints";
// import { GoogleLogin } from 'components/features/Auth/GoogleLogin';
import { GoogleLogin } from "domain/auth/components/GoogleLogin";
import { useCustomerByName } from "features/kaidu-config-server/customers-list";
import useDocumentIcon from "hooks/useDocumentIcon";
import useDocumentTitle from "hooks/useDocumentTitle";
import { setPartnerCustomer } from "providers/redux/globalSlice";
import KaiduBgImg from "../../assets/images/background.jpeg";
import { rediectToPartnerDashboard } from "lib/services/dns-redirection";
import { Divider } from "@mui/material";
import { OTPLogin } from "domain/auth/components/OTPLogin";
import usePartnerSession from "hooks/usePartnerSession";
import { setToSessionStorage } from "features/local-storage";

const FAILURE_TEXT =
  "Sorry, your login attempt failed. Please contact us for technical support if you are using a registered account.";
// const LOGIN_IMG_URL = `https://i.imgur.com/H3r7wCc.jpg`; // TEMP: use imgur, should upload to owned CDN

const ErrorText = styled.p`
  color: ${(props) => props.theme.colors.danger};
  text-align: center;
`;

const Subtitle = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin: 20px 0;
  color: #9f9f9f;
`;

const Wrapper = styled.div`
  /* display: flex; */
  height: 100vh;
  width: 100vw;

  .image {
    max-width: 715px;
    width: 100%;
    border-right: 5px ${(p) => p.theme.colors.secondary} solid;
    background-image: url(${KaiduBgImg});
    background-repeat: no-repeat;
    background-position: center;
    display: none;
    ${mediaQueries("md")`
      display: block;
    `};
  }
`;

const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 5px ${(p) => p.theme.colors.secondary} solid;

  .content {
    width: 400px;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
`;

/**
 *
 */
export function Login() {
  // Hooks
  const DASHBOARD_URL = window?.location?.origin;
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, search, state } = useLocation<{ error?: string }>();
  const isFailurePath = pathname.includes("/login/failure");
  const [error, setError] = useState(null);
  const { addPartnerId } = usePartnerSession();

  const customerName = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get("customer");
  }, [search]);

  const { customer: partnerCustomer, isLoading: loadingPartnerCustomer } =
    useCustomerByName(customerName);

  const documentTitle = useMemo(() => {
    const title = loadingPartnerCustomer
      ? "Loading..."
      : `${partnerCustomer?.customer_name || "Kaidu"} Management`;
    return decodeURI(title);
  }, [partnerCustomer, loadingPartnerCustomer]);
  useDocumentTitle(documentTitle);
  useDocumentIcon(
    partnerCustomer?.customer_config?.image || "/favicon.ico",
    loadingPartnerCustomer
  );

  useEffect(() => {
    rediectToPartnerDashboard();
  }, []);

  useEffect(() => {
    const { isPartner } = partnerCustomer || {};

    if (customerName && !loadingPartnerCustomer && !isPartner) {
      history.push("/403?customer=" + customerName);
    } else if (partnerCustomer) {
      dispatch(setPartnerCustomer(partnerCustomer));
    }
  }, [
    dispatch,
    partnerCustomer,
    customerName,
    loadingPartnerCustomer,
    history,
  ]);

  useEffect(() => {
    if (partnerCustomer) {
      addPartnerId(partnerCustomer.customer_id);
      setToSessionStorage("partnerCustomer", partnerCustomer.customer_name);
    } else {
      setToSessionStorage("partnerCustomer", null);
    }
  }, [partnerCustomer, addPartnerId]);


  return (
    <Wrapper className="flex">
      <div className="image"></div>
      <Main>
        <div className="content">
          <h1 className="text-center text-sec">
            {partnerCustomer?.customer_name || "Kaidu"}
          </h1>
          <Subtitle className="">
            Login to your {partnerCustomer?.customer_name || "Kaidu"} Account
          </Subtitle>
          <div className="mt-3 mx-auto">
            <GoogleLogin onError={setError} />
            {DASHBOARD_URL && (
              <AppleLogin
                initProps={{
                  ...APPLE_AUTH_OPTIONS,
                  redirectURI: `${DASHBOARD_URL}/auth`,
                }}
                onError={setError}
              />
            )}
            <div className="my-3">
              <Divider>OR</Divider>
            </div>
            <OTPLogin onError={setError} />
          </div>
          {isFailurePath ? (
            <ErrorText className={"mt-4"}>
              {state?.error || FAILURE_TEXT}
            </ErrorText>
          ) : null}
          {error && <ErrorText className={"mt-4"}>{error}</ErrorText>}
        </div>
      </Main>
    </Wrapper>
  );
}
