import moment from "moment";
import { ListGroup } from "react-bootstrap";

import { getDisplayedVersion } from "application/pages/DeviceStatusPage/processors";
import ErrorMsg from "components/atomic/ErrorMsg";
import { KeyValuePair } from "components/molecule/KeyValuePair";
import { convertKaiduDeviceStatusNumToString } from "features/kaidu-config-server/kaidu-device-status";
import { NO_VALUE_PLACEHOLDER } from "lib/constants";
import { DeviceLatestInfo } from "types/interfaces";

export default function DeviceListItemDetails({
  data,
}: {
  data: DeviceLatestInfo | undefined;
  [x: string]: any;
}) {
  const {
    mac_address,
    device_name,
    hw_sw_version,
    kaidu_device_status,
    slave_firmware_version,
  } = data || {};
  const { hw: master_hw, sw: master_sw } = getDisplayedVersion(
    hw_sw_version || ""
  );
  const { hw: slave_hw, sw: slave_sw } = getDisplayedVersion(
    slave_firmware_version || ""
  );

  if (data === undefined) {
    return <ErrorMsg text={`Could not find the device`} />;
  }

  return (
    <ListGroup>
      <div>
        <KeyValuePair
          labelClassName="w-64"
          name="Device ID"
          value={data?.source_id || ""}
        />
        <KeyValuePair
          labelClassName="w-64"
          name="Device Name"
          value={device_name || ""}
        />
        <KeyValuePair
          labelClassName="w-64"
          name="Mac Address"
          value={mac_address || ""}
        />
        <KeyValuePair
          labelClassName="w-64"
          name="IP Address"
          value={data?.ip_address || ""}
        />
        <KeyValuePair
          labelClassName="w-64"
          name="Device Status"
          value={
            kaidu_device_status !== undefined
              ? convertKaiduDeviceStatusNumToString(kaidu_device_status)
              : "N/A"
          }
        />
        <KeyValuePair
          labelClassName="w-64"
          name="Master Hardware Version"
          value={master_hw}
        />
        <KeyValuePair
          labelClassName="w-64"
          name="Master Software Version"
          value={master_sw}
        />
        <KeyValuePair
          labelClassName="w-64"
          name="Slave Hardware Version"
          value={slave_hw}
        />
        <KeyValuePair
          labelClassName="w-64"
          name="Slave Software Version"
          value={slave_sw}
        />
        <KeyValuePair
          labelClassName="w-64"
          name="RSSI Threshold value"
          value={data?.rssi_threshold_value || "N/A"}
        />
        <KeyValuePair
          labelClassName="w-64"
          name="Created By"
          value={data?.createdBy || "N/A"}
        />
        <KeyValuePair
          labelClassName="w-64"
          name="Created At"
          value={
            moment(data?.createDateTime).calendar() ?? NO_VALUE_PLACEHOLDER
          }
        />
        <KeyValuePair
          labelClassName="w-64"
          name="Last Changed By"
          value={data?.lastChangedBy || "N/A"}
        />
        <KeyValuePair
          labelClassName="w-64"
          name="Last Changed At"
          value={
            moment(data?.lastChangedDateTime).calendar() ?? NO_VALUE_PLACEHOLDER
          }
        />
        {data?.lastEventTime && (
          <KeyValuePair
            labelClassName="w-64"
            name="Latest Telemetry Time"
            value={
              moment(data.lastEventTime).calendar() ?? NO_VALUE_PLACEHOLDER
            }
          />
        )}
        {data?.lastConnectionTime && (
          <KeyValuePair
            labelClassName="w-64"
            name="Latest Connnection Time"
            value={
              moment(data.lastConnectionTime).calendar() ?? NO_VALUE_PLACEHOLDER
            }
          />
        )}
        {data?.lastErrorTime && (
          <KeyValuePair
            labelClassName="w-64"
            name="Latest Error Time"
            value={
              moment(data.lastErrorTime).calendar() ?? NO_VALUE_PLACEHOLDER
            }
          />
        )}
        {data?.lastErrorMsg && (
          <KeyValuePair
            labelClassName="w-64"
            name="Latest Error Message"
            value={data?.lastErrorMsg || ""}
          />
        )}
      </div>
    </ListGroup>
  );
}
