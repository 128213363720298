import React from 'react';
import Spinner from '../atomic/Spinner';

export default function LoadingSpinner(props) {
  return (
    <div className='flex justify-center flex-grow item-center py-2'>
      <Spinner {...props} />
    </div>
  );
}
