import { createSlice } from "@reduxjs/toolkit";
import type { AppState } from "./store";

const initialState = {
  identifier: "",
  mac: "",
  selectedModal: null,
  deviceData: null,
  newCustomerId: null,
};

export const DEVICE_STATUS_MODAL = {
  DETAILS: "device_details",
  EDIT: "device_edit",
  VERSION_UPDATE: "device_version_update",
  ADD_DEVICE: "add_device",
};

export const deviceStatusSlice = createSlice({
  name: "deviceStatus",
  initialState,
  reducers: {
    updateDeviceStatusIdentifier(state, action) {
      state.identifier = action.payload;
    },
    updateDeviceMacAddress(state, action) {
      state.mac = action.payload;
    },
    selectModal(state, action) {
      state.selectedModal = action.payload;
    },
    setDeviceData(state, action) {
      state.deviceData = action.payload;
    },
    setNewCustomerId(state, action) {
      state.newCustomerId = action.payload;
    },
  },
});

export default deviceStatusSlice.reducer;

export const {
  updateDeviceStatusIdentifier,
  updateDeviceMacAddress,
  selectModal,
  setDeviceData,
  setNewCustomerId,
} = deviceStatusSlice.actions;

export const selectDeviceStatusIdentifier = (state: AppState) =>
  state[deviceStatusSlice.name].identifier;
export const selectDeviceMacAddress = (state: AppState) =>
  state[deviceStatusSlice.name].mac;
export const selectedModal = (state: AppState) =>
  state[deviceStatusSlice.name].selectedModal;
export const selectedDeviceData = (state: AppState) =>
  state[deviceStatusSlice.name].deviceData;
export const selectNewCustomerId = (state: AppState) =>
  state[deviceStatusSlice.name].newCustomerId;
