import { useEffect, useState } from 'react';

export function useBluetoothAvailability() {
  const [isAvailable, setIsAvailable] = useState(null);
  const [message, setMessage] = useState(
    'Checking Bluetooth support availability'
  );

  async function handleAvailabilityChange() {
    try {
      const isAvail = await navigator.bluetooth.getAvailability();
      setIsAvailable(isAvail);
      if (isAvail) {
        setMessage(
          // `Bluetooth Support Available: Click 'Scan' to Discover Devices`
          `Bluetooth Search Available`
        );
      } else {
        setMessage(
          // 'Sorry, your device doesn’t support bluetooth.'
          `Bluetooth Search Unavailable`
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (navigator && navigator.bluetooth) {
      navigator.bluetooth.onavailabilitychanged = handleAvailabilityChange;
      handleAvailabilityChange();
    } else {
      setIsAvailable(false);
      setMessage(
        'Sorry, it seems like the bluetooth support is not available on this browser .'
      );
    }
  }, []);

  return { isAvailable, isLoading: isAvailable === null, message };
}
