import { useEffect, useState, useRef } from "react";
import styled from 'styled-components';

const DropdownMenu = styled.div`
    position: absolute;
    transform: translateY(4px);
    width: 35vh;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: auto;
    max-height: 200px;
    background-color: #fff;
    display: block;
`

const Icon = () => {
    return (
        <svg height="20" width="20" viewBox="0 0 20 20">
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
    );
};

const CustomerDropdown = ({
    placeHolder,
    options,
    isSearchable,
    selectedValue,
    setSelectedValue
}) => {
    const [showMenu, setShowMenu] = useState(false)
    const [searchValue, setSearchValue] = useState("");
    const searchRef = useRef();

    useEffect(() => {
        setSearchValue("");
        if (showMenu && searchRef.current) {
            (searchRef.current as HTMLInputElement)?.focus();
        }
    }, [showMenu]);

    useEffect(() => {
        const handler = () => setShowMenu(false);
        window.addEventListener("click", handler);
        return () => {
            window.removeEventListener("click", handler);
        }
    });

    const onSearch = (e) => {
        setSearchValue(e.target.value);
    };

    // Filters customers list by what is typed into the input; Set to >= 0 to find input anywhere within the customer's name
    const getOptions = () => {
        if (!searchValue) {
            return options;
        }
        return options.filter((option) => option.customer_name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0);
    };

    const handleInputClick = (e) => {
        e.stopPropagation();
        setShowMenu(!showMenu)
    };

    const getDisplay = () => {
        return selectedValue.customer_name;
    };

    const onItemClick = (option) => {
        setSelectedValue(option)
    };

    const isSelected = (option) => {
        if (!selectedValue) {
            return false;
        }
        return selectedValue.id === option.id;
    };

    return (
        <div>
            {/* Input Display */}
            <div className="mb-2.5 ml-2.5">
                <div className="text-base text-gray-400">
                    Customer:
                </div>
                <div className="text-left relative w-max -mb-1">
                    <div onClick={handleInputClick} className="pb-1.5 flex items-center select-non hover:cursor-pointer">
                        <div className="text-4xl font-bold ">{getDisplay()}</div>
                        <div className="dropdown-tools">
                            <Icon />
                        </div>
                    </div>
                </div>
            </div>
            {/* Dropdown menu */}
            {showMenu && (
                <DropdownMenu>
                    {/* Search Field */}
                    {isSearchable && (
                        <div className="p-1.5 bg-slate-200">
                            <input
                                className="box-border w-100 p-1.5 border border-gray-300 rounded-md"
                                onChange={onSearch}
                                value={searchValue}
                                ref={searchRef} />
                        </div>
                    )}
                    {/* Dropdown options */}
                    {getOptions().map((option) => (
                        <div
                            onClick={() => onItemClick(option)}
                            key={option.id}
                            className={`p-1 cursor-pointer hover:bg-blue-400 ${isSelected(option) ? "bg-blue-600" : "bg-inherit"} ${isSelected(option) && "selected"}`}
                        >
                            {option.customer_name}
                        </div>
                    ))}
                </DropdownMenu>
            )}
        </div>
    );
};

export default CustomerDropdown