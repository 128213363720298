import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useSWRConfig } from "swr";

import { Login } from "application/pages/Login";
import SpinnerWithLayout from "components/molecule/SpinnerWithLayout";
import { setAxiosDefault } from "domain/auth/lib/axios";
import { PRE_CONFIG_APIS } from "features/pre-configs/api";
import useDocumentIcon from "hooks/useDocumentIcon";
import useDocumentTitle from "hooks/useDocumentTitle";
import { SERVER_API } from "lib/constants";
import {
  selectPartnerCustomer,
  updateLogin,
} from "providers/redux/globalSlice";
import { AsyncLifecycle } from "utils";
import {
  CUSTOMERS_LIST_API,
  DEVICE_CONFIG_ALL_API,
} from "../../../features/kaidu-config-server";
import {
  checkIsJWTTokenValid,
  getAuthTokenFromLocalStorage,
  getAxiosAuthToken,
  getLocalStorageAuthItem,
  setTokenToLocalStorage,
} from "../lib";
import { isAxiosTokenValid } from "./processors";
import { getSessionStorage } from "features/local-storage";
import { VerifyOTP } from "application/pages/VerifyOTP";
import usePartnerSession from "hooks/usePartnerSession";

/**
 * check the token in local storage
 * if it expired, logout
 */
// function controlAuth() {
//   console.debug("window is focused");
//   try {
//     const isValid = checkIsAuthInLocalStorageValid(partnerName);
//     const token = getAuthTokenFromLocalStorage(partnerName);
//     // console.debug("check token for reload", token);
//     // console.debug("controlAuth pathname", window.location.pathname);
//     const pathHasLogin = window.location.pathname?.includes("/login");
//     if (!isValid && token) {
//       logout();
//       !pathHasLogin && window.location.reload();
//     }
//   } catch (error) {
//     console.error(error);
//   }
// }

function getShouldCheckLoginStatus(location) {
  const isPathForLogin = location?.pathname === "/login";
  const isPathForLoginSuccess = location?.pathname?.includes("/login/success");
  return !isPathForLogin && !isPathForLoginSuccess;
}

function getValidToken(token1) {
  if (token1 && checkIsJWTTokenValid(token1)) {
    return token1;
  }
  return;
}

/**
 * check axios default header, if it's valid, do nothing
 * if it's not valid, check if local storage has valid token
 * if it's invalid, return login page
 * if it's valid, set it in axios default header and mutate
 */
export function AuthenticationHandler({ children, ...optionals }) {
  // Hooks
  const location = useLocation();
  const { pathname, search, state } =
    useLocation<{ error?: string; email?: string }>();

  const history = useHistory();
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const isPathForLogin = pathname === "/login";
  const isPathForOTPVerify = pathname === "/verify_otp";
  const isPathForLoginSuccess = pathname?.includes("/login/success");
  const isPathForLoginFailure = pathname
    ?.toLowerCase()
    .includes("/login/failure");
  const shouldCheckLoginStatus = getShouldCheckLoginStatus(location);

  const partnerCustomer = useSelector(selectPartnerCustomer);
  const partnerName = partnerCustomer?.customer_name;
  const [status, setStatus] = useState<AsyncLifecycle>(AsyncLifecycle.IDLE);
  useDocumentTitle(`${partnerName || "Kaidu"} Management`);
  useDocumentIcon(partnerCustomer?.customer_config?.image);
  const { getPartnerId } = usePartnerSession();

  const customerName = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get("customer");
  }, [search]);

  useEffect(() => {
    if (status === AsyncLifecycle.IDLE) {
      // init check token in axios header
      // console.log(`init token check...`);
      const axiosToken = getAxiosAuthToken();
      // console.log(`Init with token: ${axiosToken}`);
      if (!isAxiosTokenValid(axiosToken)) {
        console.error(`invalid axiosToken`);
        // setStatus(AsyncLifecycle.PENDING);
        // find at least one valid token
        const testToken = process.env?.REACT_APP_LOCAL_TOKEN;
        const reqToken = getAxiosAuthToken();
        const localStorageToken = getAuthTokenFromLocalStorage(partnerName);
        const validToken =
          getValidToken(testToken) ||
          getValidToken(reqToken) ||
          getValidToken(localStorageToken);
        if (!validToken) {
          // invalid
          console.error(`no token is valid`);

          if (localStorageToken) {
            // if token exist
            // should return /login or /login/success to fetch new token
            setStatus(AsyncLifecycle.REJECTED);
          } else {
            // controlAuth(shouldCheckLoginStatus);
            setStatus(AsyncLifecycle.REJECTED);
          }
        } else {
          const localPartnerName = getSessionStorage("partnerCustomer");
          const localPartnerCustomerId = getPartnerId();
          setAxiosDefault(
            validToken,
            partnerCustomer?.customer_id || localPartnerCustomerId,
            !localPartnerName
          );
          setTokenToLocalStorage(validToken, partnerName);

          mutate(SERVER_API.BASE);
          mutate(PRE_CONFIG_APIS.BUILDING);
          mutate(PRE_CONFIG_APIS.FLOOR);
          mutate(PRE_CONFIG_APIS.LOCATION);
          mutate(CUSTOMERS_LIST_API);
          mutate(DEVICE_CONFIG_ALL_API);

          setStatus(AsyncLifecycle.PENDING);
        }
      } else {
        setStatus(AsyncLifecycle.FULFILLED);
      }
    }

    if (status === AsyncLifecycle.PENDING) {
      // console.log(`Environment: ${NODE_ENV}`);

      setTimeout(() => {
        setStatus(AsyncLifecycle.FULFILLED);
      }, 400);
    }

    if (status === AsyncLifecycle.REJECTED) {
      const axiosToken = getAxiosAuthToken();
      if (isAxiosTokenValid(axiosToken)) {
        setTokenToLocalStorage(axiosToken, partnerName);
        setStatus(AsyncLifecycle.FULFILLED);
      } else if (
        !isPathForLoginFailure &&
        !isPathForLoginSuccess &&
        !isPathForOTPVerify
      ) {
        history.push(
          customerName ? `/login?customer=${customerName}` : "/login"
        );
      } else if (isPathForOTPVerify && !state?.email) {
        history.push(
          customerName ? `/login?customer=${customerName}` : "/login"
        );
      }
    }

    if (status === AsyncLifecycle.FULFILLED && shouldCheckLoginStatus) {
      const userData = getLocalStorageAuthItem(partnerName);
      dispatch(updateLogin(userData));
    }
  }, [
    status,
    shouldCheckLoginStatus,
    dispatch,
    history,
    mutate,
    customerName,
    partnerCustomer,
    isPathForLoginFailure,
    isPathForLoginSuccess,
    isPathForOTPVerify,
    state?.email,
    partnerName,
    getPartnerId,
  ]);

  /**
   * When user refocus to the website, it should check if the token is still valid
   */
  // useEffect(() => {
  //   window.addEventListener("focus", () => controlAuth());
  // }, []);

  if (
    // show login page when is not login path
    !isPathForLoginSuccess &&
    !isPathForOTPVerify &&
    (isPathForLogin || isPathForLoginFailure)
  ) {
    return <Login />;
  }

  if (status === AsyncLifecycle.PENDING) {
    return <SpinnerWithLayout />;
  }

  // not /login/success, is /login, no token in local storage
  if (
    !isPathForLoginSuccess &&
    (isPathForLogin || isPathForLoginFailure) &&
    !getAuthTokenFromLocalStorage(partnerName) &&
    !isPathForOTPVerify
  ) {
    return <Login />;
  }

  if (isPathForOTPVerify) {
    return state?.email ? <VerifyOTP /> : <Login />;
  }

  // fulfilled
  return <>{children}</>;
}
