import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "components/atomic/Button";
import { H3 } from "components/atomic/Headings";
import { Tooltip } from "components/atomic/Tooltip";
import { Chip } from "components/molecule/Chip";
import { KeyValuePair } from "components/molecule/KeyValuePair";
import LoadingSpinner from "components/molecule/LoadingSpinner";
import { parseString } from "features/date";
import {
  KaiduDevicesListItem,
  removeCommandById,
  sendUpdateCommand,
  useFirmwareUpdateResult,
  useKaiduFirmwareList,
} from "features/kaidu-config-server";
import { notifyError, notifySuccess } from "lib/services/notification";
import { ErrorMsg } from "../../../components/atomic/ErrorMsg";
import {
  extractHardwareVersion,
  filterByHardwareVersion,
} from "../../../features/firmware";
import { isFilledArray } from "../../../utils";
import { FirmwareUpdateStatus } from "../types";
import { FirmwareUpdateInputGroup } from "./FirmwareUpdateInputGroup";

const MASTER_FIRMWARE = "master_firmwareversion";
const SLAVE_FIRMWARE = "slave_firmwareversion";

function getDisplayedFirmwareList(fetchedList, allowedHardwareVersions) {
  if (!isFilledArray(fetchedList)) {
    return [];
  }

  if (!isFilledArray(allowedHardwareVersions)) {
    const displayedFirmwareList = fetchedList
      .map((item) => item.firmware_url)
      .sort();
    return displayedFirmwareList;
  }

  const filtered = filterByHardwareVersion(
    fetchedList,
    allowedHardwareVersions
  );
  const displayedFirmwareList = filtered
    .map((item) => item.firmware_url)
    .sort();
  return displayedFirmwareList;
}

/**
 * e.g. "00-03-00-07-03" -> "0.3.0.7.3"
 */
function getDisplayedVersion(longVersionString: string) {
  try {
    if (!longVersionString || typeof longVersionString !== "string") {
      console.error(`Invalid version: ${longVersionString}`);
      throw new TypeError(`Not available`);
    }

    if (longVersionString.length < 14) {
      throw new Error("Invalid version. Too short");
    }

    const arr = longVersionString.split("-");
    const versionNumbers = [];
    let prefix = "";
    arr.forEach((item) => {
      const v = Number(item);
      if (!isNaN(v)) versionNumbers.push(v);
      else if (String(item).toLowerCase() === "pre") prefix = "pre-";
    });
    return `${prefix}${versionNumbers.join(".")}`;
  } catch (error) {
    return error?.message;
  }
}

function getDisplayedStatus(value: FirmwareUpdateStatus) {
  let text;
  switch (value) {
    case 0:
      text = "Not started";
      break;
    case 1:
      text = "Pending";
      break;
    case 2:
      text = "Success";
      break;
    case 3:
      text = "Failure";
      break;
    case 4:
      text = "Unknown";
      break;
    default:
      text = "Unknown";
      console.warn(`Unsupported status value ${value}`); // eslint-disable-line no-console
      break;
  }
  return text;
}

function getColorByStatus(value: FirmwareUpdateStatus) {
  let color;
  switch (value) {
    case 0:
      color = "default";
      break;
    case 1:
      color = "primary";
      break;
    case 2:
      color = "success";
      break;
    case 3:
      color = "error";
      break;
    case 4:
      color = "warning";
      break;
    default:
      color = "warning";
      console.warn(`Unsupported status value ${value}`); // eslint-disable-line no-console
      break;
  }
  return color;
}

/**
 *
 * @returns e.g. 'Thu Nov 25 2032 20:34:47 GMT-0500 (Version: 0.3.1)'
 */
function getDisplayedFirmwareUpdateResultString(data) {
  if (!data) {
    return "Not available";
  }

  const { createDateTime, firmware_hw_sw_version } = data || {};
  const formattedDate = parseString(createDateTime).toLocaleString();
  const formattedVersion = getDisplayedVersion(firmware_hw_sw_version);

  return `${formattedDate} (Version: ${formattedVersion})`;
}

/**
 * display a firmware list of all available firmware versions
 * send update command
 */
export function UpdateFirmwareDialog({
  identifier = "",
  value = "",
  ...optionals
}) {
  const { onHide, data } = optionals;
  const {
    mac_address,
    kaidu_device_list,
  }: { mac_address: string; kaidu_device_list: KaiduDevicesListItem } =
    data || {};

  // Hook
  const {
    data: firmwareUpdateResult,
    error: firmwareUpdateResultError,
    isLoading,
  } = useFirmwareUpdateResult(mac_address);
  const {
    firmwareList,
    isLoading: loadingFirmwareList,
    isError: firmwareListError,
  } = useKaiduFirmwareList();
  const { register, handleSubmit } = useForm();
  const loading = useMemo(
    () => isLoading || loadingFirmwareList,
    [isLoading, loadingFirmwareList]
  );
  const loadingError = useMemo(
    () => firmwareUpdateResultError || firmwareListError,
    [firmwareUpdateResultError, firmwareListError]
  );
  const masterHardware = useMemo(
    () =>
      kaidu_device_list?.hw_sw_version
        ? [extractHardwareVersion(kaidu_device_list?.hw_sw_version)]
        : ["01", "03"],
    [kaidu_device_list]
  );
  const masterFirewareList = useMemo(() => {
    return getDisplayedFirmwareList(firmwareList, masterHardware);
  }, [firmwareList, masterHardware]);
  const slaveFirewareList = useMemo(() => {
    return getDisplayedFirmwareList(firmwareList, ["02"]);
  }, [firmwareList]);

  const defaultVersion = {
    [MASTER_FIRMWARE]: masterFirewareList[masterFirewareList.length - 1],
    [SLAVE_FIRMWARE]: slaveFirewareList[slaveFirewareList.length - 1],
  };

  const handleDeleteBtnClick = useCallback(async () => {
    // handle the click event
    if (firmwareUpdateResult?.id) {
      await removeCommandById(firmwareUpdateResult.id)
        .then(() => notifySuccess(`Update command is removed successfully!`))
        .catch((error) => notifyError(error?.message));
    }
    onHide && onHide();
  }, [firmwareUpdateResult, onHide]);

  const [expandedField, setExpandedField] = useState("");

  if (loading) {
    // console.debug('firmwareUpdateResult', firmwareUpdateResult);
    return <LoadingSpinner />;
  }
  if (loadingError) return <ErrorMsg text={loadingError?.message} />;

  /**
   * submit request to send update firmware command, to the user selected version
   */
  const handleUpdate = async (data) => {
    if (!expandedField) return;
    try {
      const deviceId = mac_address;
      let firmwareVersion =
        data[expandedField === "master" ? MASTER_FIRMWARE : SLAVE_FIRMWARE];
      if (!firmwareVersion) {
        firmwareVersion =
          defaultVersion[
            expandedField === "master" ? MASTER_FIRMWARE : SLAVE_FIRMWARE
          ];
      }
      await sendUpdateCommand(
        deviceId,
        firmwareVersion,
        expandedField === "master" ? "OT" : "DF"
      );
      notifySuccess(`Firmware update command is sent to device ${identifier}`);
      // console.log(`handleUpdate finished`);
    } catch (error) {
      notifyError(error?.message);
    }
    onHide && onHide();
  };

  const firmwareUpdateResultString =
    getDisplayedFirmwareUpdateResultString(firmwareUpdateResult);

  const masterFirmwareVersion = getDisplayedVersion(
    kaidu_device_list?.hw_sw_version
  );
  const slaveFirmwareVersion = getDisplayedVersion(
    kaidu_device_list?.slave_firmware_version
  );

  // const displayedFirmwareList = getDisplayedFirmwareList(firmwareList);
  const masterFirmwareVersionLabel = slaveFirmwareVersion
    ? "Master Firmware Version"
    : "Firmware Version";

  const shouldDisableUpdate =
    firmwareUpdateResult &&
    (firmwareUpdateResult?.update_status === FirmwareUpdateStatus.NOT_STARTED ||
      firmwareUpdateResult?.update_status === FirmwareUpdateStatus.PENDING);

  return (
    <>
      <div className="mb-6">
        <KeyValuePair
          labelClassName={"w-64"}
          name={"Device ID"}
          value={identifier}
        ></KeyValuePair>
        <KeyValuePair
          labelClassName={"w-64"}
          name={"MAC Address"}
          value={mac_address}
        ></KeyValuePair>
      </div>
      <H3>Firmware</H3>
      <div className={"flex flex-col"}>
        <KeyValuePair
          labelClassName={"w-64"}
          name={masterFirmwareVersionLabel}
          value={masterFirmwareVersion}
        >
          <FirmwareUpdateInputGroup
            register={register}
            name={MASTER_FIRMWARE}
            className={"ml-4"}
            onClick={() => setExpandedField("master")}
            isExpanded={expandedField === "master"}
            allowedHardwareVersions={masterHardware}
            key={"master-firmware-update-input-group"}
            disabled={shouldDisableUpdate}
            firmwareList={masterFirewareList}
          />
        </KeyValuePair>
        {slaveFirmwareVersion ? (
          <KeyValuePair
            labelClassName={"w-64"}
            name={"Slave Firmware Version"}
            value={slaveFirmwareVersion}
          >
            <FirmwareUpdateInputGroup
              register={register}
              name={SLAVE_FIRMWARE}
              className={"ml-4"}
              onClick={() => setExpandedField("slave")}
              isExpanded={expandedField === "slave"}
              allowedHardwareVersions={["02"]}
              key={"slave-firmware-update-input-group"}
              disabled={shouldDisableUpdate}
              firmwareList={slaveFirewareList}
            />
          </KeyValuePair>
        ) : null}
        <KeyValuePair
          labelClassName={"w-64"}
          name={"Last update command"}
          value={firmwareUpdateResultString}
        >
          {firmwareUpdateResult ? (
            <Chip
              className={"ml-4"}
              label={getDisplayedStatus(firmwareUpdateResult?.update_status)}
              color={getColorByStatus(firmwareUpdateResult?.update_status)}
            ></Chip>
          ) : null}
          {shouldDisableUpdate && (
            <Button
              className="ml-2"
              variant="light"
              onClick={handleDeleteBtnClick}
            >
              Delete
            </Button>
          )}
        </KeyValuePair>
      </div>
      <div className="mt-8 gap-x-4 flex justify-end">
        <Tooltip />
        <div
          data-tip={
            shouldDisableUpdate
              ? "Please wait until last update is finished"
              : null
          }
        >
          <Button
            onClick={handleSubmit(handleUpdate)}
            disabled={shouldDisableUpdate}
          >
            Confirm
          </Button>
        </div>
        <div>
          <Button variant="light" onClick={onHide}>
            Close
          </Button>
        </div>
      </div>
    </>
  );
}

export default UpdateFirmwareDialog;
