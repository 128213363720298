import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider as ReduxProvider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import SpinnerWithLayout from "components/molecule/SpinnerWithLayout";
import { GOOGLE_CLIENT_ID } from "features/auth";
import store from "providers/redux/store";

export function ReduxGroupProvider({ children, ...optionals }) {
  const persistor = persistStore(store);

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={<SpinnerWithLayout />} persistor={persistor}>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          {children}
        </GoogleOAuthProvider>
      </PersistGate>
    </ReduxProvider>
  );
}
