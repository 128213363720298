import Toast from 'components/atomic/Toast';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectToastContent,
  selectIsToastOpen,
  setOpenToast,
} from 'features/users/lib/redux/usersSlice';

export function UserToastContainer() {
  //Hooks
  const dispatch = useDispatch();

  //Global state
  const toastContent = useSelector(selectToastContent);
  const isToastOpen = useSelector(selectIsToastOpen);

  return (
    <div>
      {/* <Toast
        show={isToastOpen}
        onClose={() => dispatch(setOpenToast(false))}
        {...toastContent}
      /> */}
    </div>
  );
}
