import useSWR from "swr";
import { helpers } from "utils";
import { checkIsValidID } from "../processor";
import {
  KAIDU_CONFIG_LIST_API,
  KAIDU_CONFIG_LIST_CUSTOMER_API,
  KAIDU_CONFIG_LIST_DEVICE_API,
  KAIDU_CONFIG_LIST_ITEM_API,
} from "./api";
import { KaiduConfigurationsListItem } from "./types";

const { swrFetcher } = helpers;

export function useKaiduConfigurationsList(
  mounted = true,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const { data, error } = useSWR<KaiduConfigurationsListItem[], Error>(
    mounted ? KAIDU_CONFIG_LIST_API : null,
    swrFetcher,
    options
  );
  return {
    configList: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useSingleKaiduConfigurationItem(
  id: string,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const { data, error } = useSWR(
    checkIsValidID(id) ? `${KAIDU_CONFIG_LIST_ITEM_API}/${id}` : null,
    swrFetcher,
    options
  );
  return {
    data,
    isLoading: checkIsValidID(id) && !error && !data,
    isError: error,
  };
}

export function useKaiduConfigOfCustomer(customerID: string) {
  const url = `${KAIDU_CONFIG_LIST_CUSTOMER_API}/${encodeURIComponent(
    customerID
  )}`;
  const { data, error } = useSWR<KaiduConfigurationsListItem[], Error>(
    customerID ? url : null,
    swrFetcher
  );
  return {
    configList: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useKaiduConfigOfDevice(
  deviceID: string,
  options: any = { revalidateOnFocus: false, refreshInterval: 0 }
) {
  const { data, error } = useSWR<KaiduConfigurationsListItem[], Error>(
    deviceID ? `${KAIDU_CONFIG_LIST_DEVICE_API}/${deviceID}` : null,
    swrFetcher,
    options
  );
  return {
    configList: data,
    isLoading: !error && !data,
    isError: error,
  };
}
