import axios from "axios";
import { ConfigurationInServer } from "types/interfaces";
import { handle } from "utils/helper";
import { getErrorMessageInResponse, handleAxiosResultData } from "../processor";
import { DEVICE_CONFIG_API } from "./api";
import { KaiduDeviceConfiguration } from "./types";

export async function fetchAllConfiguration(): Promise<
  Array<KaiduDeviceConfiguration>
> {
  const [result, resultErr] = await handle(axios.get(DEVICE_CONFIG_API));
  if (resultErr) {
    console.error(`fetchAllConfiguration failed: ${resultErr.message}`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  return handleAxiosResultData(result);
}

/**
 * @description fetch configuration data from Server API
 * @param  {string} mac
 * @returns Promise
 */
export async function fetchConfigurationByMAC(
  mac: string
): Promise<ConfigurationInServer> {
  // console.log(`fetchConfigurationByMAC is called`);
  const url = `${DEVICE_CONFIG_API}/${encodeURIComponent(mac)}`;
  // console.log(`fetchConfigurationByMAC URL: ${url}`);
  //send then request n times
  let n = 3;
  let result;
  let resultErr;
  while (!result && n > 0) {
    [result, resultErr] = await handle(axios.get(url));
    n--;
  }

  if (!result) {
    // console.error(`fetchConfigurationByMAC failed`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  return handleAxiosResultData(result);
}
