
import { Select2 } from 'components/molecule/Select';
import { Button } from '../../../../components/atomic/Button';
import { useController } from 'react-hook-form';
import {
  FormLabel,
} from '../../components/atomic/ClientInformationModalStyles';
import { findDefaultCustomerType, useCustomerTypeList } from 'features/kaidu-config-server/customer-type';
import { Spinner } from '../../../../components/atomic/Spinner';
import { ErrorMsg } from '../../../../components/atomic/ErrorMsg';


const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    maxWidth: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-grid',
  }),
};

/**
 * Form name: timezone
 */
export function CustomerTypeSelector({ control, name = 'customer_type' }) {
  const { customerTypeList, isLoadingCustomerTypeList, isCustomerTypeListError } = useCustomerTypeList();

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  if (isLoadingCustomerTypeList) {
    return <Spinner />
  }

  if (isCustomerTypeListError) {
    return <ErrorMsg />
  }

  const customerTypeOptions = customerTypeList?.map(item => {
    return {
      value: item?.customer_type_id,
      label: item?.name,
    };
  });

  return (
    <>
      <FormLabel htmlFor={name}>Client Type</FormLabel>
      <div className='flex gap-x-4'>
        <Select2
          options={customerTypeOptions}
          value={value}
          onChange={(nextValue) => {
            onChange(nextValue?.value);
          }}
          name={name}
          styles={customStyles}
          selectClassName='flex-1 lg:flex-none lg:w-full'
          className='flex-1'
        />
        <Button
          variant='secondary'
          onClick={() => {
            const defaultOption = findDefaultCustomerType(customerTypeList);
            onChange(defaultOption?.customer_type_id);
          }}
        >
          Use default
        </Button>
      </div>
    </>
  );
}
