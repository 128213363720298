//@ts-ignore
import _ from "lodash";
import useSWR from "swr";

import { isFilledArray, swrFetcher } from "utils";
import { KAIDU_FIRMWARE_LIST_API } from "./api";
import { sortFirmwareList } from "./processors";
import { FirmwareDTO } from "./types";

/**
 * fetch firmware lists
 */
export function useKaiduFirmwareList(
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  // console.debug(`useKaiduFirmwareList is called with ${KAIDU_FIRMWARE_LIST_API}`);
  const { data, error } = useSWR<FirmwareDTO[], Error>(
    KAIDU_FIRMWARE_LIST_API,
    () => swrFetcher(KAIDU_FIRMWARE_LIST_API),
    options
  );
  return {
    firmwareList: sortFirmwareList(data),
    latest:
      isFilledArray(data) &&
      (_.sortBy(data, "firmware_url")[data.length - 1]?.firmware_url as string),
    isLoading: !error && !data,
    isError: error,
  };
}

// return the latest firmware version
export function useLatestFirmwareVersionFilesubpath() {
  const { firmwareList, isLoading, isError } = useKaiduFirmwareList();

  let latest;
  if (isFilledArray(firmwareList)) {
    latest = firmwareList[firmwareList.length - 1]?.firmware_url;
    console.debug(`Latest firmware: ${latest}`);
  }

  return { latest, isLoading, isError };

  // for testing
  // return {latest: '', isLoading: false, isError: false};
}
