import styled, { useTheme } from 'styled-components';

export const Dd = styled.dd`
  color: ${(props) => props.theme.palette.primary};
`;

export const Dt = styled.dt`
  color: ${(props) => props.theme.palette.grayscale[3]};
`;

export function KeyValuePair({ name, ...optionals }) {
  const {
    labelClassName = 'w-48',
    valueClassName = 'max-w-full break-words flex items-center mb-0',
    value,
    children,
    className = 'flex flex-wrap w-full items-center my-2',
    ...rest
  } = optionals;
  // const theme = useTheme();

  return (
    <div className={className} {...rest}>
      <Dt className={labelClassName}>{name}: </Dt>
      <Dd className={valueClassName}>
        {value} {children}
      </Dd>
    </div>
  );
}
