import axios from "axios";
import { BASE_API } from "lib/constants";

export interface VerifyDashboardResponse {
  customer_id: string;
  customer_name: string;
}

export const rediectToPartnerDashboard = async (): Promise<void> => {
  const urlObj = new URL(window.location.href);
  const { searchParams, origin } = urlObj;

  let customerName;
  searchParams.forEach((value, key) => {
    if (key === "customer") customerName = value;
  });

  if (customerName) return;
  const partner_dashboard: VerifyDashboardResponse = await axios
    .post(`${BASE_API}/verify_dashboard`, {
      dashboard_url: origin,
      isManagementDashboard: true,
    })
    .then((response) => response.data);

  const { customer_name } = partner_dashboard || {};
  if (!customer_name) return;
  window.location.replace(
    `${origin}/login?customer=${encodeURI(customer_name)}`
  );
};
