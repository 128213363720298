//@ts-nocheck
import Button from 'components/atomic/Button';
import BaseModal from 'components/molecule/BaseModal';
import { InfoCard } from 'components/molecule/InfoCard';
import {
  clearModalData, setOpenModal,
  setToast
} from 'features/users/lib/redux/usersSlice';
import { useAsync } from 'hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSWRConfig } from 'swr';
import { AsyncLifecycle, capitalizeFirstLetter } from 'utils';
import { User, deleteUser, USERS_LIST_ALL_API } from 'features/kaidu-config-server/users-list';
import { toast } from 'react-toastify';

const itemName = 'User';

export function DeleteUserModal({ user, ...optionals }) {
  // Props
  const { userId, userEmail } = user || {};

  // Hooks
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();

  const submitData = async () => {
    const res = await deleteUser(userId);
    return res;
  };

  const { execute, status, value, error } = useAsync(submitData, false);

  const handleFulfilled = (value) => {
    console.log('modal request fulfilled');

    dispatch(clearModalData());
    dispatch(setOpenModal(false));
    //show toast
    toast.success(`Client ${userEmail ?? ''} is deleted`);

    mutate(USERS_LIST_ALL_API);
  };

  const handleRejected = (error) => {
    //cleanup
    dispatch(clearModalData());
    dispatch(setOpenModal(false));
    //show toast
    toast.error(`${error?.message}` ?? `Failed to delete client `);
  };

  useEffect(() => {
    status === AsyncLifecycle.FULFILLED && handleFulfilled(value);
    status === AsyncLifecycle.REJECTED && handleRejected(error);
  }, [status]);

  return (
    <BaseModal
      show={true}
      onHide={() => {
        dispatch(setOpenModal(false));
      }}
      title={`Delete ${capitalizeFirstLetter(itemName)}: ${userEmail}`}
      size='xl'
    >
      <div {...optionals}>
        <p>
          Are you sure you want to delete “{userEmail}”? You cannot undo this action.
        </p>
        <InfoCard
          text={
            `Once you delete a ${itemName}, all of the information and data of this ${itemName} will be lost. If you still want to delete it, click the "Delete" button`
          }
          subHeadingText={'Warn'}
          icon={'exclamation-triangle'}
        />
      </div>
      <div className='flex justify-end mt-4'>
        <Button onClick={execute} variant='danger'>
          Delete
        </Button>
      </div>
    </BaseModal>
  )
}
