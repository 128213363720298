import axios from "axios";
import {
  fetchLatestSoftwareVersion,
  findLatestCompatibleSoftwareVersion,
} from "features/kaidu-config-server/kaidu-firmware-list";
import { handle, helpers } from "utils";
import { fetchAsArrayBuffer } from "../axios";
import { getErrorMessageInResponse } from "../kaidu-config-server";
import { FIRMWARE_FILE_BASE, FIRMWARE_VERSIONS_JSON } from "./apis";
import { appendBinAsFileExtension, formateHardwareVersion } from "./processors";

/**
 * return the latest compatible software version for a hardware version
 */
export async function fetchLatestCompatibleSoftwareVersion(
  hardWareVersion: string,
  accessToken?: string
): Promise<string | undefined> {
  console.log(`fetchLatestCompatibleSoftwareVersion starts`);
  const formatedHardwareVersion = formateHardwareVersion(hardWareVersion);
  const [result, resultErr] = await handle(
    axios.get(
      FIRMWARE_VERSIONS_JSON,
      helpers.createAxiosTokenConfig(accessToken)
    )
  );

  if (!result) {
    console.error(`fetchLatestCompatibleSoftwareVersion failed`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  if (!result.data) {
    throw new Error(
      `Got response from server but no software version data in the response`
    );
  }

  console.log(
    `Latest software version: ${result?.data?.firmware[0]?.software}`
  );
  const latest = findLatestCompatibleSoftwareVersion(
    formatedHardwareVersion,
    result?.data
  );

  return latest;
}

/**
 * fetch binary file
 */
export async function fetchFirmWare(version: string): Promise<ArrayBuffer> {
  const formattedVersion = appendBinAsFileExtension(version);
  const url = `${FIRMWARE_FILE_BASE}/${formattedVersion}`; //e.g. http://dev2.deeppixel.ai/v0.1.5.bin

  return fetchAsArrayBuffer(url, {
    headers: null,
  });
}

export async function fetchLatestVersionFirmware(): Promise<ArrayBuffer> {
  const version = await fetchLatestSoftwareVersion();
  return await fetchFirmWare(version);
}
