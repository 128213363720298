import React from 'react';
import Button from '../atomic/Button';
import Icon from '../atomic/Icon';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  white-space: nowrap;
  width: 180px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  color: white;
  border: 2px solid #0d6efd;
  background-color: #0d6efd;
`;

export function AddBtn({ children, ...rest }) {
  return (
    <StyledButton {...rest}>
      <Icon name={'plus-circle'} className='mr-2' />
      {children}
    </StyledButton>
  );
}
