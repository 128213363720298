import { CustomersListItem } from "features/kaidu-config-server";
import { AddCustomerModal } from "./AddCustomerModal";
import { CodeModal } from "./CodeModal";
import { DeleteCustomerModal } from "./DeleteCustomerModal";
import { EditCustomerModal } from "./EditCustomerModal";

export function ModalContainer({
  modalData,
  customersList,
  ...optionals
}: {
  modalData;
  customersList: CustomersListItem[];
  [x: string]: any;
}) {
  const { operationType, customerItem } = modalData || {};
  const { customer_id, customer_name } = customerItem || {};

  const allCustomerNames = customersList?.map(
    (customer) => customer?.customer_name
  );

  return (
    <>
      {operationType === "add" ? (
        <AddCustomerModal allCustomerNames={allCustomerNames} />
      ) : null}
      {operationType === "edit" ? (
        <EditCustomerModal
          allCustomerNames={allCustomerNames}
          customerItem={customerItem}
        />
      ) : null}
      {operationType === "delete" ? (
        <DeleteCustomerModal id={customer_id} name={customer_name} />
      ) : null}
      {operationType === "getCode" ? <CodeModal /> : null}
    </>
  );
}
