import { useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import ErrorMsg from "components/atomic/ErrorMsg";
import { BaseModal } from "components/molecule/BaseModal";
import { getAxiosAuthToken } from "domain/auth";
import AddCustomerForm from "domain/customers/components/modal/AddCustomerForm";
import {
  selectIsModalOpened,
  setOpenModal,
} from "domain/customers/lib/redux/customersSlice";
import { useCustomersList } from "features/kaidu-config-server/customers-list";

export function AddNewCustomerModal() {
  const token = getAxiosAuthToken();

  // Hooks
  const dispatch = useDispatch();
  const { reset, control } = useForm({});
  const isOpen = useSelector(selectIsModalOpened);
  const { customersList, isLoading, isError } = useCustomersList(
    Boolean(token) && isOpen
  );
  const allCustomerNames = useMemo(
    () =>
      isOpen && customersList
        ? customersList.map((customer) => customer.customer_name)
        : [],
    [isOpen, customersList]
  );

  return (
    <BaseModal
      show={isOpen}
      onHide={() => {
        reset();
        dispatch(setOpenModal(false));
      }}
      title={"Create New Client"}
    >
      <div className="flex justify-center align-items-center">
        {isLoading && <Spinner />}
        {isError && <ErrorMsg text={isError?.message} />}
        {!isLoading && customersList && (
          <AddCustomerForm
            allCustomerNames={allCustomerNames}
            control={control}
          />
        )}
      </div>
    </BaseModal>
  );
}
