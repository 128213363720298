import axios from "axios";
import _ from "lodash";
import { handle, helpers, inspect } from "utils";
import { BASE_API } from "../constants";
import { getErrorMessageInResponse } from "../processor";
import { UpdateKaiduDevicesListItemProps } from "./types";

const FOO = "/kaidu_devices_list";
export const KAIDU_DEVICES_LIST_API = `${BASE_API}${FOO}`;

function processKaiduDeviceListItem(data) {
  // console.log(`preprocessed KaiduDeviceListItem: ${inspect(data)}`);

  if (!data.source_id) {
    data.source_id = String(data.mac_address).split(":").join("");
  }
  if (!data.hw_sw_version) {
    data.hw_sw_version = "00-00-00-00-00";
  }
  const result = _.pick(data, UpdateKaiduDevicesListItemProps);
  return result;
}

//  /kaidu_devices_list
export async function postKaiduDevicesList(data) {
  console.log(`postKaiduConfig with data: ${helpers.inspect(data)}`);
  const [result, resultErr] = await helpers.handle(
    axios.post(KAIDU_DEVICES_LIST_API, data)
  );

  if (resultErr) {
    console.error(resultErr);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return result;
}

export async function putKaiduDevicesList(data) {
  // console.log(`putKaiduDevicesList with data: ${inspect(data)}`);
  // console.log('putKaiduDevicesList with data:', data);
  const url = `${KAIDU_DEVICES_LIST_API}/${encodeURIComponent(
    data?.mac_address
  )}`;
  // console.log(`url: ${url}`);
  const submitted = processKaiduDeviceListItem(data);
  console.log(`submitted: ${inspect(submitted)}`);
  // throw new Error('fuck');

  const [result, resultErr] = await handle(axios.put(url, submitted));

  if (resultErr) {
    console.error(resultErr?.message);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return result;
}

export async function fetchKaiduDevicesListItem(mac: string) {
  console.log(`fetchKaiduDevicesListItem with mac: ${mac}`);
  const url = `${KAIDU_DEVICES_LIST_API}/${encodeURIComponent(mac)}`;
  const { data } = (await axios.get(url)) || {};
  return data;
}
