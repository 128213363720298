import _ from "lodash";
import { NIL as NIL_UUID, v4 as uuidv4 } from "uuid";

import {
  fetchKaiduConfigListItem,
  fetchKaiduDevicesListItem,
  postCustomersDeviceData,
  postKaiduConfigListItem,
  processKaiduConfigListSubmission,
  putKaiduConfig,
  putKaiduConfigListItem,
  putKaiduDevicesList,
} from "features/kaidu-config-server";
import {
  fetchCustomerDeviceDataList,
  findCustomerDeviceDataItem,
  processSubmittedCustomerDeviceData,
  putCustomersDeviceData,
} from "features/kaidu-config-server/customers-device-data";
// import { inspect } from 'utils';

async function updateCustomer(combined, nextCustomerId, id) {
  // console.log("updateCustomer", combined, nextCustomerId, id);

  const processed = processKaiduConfigListSubmission({
    ...combined,
    customer_list_id: nextCustomerId,
    id,
  });
  const res2 = await putKaiduConfigListItem(processed);
  return res2;
}

async function updateKaiduDevicesList(combined) {
  // console.log('updateKaiduDevicesList', combined);
  const { next_partner_customer_id, ...deviceData } = combined || {};

  const { id: IdOfKaiduDevice } = await fetchKaiduDevicesListItem(
    combined?.mac_address
  );
  const res1 = await putKaiduDevicesList({
    ...deviceData,
    partner_customer_id: next_partner_customer_id,
    id: IdOfKaiduDevice,
  });
  return res1;
}

export async function updatePlugConfigWithCompositeAPIs(combined) {
  // variables: customer_id, device name, kaidu_device_status
  const {
    customer_id: nextCustomerId,
    customer_list_id: prevCustomerId,
    wifi_ssid,
    wifi_password,
    customers_building_id,
    customers_floor_id,
    customers_location_id,
    rssi_thresholds_id,
    kaidu_configuration_id: configuration_id,
    isCustomerChanged,
    shouldUpdateKaiduDevicesList,
    customer_device_data_id: device_data_id,
    mqtt_configuration_id,
    kaidu_device_id,
  } = combined || {};
  // console.log('updatePlugConfigWithCompositeAPIs combined', combined);
  // return;
  let shouldUpdateCustomer = isCustomerChanged;
  if (isCustomerChanged === undefined)
    shouldUpdateCustomer = nextCustomerId && nextCustomerId !== prevCustomerId;

  if (shouldUpdateKaiduDevicesList) {
    await updateKaiduDevicesList(combined);
  }

  let kaidu_configuration_id = configuration_id || uuidv4();
  let customer_device_data_id = device_data_id;

  if (
    !customer_device_data_id &&
    wifi_ssid &&
    wifi_password &&
    rssi_thresholds_id
  ) {
    console.log("Creating a new customer device data: ", {
      wifi_ssid,
      wifi_password,
      rssi_thresholds_id,
      nextCustomerId,
    });
    const newCustomerData = await postCustomersDeviceData({
      wifi_ssid,
      wifi_password,
      rssi_thresholds_id,
      customer_id: nextCustomerId,
    });
    if (customer_device_data_id)
      kaidu_configuration_id = newCustomerData.customer_device_data_id;
  }

  if (!configuration_id) {
    await postKaiduConfigListItem({
      kaidu_configuration_id,
      kaidu_device_id,
      mqtt_configuration_id: mqtt_configuration_id || NIL_UUID,
      customer_list_id: nextCustomerId,
      customer_device_data_id: customer_device_data_id || NIL_UUID,
    });
  }
  // fetch {id} from kaidu_configurations_list
  const { id, ...restKaiduConfigListItem } = await fetchKaiduConfigListItem(
    kaidu_configuration_id
  );
  // if customer_id changed, update /kaidu_configurations_list
  if (shouldUpdateCustomer) {
    await updateCustomer(combined, nextCustomerId, id);
  }

  // when should use PUT customers_device_data?

  // if this is a new combination for the customer, create a /customers_devices_data & update /kaidu_configurations_list
  // else, update /kaidu_configurations_list with the found customer_device_data_id
  const preconfigCombination = _.pick(combined, [
    "customer_id", // use new customer_id
    "customers_building_id",
    "customers_floor_id",
    "customers_location_id",
    "rssi_thresholds_id",
  ]);
  // console.log('picked combination:', preconfigCombination);

  const isNextCustomerDeviceDataExist =
    customers_building_id ||
    customers_floor_id ||
    customers_location_id ||
    rssi_thresholds_id ||
    wifi_ssid ||
    wifi_password;

  const customerDeviceDataList = await fetchCustomerDeviceDataList();

  const found = findCustomerDeviceDataItem(
    customerDeviceDataList,
    preconfigCombination
  );

  if (isNextCustomerDeviceDataExist && found) {
    // console.log("found customer device data", found);
    // should replace old customer device data id with the found one
    const {
      customer_device_data_id: foundCustomersDeviceId,
      wifi_ssid: prevWifiSSID,
      wifi_password: prevWifiPassword,
    } = found || {};

    const isWifiChanged =
      wifi_ssid !== prevWifiSSID || wifi_password !== prevWifiPassword;
    if (isWifiChanged) {
      // if only wifi_ssid or wifi_password changed, update /customers_device_data
      console.log("wifi changed, update /customers_device_data");

      const input = processSubmittedCustomerDeviceData(combined);
      await putCustomersDeviceData(input);
    } else {
      // if wifi is not changed, update /kaidu_configurations_list
      console.log("wifi not changed, update /kaidu_configurations_list");

      const processed = processKaiduConfigListSubmission({
        ...combined,
        customer_device_data_id: foundCustomersDeviceId,
        id,
      });

      await putKaiduConfigListItem(processed);
    }
  } else if (isNextCustomerDeviceDataExist && !found) {
    console.log(
      "New customer device data is submitted and it cannot be found in server"
    );

    // should post a new customer device data
    let nextCustomerDeviceDataId = NIL_UUID;
    if (wifi_ssid && wifi_password && rssi_thresholds_id) {
      const res3 = await postCustomersDeviceData(combined);
      if (res3) nextCustomerDeviceDataId = res3.customer_device_data_id;
    }

    console.log(`created customer device data ${nextCustomerDeviceDataId}`);
    const processed = processKaiduConfigListSubmission({
      ...combined,
      kaidu_configuration_id,
      customer_device_data_id: nextCustomerDeviceDataId,
      customer_list_id: nextCustomerId,
      id,
    });
    await putKaiduConfigListItem(processed);
  } else if (!isNextCustomerDeviceDataExist) {
    console.log("No customer device data is submitted");
    // should put kaidu configuration list item with null value for customer_device_data_id
    const nextKaiduConfig = {
      ...restKaiduConfigListItem,
      customer_device_data_id: null,
      id,
    };
    await putKaiduConfigListItem(nextKaiduConfig);
  }
  return;
}

export async function updateConfigurationWithKaiduDeviceConfigurationAPI(data) {
  const { mac_address, kaidu_device_status } = data || {};
  // console.log("updateConfigurationWithKaiduDeviceConfigurationAPI ", data);
  // return;
  const result = await putKaiduConfig(data);

  // fetch the kaidu device id at first
  const kaiduDeviceListItem = await fetchKaiduDevicesListItem(mac_address);
  if (
    kaiduDeviceListItem &&
    kaidu_device_status !== kaiduDeviceListItem?.kaidu_device_status
  ) {
    await updateKaiduDevicesList({
      ...kaiduDeviceListItem,
      kaidu_device_status,
    });
  }
  return result;
}
