import BootstrapBadge from "react-bootstrap/Badge";
import { useDispatch } from "react-redux";

import OperationsGroup from "components/molecule/OperationsGroup";
import { User } from "features/kaidu-config-server/users-list";
import {
  setModalData,
  setOpenModal,
} from "features/users/lib/redux/usersSlice";

export function UserItem({
  data,
  ...optionals
}: {
  data: User;
  [x: string]: any;
}) {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(
      //@ts-ignore
      setModalData({
        operationType: "delete",
        userId: data?.user_id,
        userEmail: data?.user_email,
      })
    );
    //@ts-ignore
    dispatch(setOpenModal(true));
  };

  if (!data) {
    return null;
  }

  const { user_email, user_name, isSuperUser, is_partner } = data || {};

  return (
    <tr className="flex items-center justify-between w-full p-2">
      <td className="flex-1 max-w-md">
        {user_email}
        {is_partner ? (
          <BootstrapBadge className="ml-3" bg="secondary">
            Partner
          </BootstrapBadge>
        ) : null}
      </td>
      <td className="flex-1">{user_name ?? ""}</td>
      <td className="flex space-x-2 flex-grow-0 flex-1">
        <OperationsGroup onDelete={handleDelete} showDelete={true} />
      </td>
    </tr>
  );
}
