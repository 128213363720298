//rendered data
export const displayedCols = [
  "customer_name",
  "device_name",

  "kaidu_device_status",
  "mac_address",

  "mqtt_device_id",
  "last_seen_timestamp",
  "hw_sw_version",
];

// headers for rendered data
export const colTexts = [
  "Customer",
  "Scanner",

  "Status",
  "MAC Address",

  "Device ID",
  "Last Seen Timestamp",
  "Version",
];

// needed columns for DevicesStatusPage
export const usefulCols = [
  "device_name",
  "mac_address",
  "hw_sw_version",
  "slave_firmware_version",
  "kaidu_device_status",
  "last_seen_timestamp",
  "mqtt_device_id",
  "customer_name",
  "building",
  "floor",
  "location",
];
