import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { Action, combineReducers, Reducer } from "redux";
import thunk from "redux-thunk";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { NODE_ENV } from "lib";

//self-defined dependencies
import deviceStatusSliceReducer, {
  deviceStatusSlice,
} from "./deviceStatusSlice";
import preconfigSliceReducer, {
  preconfigSlice,
} from "features/pre-configs/lib/redux/preconfigSlice";
import floorplanSliceReducer, {
  floorplanSlice,
} from "features/pre-configs/lib/redux/floorplanSlice";
import customersSliceReducer, {
  customersSlice,
} from "domain/customers/lib/redux/customersSlice";
import filterSliceReducer, { filterSlice } from "./filterSlice";
import usersSliceReducer, {
  usersSlice,
} from "features/users/lib/redux/usersSlice";
import globalStatusReducer, { globalStatusSlice } from "./globalSlice";
import scannersReducer, {
  scannersSlice,
} from "features/pre-configs/lib/redux/scannersSlice";
import bleReducer, { bleSlice } from "./bleSlice";
import storage from "redux-persist/lib/storage";

export const persistConfig = {
  key: "root",
  storage,
  whitelist: ["customer"],
};

export const customerPersistConfig = {
  key: "customer",
  storage,
  whitelist: ["selectedCustomer"],
  timeout: 800,
};

const baseReducers: Reducer = combineReducers({
  //all reducers should be put here
  [preconfigSlice.name]: preconfigSliceReducer,
  [deviceStatusSlice.name]: deviceStatusSliceReducer,
  [floorplanSlice.name]: floorplanSliceReducer,
  [globalStatusSlice.name]: globalStatusReducer,
  [usersSlice.name]: usersSliceReducer,
  [customersSlice.name]: persistReducer(
    customerPersistConfig,
    customersSliceReducer
  ),
  [filterSlice.name]: filterSliceReducer,
  [scannersSlice.name]: scannersReducer,
  [bleSlice.name]: bleReducer,
});

// const persistedReducer = persistReducer(persistConfig, baseReducers);

const makeStore = () =>
  configureStore({
    reducer: baseReducers,
    devTools: NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) => {
      const middleware = getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      });
      return [...middleware, thunk];
    },
  });

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

// export an assembled wrapper
const store = makeStore();
export default store;
