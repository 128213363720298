import useSWR, { SWRResponse } from "swr";
import { helpers } from "utils";
import { RSSI_THRESHOLDS_API } from "./api";
import { RSSIThreshold } from "./types";

const { swrFetcher } = helpers;

export function useRSSIThresholdList(
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const { data, error }: SWRResponse<RSSIThreshold[], Error> = useSWR(
    RSSI_THRESHOLDS_API,
    swrFetcher,
    options
  );
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
