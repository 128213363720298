import ListItem from '../../../components/atomic/ListItem';
import Typography from '../../../components/atomic/Typography';
import styled from 'styled-components';
import { useKaiduScannerData } from './useKaiduScanner';
import ErrorMsg from '../../../components/atomic/ErrorMsg';
import { NO_VALUE_PLACEHOLDER } from '../../../lib/constants';
import LoadingSpinner from '../../../components/molecule/LoadingSpinner';

const StyledListItem = styled(ListItem)`
  display: flex;
  flex-grow: 0;
  margin-top: 5px;
  height: 100%;
  > p {
    margin-bottom: 0.25rem;
    width: 400px;
    word-wrap: break-word;
  }
`;

const ValueText = styled(Typography)``;

const NameText = styled(Typography)`
  color: ${(props) => props.theme.palette.grayscale[2]};
  width: 20rem;
  // margin-right: 100px;
`;

const Container = styled.div`
  padding: 1.2rem;
  padding-top: 0.4rem;
  /* min-height: 30vh; */
  height: auto;
`;

// View for name and value pairs
function NameValuePair(props) {
  const { name, value } = props;
  return (
    <StyledListItem>
      <NameText>{name}</NameText>
      <ValueText>{value ?? NO_VALUE_PLACEHOLDER}</ValueText>
    </StyledListItem>
  );
}

// 
/**
 * Controller and View for reading readable data from connected Kaidu scanner
 */
function ConnectedReadable({ connectedGattServer }) {
  const { deviceInfo, isLoading, isError } = useKaiduScannerData(
    connectedGattServer
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (isError) {
    return <ErrorMsg text='Failed to read KaiduScanner' />;
  }

  //@ts-ignore
  const {
    statistics,
    wifi,
    mqttDeviceId,
    hw,
    sw,
    mqttCertificate,
  } = deviceInfo || {wifi: undefined, mqttDeviceId: undefined, mqttCertificate: undefined};

  return (
    <>
      <NameValuePair name={'Hardware version'} value={hw} />
      <NameValuePair name={'Software version'} value={sw} />
      <NameValuePair name={'MQTT Device ID'} value={mqttDeviceId} />
      <NameValuePair name={'MQTT Certificate'} value={mqttCertificate} />
      <NameValuePair name={'Beacon Status'} value={statistics?.beacon_status || statistics?.b} />
      <NameValuePair name={'Start Time'} value={statistics?.start_time} />
      <NameValuePair name={'End Time'} value={statistics?.end_time} />
      <NameValuePair
        name={'BLE Scan Counter'}
        value={statistics?.ble_scan_counter}
      />
      <NameValuePair
        name={'MQTT Upload Counter'}
        value={statistics?.mqtt_upload_counter}
      />
      <NameValuePair
        name={'Reboot Counter'}
        value={statistics?.reboot_counter}
      />
      <NameValuePair name={'Source ID'} value={statistics?.source_id} />
      <NameValuePair name={'Wi-Fi SSID'} value={wifi?.ssid} />
      <NameValuePair name={'Wi-Fi Password'} value={wifi?.password} />
    </>
  );
}

/**
 * UI info for connected BLE devices
 */
export default function ConnectedInfo({ connectionResult, ...rest }) {

  if (!connectionResult || !connectionResult.connectedBLEDevice) {
    return null;
  }
  if (connectionResult.isLoading) {
    return (
      <div className='flex flex-col justify-center items-center mt-3'>
        <Typography>Waiting for pairing and connecting...</Typography>
        <LoadingSpinner />
      </div>
    );
  }
  if (connectionResult.isError) {
    return <ErrorMsg text={connectionResult.isError.message} />;
  }

  const { id, name, gatt } = connectionResult.connectedBLEDevice;
  if (!name || !gatt) {
    return null;
  }

  return (
    <Container>
      <NameValuePair name={'Connected device'} value={String(name)} />
      <ConnectedReadable connectedGattServer={gatt} />
    </Container>
  );
}
