import {CustomersListItem, FloorplanData} from './types';
import { isFilledArray } from '../../../utils';
import {NULL_VALUE} from '../constants';
import {pipe, prop, path, pathOr, pluck, pick, includes} from 'ramda';
import { BasicCustomerListItem } from 'features/pre-configs/types';
import _ from 'lodash';

export function getCustomerNames(list) {
  if (!isFilledArray(list)) {
    return undefined;
  }
  return list.map((customer) => customer?.customer_name);
}

export function findByCustomerID(list: CustomersListItem[], id: string) {
  if (!isFilledArray(list)) {
    return undefined;
  }
  return list?.find(item => item.customer_id === id);
}

export function findByCustomerName(list: CustomersListItem[], name: string) {
  if (!isFilledArray(list)) {
    return undefined;
  }
  return list?.find(item => item.customer_name === name);
}

export function hasDefaultFloorplan(customer: CustomersListItem) {
  if (!customer) {
    return false;
  }

  const result = pipe(pathOr([{customers_floor_id: null}], ['customer_config', 'floorplans']), pluck('customers_floor_id'), includes(NULL_VALUE))(customer);
  // console.debug('hasDefaultFloorplan result', result);
  return result;
}

export function getDefaultFloorplan(floorplans: FloorplanData[]) {
  if (!isFilledArray(floorplans)) {
    return undefined;
  }
  return floorplans.find(item => item?.customers_floor_id === NULL_VALUE);
}

export function extractIdNameFromCustomerList(
  customersList: CustomersListItem[]
): BasicCustomerListItem[] {
  if (!isFilledArray(customersList)) {
    return [];
  }

  return customersList?.map((customer) => {
    if (!customer) {
      return null;
    }
    return pick(['id', 'customer_name', 'customer_id'], customer);
  });
}