import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  DEVICE_CONFIG_ALL_API,
  KaiduDeviceConfiguration,
  useKaiduConfigByMac,
  useKaiduConfigList,
} from "features/kaidu-config-server/kaidu-device-configuration";
import { selectedDeviceData } from "providers/redux/deviceStatusSlice";
import {
  CUSTOMERS_DEVICE_DATA_ITEM_API,
  useCustomerDeviceDataItem,
  useCustomersDeviceData,
} from "../../../customers-device-data";
import {
  useCustomersList,
  useCustomersListById,
} from "../../../customers-list";
import {
  KAIDU_CONFIG_LIST_API,
  KaiduConfigurationsListItem,
  findByCustomerId,
  useKaiduConfigOfDevice,
  useKaiduConfigurationsList,
} from "../../../kaidu-configurations-list";
import {
  KAIDU_DEVICES_LIST_API,
  KaiduDevicesListItem,
  useKaiduDevicesList,
  useSingleKaiduDevice,
} from "../../../kaidu-devices-list";

export const KAIDU_CONFIG_MUTATE_KEYS = [
  KAIDU_DEVICES_LIST_API,
  KAIDU_CONFIG_LIST_API,
  DEVICE_CONFIG_ALL_API,
  CUSTOMERS_DEVICE_DATA_ITEM_API,
];

export function useKaiduConfigItem(
  mac: string,
  options?: any
): { data; isLoading; isError } {
  const deviceData = useSelector(selectedDeviceData);
  const { kaidu_device_list } = deviceData || {};

  const {
    kaiduDevice,
    isLoading: isKaiduDevicesListLoading,
    isError: isKaiduDevicesListItemError,
  } = useSingleKaiduDevice(kaidu_device_list ? null : mac, options);

  const deviceListData = useMemo(
    () => kaidu_device_list || kaiduDevice,
    [kaidu_device_list, kaiduDevice]
  );

  const {
    configList,
    isLoading: isKaiduConfigListLoading,
    isError: isKaiduConfigListError,
  } = useKaiduConfigOfDevice(deviceListData?.kaidu_device_id, options);

  let foundKaiduDeviceItem: KaiduDevicesListItem | undefined,
    foundConfig: KaiduConfigurationsListItem | undefined;

  foundKaiduDeviceItem = deviceListData;

  const {
    data: kaiduDeviceConfig,
    isLoading: isDeviceConfigLoading,
    isError: isDeviceConfigError,
  } = useKaiduConfigByMac(foundKaiduDeviceItem?.mac_address);

  const {
    customer,
    isLoading: isCustomerLoading,
    isError: isCustomerError,
  } = useCustomersListById(kaiduDeviceConfig?.customer_id);

  foundConfig = findByCustomerId(configList, kaiduDeviceConfig?.customer_id);
  const customerDeviceDataId = foundConfig?.customer_device_data_id;

  const {
    customerDeviceData,
    isLoading: isCustomerDeviceDataLoading,
    isError: isCustomerDeviceDataError,
  } = useCustomerDeviceDataItem(customerDeviceDataId);

  let result = Object.assign({}, deviceListData);
  foundConfig && Object.assign(result, foundConfig);
  kaiduDeviceConfig && Object.assign(result, kaiduDeviceConfig);
  customerDeviceData && Object.assign(result, customerDeviceData);
  customer &&
    Object.assign(
      result,
      _.pick(customer, [
        "customer_name",
        "customer_id",
        "customer_config",
        "partner_customer_id",
      ])
    );

  return {
    data: result,
    isLoading:
      isKaiduDevicesListLoading ||
      isKaiduConfigListLoading ||
      isCustomerDeviceDataLoading ||
      isDeviceConfigLoading ||
      isCustomerLoading,
    isError:
      isKaiduDevicesListItemError ||
      isKaiduConfigListError ||
      isCustomerDeviceDataError ||
      isDeviceConfigError ||
      isCustomerError,
  };
}

export function useCombinedKaiduConfigList(options?: any) {
  const {
    configList,
    isLoading: isKaiduConfigListLoading,
    isError: isKaiduConfigListError,
  }: {
    configList: KaiduConfigurationsListItem[];
    isLoading: boolean;
    isError: Error | undefined;
  } = useKaiduConfigurationsList(true, options);

  isKaiduConfigListError &&
    console.error(`isKaiduConfigListError ${isKaiduConfigListError?.message}`);

  const {
    kaiduDevicesList,
    isLoading: isKaiduDevicesListLoading,
    isError: isKaiduDevicesListError,
  } = useKaiduDevicesList(options);
  isKaiduDevicesListError &&
    console.error(
      `isKaiduDevicesListError ${isKaiduDevicesListError?.message}`
    );

  const {
    customerDeviceData,
    isLoading: isCustomerDeviceDataLoading,
    isError: isCustomerDeviceDataError,
  } = useCustomersDeviceData();

  const {
    customersList,
    isLoading: isCustomerListLoading,
    isError: isCustomerListError,
  } = useCustomersList();

  const {
    allConfig: deviceConfigList,
    isLoading: isDeviceConfigListLoading,
    isError: isDeviceConfigListError,
  } = useKaiduConfigList();

  let foundKaiduDeviceItem: KaiduDevicesListItem | undefined,
    foundConfig: KaiduConfigurationsListItem | undefined,
    foundCustomerDeviceData: any | undefined,
    foundDeviceConfig: KaiduDeviceConfiguration | undefined,
    foundCustomer: any | undefined;

  const processedList =
    kaiduDevicesList?.map((device) => {
      foundConfig = configList?.find(
        (item) => item?.kaidu_device_id === device?.kaidu_device_id
      );
      foundKaiduDeviceItem = kaiduDevicesList?.find(
        (item) => item?.kaidu_device_id === device?.kaidu_device_id
      );
      foundCustomerDeviceData = customerDeviceData?.find(
        (item) =>
          item?.customer_device_data_id === foundConfig?.customer_device_data_id
      );
      foundCustomer = customersList?.find(
        (item) => item?.customer_id === foundConfig?.customer_list_id
      );

      foundDeviceConfig = deviceConfigList?.find(
        (item) =>
          item.kaidu_configuration_id === foundConfig?.kaidu_configuration_id
      );

      return {
        ...device,
        ...foundKaiduDeviceItem,
        ...foundDeviceConfig,
        ...foundCustomerDeviceData,
        ...foundCustomer,
      };
    }) || [];

  const filtered =
    processedList?.filter((item) => Boolean(item?.mqtt_device_id)) || [];
  const result = filtered;

  return {
    data: result,
    isLoading:
      isKaiduDevicesListLoading ||
      isKaiduConfigListLoading ||
      isCustomerDeviceDataLoading ||
      isDeviceConfigListLoading ||
      isCustomerListLoading,
    isError:
      isKaiduDevicesListError ||
      isKaiduConfigListError ||
      isCustomerDeviceDataError ||
      isDeviceConfigListError ||
      isCustomerListError,
  };
}
