import axios from "axios";
import { getErrorMessageInResponse } from "features/kaidu-config-server/processor";
// import { handleAxiosResultData } from '../processor';
import { KAIDU_DEVICE_CMD_URL } from "./api";

/**
 * send a command to run master firmware update on a scanner
 */
export async function sendUpdateCommand(
  mac: string,
  fileName: string,
  cmd_name
) {
  console.log(`deviceId: ${mac}, versionMsg: ${fileName}`);
  try {
    if (!mac) {
      throw new Error("Missing Kaidu Device ID");
    }

    // send reset command
    const resetReqBody = {
      // kaidu_device_id: kaiduDeviceId,
      mac: mac,
      cmd_para: "",
      cmd_name: "RS",
    };

    await axios.post(KAIDU_DEVICE_CMD_URL, resetReqBody);
    // send OTA command
    const otaReqBody = {
      // kaidu_device_id: kaiduDeviceId,
      mac: mac,
      cmd_para: fileName,
      cmd_name,
    };

    await axios.post(KAIDU_DEVICE_CMD_URL, otaReqBody);
  } catch (error) {
    console.error(error);
    const errMsgInResponse = getErrorMessageInResponse(error);
    throw new Error(errMsgInResponse);
  }
}

export async function removeCommandById(id: string) {
  try {
    if (!id) {
      throw new Error("Missing Command ID");
    }

    await axios.delete(`${KAIDU_DEVICE_CMD_URL}/${id}`);
  } catch (error) {
    const errMsgInResponse = getErrorMessageInResponse(error);
    throw new Error(errMsgInResponse);
  }
}
