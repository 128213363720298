/* eslint-disable no-control-regex */
import moment from "moment";
import { DeviceStatistics } from "../../../types/interfaces";
import { handle } from "../../../utils/helper";
import {
  KAIDU_DEVICE_CHARACTERISTICS_UUIDS,
  KAIDU_DEVICE_SERVICE_UUIDS,
} from "./constants";

// const encoder = new TextEncoder();
const decoder = new TextDecoder();

export async function subscribeOtaCharacteristic(
  discoveredDevice,
  listener: (error?: Error, characteristic?: any) => void
) {
  // const transactionId = "test" + Math.random() * 10000;
  return await discoveredDevice.monitorCharacteristicForService(
    KAIDU_DEVICE_SERVICE_UUIDS.OTA,
    KAIDU_DEVICE_CHARACTERISTICS_UUIDS.OTA_FIRMWARE,
    listener,
    "characteristicvaluechanged"
  );
}

export async function callAsyncFnWithErrorTolerance(fn, maxErrorDepth = 10) {
  let errorDepth = 0;
  let result, resultErr;
  while (errorDepth < maxErrorDepth) {
    [result, resultErr] = await handle(fn());
    // await sleep(200);
    if (result) {
      break;
    } else {
      console.error(resultErr.message);
      errorDepth++;
      resultErr = null;
    }
  }
  if (errorDepth >= 10) {
    throw new Error("Max error depth reached when writing firmware chunks");
  }
}

export async function startWiFiFirmwareUpdate(value: string, deviceId: string) {
  // const base64 = encodeBase64(value);
  // return await BleWriter.writeCharacteristicWithResponseForDevice(
  //   deviceId,
  //   KAIDU_DEVICE_SERVICE_UUIDS.CONFIGURATION,
  //   KAIDU_DEVICE_CHARACTERISTICS_UUIDS.OTA_FIRMWARE_WIFI,
  //   base64,
  // );
}

export async function readHwSwVersions(connectedGattServer) {
  const service = await connectedGattServer.getPrimaryService(
    KAIDU_DEVICE_SERVICE_UUIDS.OTA
  );
  const characteristic = await service.getCharacteristic(
    KAIDU_DEVICE_CHARACTERISTICS_UUIDS.HW_SW_VERSION
  );
  const value = await characteristic.readValue();
  // console.log(`HW_SW_VERSION is ${helpers.inspect(value.getUint8(0))}`);

  const hardwareVersion = "v" + value.getUint8(0) + "." + value.getUint8(1);
  const softwareVersion =
    "v" + value.getUint8(2) + "." + value.getUint8(3) + "." + value.getUint8(4);
  console.log(
    `Hardware version: ${hardwareVersion}; Software version: ${softwareVersion}`
  );
  return { hw: hardwareVersion, sw: softwareVersion };
}

export function removeTraillingStringInJSON(str: string): string {
  const left = (str.match(/{/g) || []).length;
  const right = (str.match(/}/g) || []).length;
  if (right > left) {
    let start = 0;
    for (let i = 0; i < left; i++) {
      start = str.indexOf("}", start);
    }
    return str.slice(0, start + 1);
  }
  return str;
}

function convertTimeStampValueToReadable(numString: string): string {
  const num = parseInt(numString);
  const m = moment.unix(num);
  return m.toString();
}

/**
 * @param  {object} obj must have all the properties in oldPropertyNames
 */
export function convertTimeStampPropertiesToReadableString(
  obj: object,
  oldPropertyNames: Array<string>,
  newPropertyNames: Array<string>
) {
  console.log(`convertTimeStampPropertiesToReadableString is called`);
  // if obj doesn't have the properties, throw errors
  if (oldPropertyNames.some((prop) => obj[prop] === undefined)) {
    throw new Error("The object does not have the asked properties");
  }

  const output = Object.assign({}, obj);

  for (let i = 0; i < oldPropertyNames.length; i++) {
    console.log(`TimeStamp: ${output[oldPropertyNames[i]]}`);
    output[newPropertyNames[i]] = convertTimeStampValueToReadable(
      output[oldPropertyNames[i]]
    );
  }
  oldPropertyNames.forEach((oldProperty) => delete output[oldProperty]);

  return output;
}

export function removeNULLvalues(str: string): string {
  return str.replace(/\u0000/g, "");
}

/**
 * parse raw string retrieved from statistics characteristic to json object
 */
export function processDeviceStatisticReading(
  str: string
): DeviceStatistics | null {
  console.log(`processDeviceStatisticReading is called. input: ${str}`);
  let json;
  try {
    json = JSON.parse(
      removeTraillingStringInJSON(
        str
          .replace(/\u0000d\":\"\"}/g, "")
          .replace(/\u0000/g, "")
          .normalize()
      )
    );
  } catch (error) {
    console.error(`processDeviceStatisticReading error: input is ${str}`);
    throw new Error(`Device Statistic Reading error. read value: ${str}`);
  }

  // Convert each timestamp property to a readable date string;

  const rawStartTime = json?.start_timestamp || json?.s;
  const rawEndTime = json?.end_timestamp || json?.t;
  const start_time = convertTimeStampValueToReadable(rawStartTime);
  const end_time = convertTimeStampValueToReadable(rawEndTime);

  // const oldTimestampProperties = ['start_timestamp', 'end_timestamp'];
  // const newTimestampProperties = ['start_time', 'end_time'];
  // const result = convertTimeStampPropertiesToReadableString(
  //   json,
  //   oldTimestampProperties,
  //   newTimestampProperties
  // );

  // console.log(`processDeviceStatisticReading finished.`, result);
  return { ...json, start_time, end_time } as DeviceStatistics;
}

/**
 * @description return all useful data from the configuration service on Kaidu scanners
 * @param  {} connectedGattServer
 * @return DeviceStatistics,
 */
export async function readAllConfigurationServiceData(connectedGattServer) {
  const configService = await connectedGattServer.getPrimaryService(
    KAIDU_DEVICE_SERVICE_UUIDS.CONFIGURATION
  );

  // read statistics
  const characteristic = await configService.getCharacteristic(
    KAIDU_DEVICE_CHARACTERISTICS_UUIDS.DEVICE_STATISTICS
  );
  const value = await characteristic.readValue();
  const rawStatisticString = decoder.decode(value);
  // console.log(`statistics value: ${rawStatisticString}`);
  const processed = processDeviceStatisticReading(rawStatisticString);
  // console.log(`processed value: ${helpers.inspect(processed)}`);

  //read Wi-Fi
  const ssid = await fetchWiFiSSID(configService);
  const password = await fetchWiFiPassword(configService);
  const mqttDeviceId = await fetchMqttDeviceId(configService);
  const mqttCertificate = await fetchMqttCertificate(configService);

  return {
    statistics: processed,
    wifi: { ssid, password },
    mqttDeviceId,
    mqttCertificate,
  };
}

/**
 * @description read value as string via BLE with null characters removed
 * @param  {} serviceObj
 * @param  {} characteristicId
 */
export async function fetchStringValue(serviceObj, characteristicId) {
  const characteristic = await serviceObj.getCharacteristic(characteristicId);
  const value = await characteristic.readValue();
  const result = removeNULLvalues(decoder.decode(value));
  // console.log(`string value: ${result}`);
  return result;
}

/**
 * @description read wifi ssid from kaidu ble device
 * @param  configService
 */
export async function fetchWiFiSSID(configService): Promise<string> {
  const result = await fetchStringValue(
    configService,
    KAIDU_DEVICE_CHARACTERISTICS_UUIDS.WIFI_SSID
  );
  // console.log(`readWiFiSSID value: ${result}`);
  return result;
}

export async function fetchWiFiPassword(configService): Promise<string> {
  const result = await fetchStringValue(
    configService,
    KAIDU_DEVICE_CHARACTERISTICS_UUIDS.WIFI_PASSWORD
  );
  console.log(`readWiFiPassword value: ${result}`);
  return result;
}

export async function fetchMqttDeviceId(configService): Promise<string> {
  const result = await fetchStringValue(
    configService,
    KAIDU_DEVICE_CHARACTERISTICS_UUIDS.MQTT_DEVICE_ID
  );
  return result;
}

export async function fetchMqttCertificate(configService): Promise<string> {
  const result = await fetchStringValue(
    configService,
    KAIDU_DEVICE_CHARACTERISTICS_UUIDS.MQTT_CERTIFICATE
  );
  return result;
}
