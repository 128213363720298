import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import {
  CustomerConfig,
  CustomersListItem,
  UpdateCustomersListItem,
} from "features/kaidu-config-server";
import { helpers, trimObjectProperties } from "utils";
import { FormCustomerListItem } from "../types";

const { replaceEmptyStringWithNull } = helpers;

/**
 * remove extra un
 */
export function processSubmittedCustomer(data) {
  const trimmed = trimObjectProperties(data);
  const replaced = replaceEmptyStringWithNull(trimmed);
  const result: CustomersListItem = _.pick(replaced, [
    "id",
    "customer_id",
    "customer_name",
    "customer_config",
    "customer_custom_data",
    "isPartner",
    "partner_customer_id",
  ]);

  return result;
}

export function addIdsToCustomer(data) {
  const customer_id = uuidv4();
  return { ...data, customer_id };
}

/**
 * convert form data
 * @returns
 */
export function createCustomerListItemFromForm(
  data: FormCustomerListItem
): UpdateCustomersListItem {
  const {
    start,
    end,
    customer_name,
    customer_id,
    id,
    realtime_flag,
    timezone,
    isPartner,
    partner_customer_id,
    ...rest
  } = data;
  // const hoursOfOperation = start && end && { start, end };

  const customer_config: Partial<CustomerConfig> = {
    hoursOfOperation: {
      start,
      end,
      timezone,
    },
    flags: {
      realtime_flag,
    },
    ...rest,
  };

  const result = {
    customer_name,
    customer_id,
    customer_config,
    isPartner,
    partner_customer_id,
  };
  return result;
}
