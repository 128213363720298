import { createSlice } from "@reduxjs/toolkit";
import type { AppState } from "./store";

const initialState = {
  identifier: "",
  status: "idle",
};

export const bleSlice = createSlice({
  name: "BLE",
  initialState,
  reducers: {
    updateDeviceStatusIdentifier(state, action) {
      state.identifier = action.payload;
    },
    updateStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export default bleSlice.reducer;

export const { updateDeviceStatusIdentifier, updateStatus } = bleSlice.actions;

export const selectDeviceStatusIdentifier = (state: AppState) =>
  state[bleSlice.name].identifier;
export const selectBLEStatus = (state: AppState) => state[bleSlice.name].status;
