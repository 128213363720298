import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import styled from 'styled-components';
import Spinner from './Spinner';
import Icon from './Icon';

const StyledAlert = styled(Alert)`
  display: flex;
  border-radius: 150px;
  padding: 8px;
  margin-top: 6px;
`;

const IconContainer = styled.div`
  margin-right: 0.6rem;
`;

export default function MyAlert({
  isLoading = false,
  dismissible = true,
  variant = 'primary',
  defaultShow = true,
  ...optionals
}) {
  const { heading, children, ...rest } = optionals;
  const [show, setShow] = useState(defaultShow);

  return (
    <>
      <StyledAlert
        variant={isLoading ? 'primary' : variant}
        // dismissible={dismissible}  // Makes it clickable to close
        onClose={() => setShow(false)}
        show={show}
        {...rest}
      >
        {heading && <Alert.Heading>{heading}</Alert.Heading>}
        <IconContainer>
          {isLoading ? (
            <Spinner size='sm' />
          ) : (
            <>
              {variant === 'success' && <Icon name='check' />}
              {variant === 'danger' && <Icon name='times' />}
              {variant === 'warning' && <Icon name='exclamation' />}
            </>
          )}
        </IconContainer>
        {children}
      </StyledAlert>
    </>
  );
}
