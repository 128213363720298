import { Toast } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/types';

export default function MyToast({
  variant = 'primary' as Variant,
  title = '',
  text = '',
  autohide = true,
  delay = 3000,
  onClose = null,
  show = false,
  ...rest
}) {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Toast
      className={`d-inline-block m-1 z-50 fixed right-2 bottom-2`}
      bg={variant.toLowerCase()}
      {...{ autohide, delay }}
      show={show}
      onClose={handleClose}
      {...rest}
    >
      <Toast.Header>
        <strong className='me-auto'>{title}</strong>
      </Toast.Header>
      <Toast.Body>{text}</Toast.Body>
    </Toast>
  );
}
