import { Link } from "react-router-dom";
import styled from "styled-components";

import Icon from "../../atomic/Icon";

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const NavItem = styled.div<{ isSelected: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 3px solid transparent;

  padding-left: 0.8rem;
  height: 2.5rem;
  @media ${({ theme }) => theme.device.tablet} {
    padding-left: 50px;
    height: 65px;
  }
  color: rgb(108, 117, 125);

  ${(props) =>
    props.isSelected &&
    `
    border-left: 3px solid #fff;
    color: #fff;
    background: #6C757D;
  `}

  .name {
    margin: 0px;
    margin-left: 15px;
  }

  &:hover {
    background: #6c757d;
    color: #fff;
  }
`;

function NavItemComposition(props) {
  const { icon, text, children, isSelected = false, ...rest } = props;
  return (
    <NavItem isSelected={isSelected} {...rest}>
      {icon ? <Icon name={icon} /> : null}
      {text ? <p className="name">{text}</p> : null}
      {children}
    </NavItem>
  );
}

export default function NavTab({ to = "/", isLink = true, ...rest }) {
  return (
    <div className="w-full">
      {isLink ? (
        <StyledLink to={to}>
          <NavItemComposition {...rest} />
        </StyledLink>
      ) : (
        <NavItemComposition {...rest} />
      )}
    </div>
  );
}
