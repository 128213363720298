import useSWR from "swr";
import { helpers } from "utils";
import {
  CUSTOMERS_DEVICE_DATA_ITEM_API,
  CUSTOMERS_DEVICE_DATA_LIST_API,
  CUSTOMERS_DEVICE_DATA_OF_CUSTOMER_API,
} from "./api";
// import { CustomerDeviceData } from './types';
// import { NULL_VALUE } from '../constants';
import { checkIsValidID } from "../processor";
import { fetchCustomerDeviceDataItem } from "./fetch";

const { swrFetcher } = helpers;

// All CUSTOMER_DEVICE_DATA
export function useCustomersDeviceData(
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const { data, error } = useSWR(
    CUSTOMERS_DEVICE_DATA_LIST_API,
    () => swrFetcher(CUSTOMERS_DEVICE_DATA_LIST_API),
    options
  );
  return {
    customerDeviceData: data,
    isLoading: !error && !data,
    isError: error,
    key: CUSTOMERS_DEVICE_DATA_LIST_API,
  };
}

// for one customer
export function useSingleCustomerDeviceData(
  customerName: string | null,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const url = customerName
    ? `${CUSTOMERS_DEVICE_DATA_OF_CUSTOMER_API}/${encodeURIComponent(
        customerName
      )}`
    : null;
  // console.log(`useSingleCustomerDeviceData url: ${url}`);
  const { data, error } = useSWR(url, () => swrFetcher(url), options);

  return {
    devicesOfCustomer: data,
    isLoading: customerName && !error && !data,
    isError: error,
  };
}

export function useCustomerDeviceDataItem(
  id: string,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const url = `${CUSTOMERS_DEVICE_DATA_ITEM_API}/${id}`;
  // console.log(`useCustomerDeviceDataItem url: ${url}`);
  const { data, error } = useSWR(
    checkIsValidID(id) ? url : null,
    () => fetchCustomerDeviceDataItem(id),
    options
  );

  return {
    customerDeviceData: data,
    isLoading: checkIsValidID(id) && !error && !data,
    isError: error,
  };
}
