import _ from "lodash";
import { useEffect, useState } from "react";
import { Variant } from "react-bootstrap/types";

import Table from "components/atomic/Table";
import Toast from "components/atomic/Toast";
import { SortIcon } from "components/molecule/SortIcon";
import update from "immutability-helper";
import CheckboxIcon from "./CheckboxIcon";
import DevicesStateOperations from "./DeviceStateOperations";
import { HeaderBtnGroup } from "./HeaderBtnGroup";
import { StatusRow } from "./StatusRow";

const Th = ({ children, sortable = true, ...rest }) => {
  const { onClick = () => {} } = rest;
  return (
    <th className="whitespace-nowrap">
      {children} {sortable ? <SortIcon onClick={onClick} /> : null}
    </th>
  );
};

const operationIdentifier = "mqtt_device_id";
const tableKey = "mac_address";

function getAllSelectorStatus(rows: any[]) {
  if (rows.every((row) => row.checked)) {
    return true;
  } else if (rows.some((row) => row.checked)) {
    //TODO:
  } else {
    return false;
  }
}

/**
 *
 */
export function StatusTable({ rows, cols, colTexts, ...rest }) {
  const [sortedRows, setSortedRows] = useState(rows);
  const [isHeaderBtnsShowed, setHeaderBtnsShowed] = useState(false);
  const [toastStatus, setToastStatus] = useState({
    show: false,
    title: "Success",
    text: "All update commands are sent to the server",
    variant: "primary" as Variant,
  });

  useEffect(() => {
    setSortedRows(rows);
  }, [rows]);

  const handleSort = (colName, isAscend) => {
    const order = isAscend ? "asc" : "desc";
    const sorted = _.orderBy(sortedRows, [colName], [order]);
    setSortedRows(sorted);
  };

  const handleAllselectCheckbox = (isChecked) => {
    if (isChecked) {
      // select all selectables
      const allT = sortedRows.map((row) => {
        return { ...row, checked: true };
      });
      setSortedRows(allT);
      setHeaderBtnsShowed(true);
    } else {
      //unselect all selectables
      const allF = sortedRows.map((row) => {
        return { ...row, checked: false };
      });
      setSortedRows(allF);
      setHeaderBtnsShowed(false);
    }
  };
  const handleSingleSelectCheckbox = (
    isChecked: boolean,
    identifier: string
  ) => {
    const foundIndex = sortedRows.findIndex(
      (row) => row.mqtt_device_id === identifier
    );
    if (isChecked) {
      setHeaderBtnsShowed(true);
      // set this row to checked
      // foundRow.checked = true;
      const foundRow = Object.assign({}, sortedRows[foundIndex], {
        checked: true,
      });
      const newSortedRows = update(sortedRows, {
        [foundIndex]: { $set: foundRow },
      });
      setSortedRows(newSortedRows);
    } else {
      // setHeaderBtnsShowed(false);
      // foundIndex.checked = false;
      const foundRow = Object.assign({}, sortedRows[foundIndex], {
        checked: false,
      });
      const newSortedRows = update(sortedRows, {
        [foundIndex]: { $set: foundRow },
      });
      if (!newSortedRows.find((row) => row.checked)) {
        setHeaderBtnsShowed(false);
      }
      setSortedRows(newSortedRows);
    }
  };

  const handleUpdateSelected = (data) => {
    // send update commands to selected devices
  };

  return (
    <div className="relative">
      {isHeaderBtnsShowed ? (
        <HeaderBtnGroup onUpdateSelected={handleUpdateSelected} />
      ) : null}
      <Table {...rest}>
        <thead>
          <tr>
            <Th sortable={false}>
              <CheckboxIcon
                onChange={handleAllselectCheckbox}
                checked={getAllSelectorStatus(sortedRows)}
              />
            </Th>
            <Th sortable={false}>#</Th>
            {cols.map((col, index) => (
              <Th onClick={(isAscend) => handleSort(col, isAscend)} key={col}>
                {colTexts[index]}
              </Th>
            ))}
            <Th sortable={false}>Operations</Th>
          </tr>
        </thead>
        <tbody>
          {sortedRows.map((row, index) => {
            //Wrap operation components with identifiers
            const withIdentifier =
              (WrappedComponent) =>
              ({ ...props }) =>
                (
                  <WrappedComponent
                    {...props}
                    identifier={row[operationIdentifier]}
                  />
                );
            const WrappedOperationComponents = withIdentifier(
              DevicesStateOperations
            );

            return (
              <StatusRow
                index={index}
                row={row}
                cols={cols}
                key={`${row[tableKey]}-${index}`}
                rowKey={row[tableKey]}
                operationComponents={
                  <WrappedOperationComponents
                    mac={row.mac_address}
                    data={row}
                  />
                }
                identifier={row[operationIdentifier]}
                onSingleSelect={handleSingleSelectCheckbox}
              />
            );
          })}
        </tbody>
      </Table>
      <Toast
        {...toastStatus}
        onClose={() =>
          setToastStatus(Object.assign({}, toastStatus, { show: false }))
        }
      />
    </div>
  );
}

export default StatusTable;
