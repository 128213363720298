import {inspect, handle} from 'utils/helper';
import {AxiosResponse} from 'axios';

export function getErrorMessageInResponse(resError): string {
  if (!resError) {
    return 'Failed to get the error object';
  }

  const response = resError?.response;
  const resData = response?.data;
  if (resData) {
    const msg1 = resData?.status && resData?.error && `Status: ${resData?.status}; Error: ${resData?.error}`;
    const msg2 = resData?.statusCode && resData?.message && `Status: ${resData?.statusCode}; Error: ${resData?.message}`;
    return msg1 || msg2;
  }

  if (resError?.message) {
    return resError.message;
  }

  return inspect(resError);
}

export function handleAxiosResultData<T>(axiosResponse: AxiosResponse<T>): T {
  if (!axiosResponse.data) {
    throw new Error(`Got response from server but no data in the response`);
  }
  return axiosResponse.data;
}


export async function callWithResponseErrorHandling(callback) {
  const [result, resultErr] = await handle(callback());

  if (resultErr) {
    console.error(resultErr);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return result;
}