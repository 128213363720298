import { BASE_API } from '../constants';
import { sendDelete, sendPost, sendPut } from '../handler';
import { NewUser, User, NewCustomerCodeRequestData } from './types';

const USERS_LIST_SUBPATH = '/users_list';
export const USERS_LIST_API = `${BASE_API}${USERS_LIST_SUBPATH}`;
export const USERS_LIST_ALL_API = `${USERS_LIST_API}/all`;
export const USERS_LIST_CODE = `${USERS_LIST_API}/code`; // create new customer with a given code

export async function postUser(data: NewUser) {
  const res = await sendPost(USERS_LIST_API, data);
  return res;
}

export async function putUser(data: User) {
  const res = await sendPut(USERS_LIST_API, data);
  return res;
}

export async function deleteUser(id: string) {
  const url = `${USERS_LIST_API}/${id}`;
  const res = await sendDelete(url);
  return res;
}

export async function postCode(data: NewCustomerCodeRequestData) {
  const res = await sendPost(USERS_LIST_CODE, data);
  return res;
}
