import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useSWRConfig } from "swr";

import Button from "components/atomic/Button";
import { Input } from "domain/customers/components/atomic/ClientInformationModalStyles";
import { getData } from "features/axios";
import {
  KAIDU_CONFIG_LIST_DEVICE_API,
  KAIDU_CONFIG_MUTATE_KEYS,
  KAIDU_DEVICES_LIST_API,
  KaiduConfigurationsListItem,
  putKaiduConfigListItem,
  putKaiduDevicesList,
} from "features/kaidu-config-server";
import { SERVER_API } from "lib";
import { KaiduDevicesListItem } from "lib/kaidu-server/kaidu-devices-list/types";
import { notifyError } from "lib/services";
import {
  DEVICE_STATUS_MODAL,
  selectModal,
  selectedModal,
  setDeviceData,
  updateDeviceMacAddress,
} from "providers/redux/deviceStatusSlice";
import { selectCurrentUser } from "providers/redux/globalSlice";

/**
 *
 */
interface AddScannerFormInput {
  mac_address: string;
}

export function DeviceCreateModal(props) {
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();

  const modal = useSelector(selectedModal);
  const currentUser = useSelector(selectCurrentUser);

  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const { partner_customer_id } = useMemo(
    () => currentUser || {},
    [currentUser]
  );

  const handleCloseModal = useCallback(() => {
    dispatch(selectModal(null));
  }, [dispatch]);

  const { register, reset, handleSubmit } = useForm({
    defaultValues: {
      mac_address: "",
    },
  });

  const onSubmit: SubmitHandler<AddScannerFormInput> = async (data) => {
    try {
      setSubmitting(true);
      const { mac_address } = data;
      if (!partner_customer_id) {
        throw new Error("Partner customer id missing!");
      }

      const mac_address_pattern = new RegExp(
        "^[a-fA-F0-9]{2}(:[a-fA-F0-9]{2}){5}$"
      );

      if (!mac_address) {
        throw new Error("MAC address missing!");
      }
      const mac = mac_address.trim().toUpperCase();
      if (!mac_address_pattern.test(mac)) {
        throw new Error("Invalid mac address");
      }
      const device: KaiduDevicesListItem = await getData(
        `${KAIDU_DEVICES_LIST_API}/${encodeURIComponent(mac)}`
      );

      if (!device) throw new Error(`No device with mac address - ${mac}`);
      const {
        kaidu_device_id,
        partner_customer_id: device_partner_customer_id,
      } = device;
      if (!kaidu_device_id) throw new Error("Device id missing");
      if (device_partner_customer_id !== partner_customer_id) {
        await putKaiduDevicesList({
          ...device,
          partner_customer_id,
        });
        const configurations: KaiduConfigurationsListItem[] = await getData(
          `${KAIDU_CONFIG_LIST_DEVICE_API}/${kaidu_device_id}`
        );
        const [configuration] = configurations || [];
        if (!configuration) throw new Error(`Kaidu configuration not found`);
        configuration.customer_list_id = partner_customer_id;
        await putKaiduConfigListItem(configuration);

        KAIDU_CONFIG_MUTATE_KEYS.forEach((key) => {
          mutate(key);
        });
      }

      const deviceConfiguration = await getData(
        `${SERVER_API.CONFIGURATION}/${encodeURIComponent(mac)}`
      );

      setSubmitting(false);
      reset();

      if (deviceConfiguration) {
        dispatch(updateDeviceMacAddress(mac));
        dispatch(setDeviceData(deviceConfiguration));
        dispatch(selectModal(DEVICE_STATUS_MODAL.EDIT));
      }
    } catch (error) {
      notifyError(error.message);
      setSubmitting(false);
    }
  };

  return (
    <Modal
      show={modal === DEVICE_STATUS_MODAL.ADD_DEVICE}
      onHide={handleCloseModal}
      centered
      // size="xl"
      // {...rest}
    >
      <Modal.Body>
        <div
          className={
            "p-4 max-w-full justify-center text-center flex flex-col gap-4 items-center"
          }
        >
          <Typography variant="h5" align="center">
            Enter the Scanner MAC #
          </Typography>
          <div className="text-center">
            <Input
              className="!max-w-60"
              name="mac_address"
              required
              autoFocus
              maxLength={17}
              {...register("mac_address")}
            />
          </div>
          <div className="flex justify-center gap-4">
            <Button disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
              <FontAwesomeIcon className="mr-1" icon="save" />
              {isSubmitting ? "Saving" : "Confirm"}
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
