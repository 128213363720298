import { useEffect, useState, useMemo } from 'react';
import Typography from 'components/atomic/Typography';
import { useFirmwareFile } from 'features/firmware';
import { getPercentage } from 'lib/data-operations';
import { useFirmwareChunks } from './useKaiduScanner';
import { getFileChunks } from './processor';
import { FirmwareUpdateProgressbar } from '../../../domain/firmware-update/components/FirmwareUpdateProgressbar';

/**
 * Perform firmware update
 * Props: target device, target firmware
 * Feedback: progress, result
 */
export function FirmwareUpdateController({
  bleStatus,
  connectedBLEDevice,
  startUpdate,
  onUpdateResult,
  ...optionals
}) {
  const { selectedVersion, ...rest } = optionals;

  // Hooks
  const { data: firmwareFile } = useFirmwareFile(selectedVersion);
  const { characteristic } = useFirmwareChunks(
    firmwareFile,
    connectedBLEDevice
  );

  // Local states
  const [writtenChunks, setWrittenChunks] = useState(0);
  const firmwareChunks = useMemo(() => {
    const { chunks } = getFileChunks(firmwareFile) || {};
    return chunks;
  }, [firmwareFile]);

  const isFirmwareUpdateFinished = useMemo(() => {
    const { totalChunks } = getFileChunks(firmwareFile) || {};
    return totalChunks && writtenChunks === totalChunks;
  }, [firmwareFile, writtenChunks])

  const updateProgress = useMemo(
    () => {
      const { totalChunks } = getFileChunks(firmwareFile) || {};
      return getPercentage(totalChunks, writtenChunks);
    },
    [firmwareFile, writtenChunks]
  );

  const isUnfinished = !isFirmwareUpdateFinished &&
    firmwareChunks &&
    characteristic && startUpdate;
  /**
   * send chunks until writtenChunks is not less than total chunks
   */
  useEffect(() => {
    if (startUpdate && writtenChunks === 0) {
      const startMsg = `OTA starts at ${new Date().toLocaleTimeString()}`;
      console.log(startMsg);
    }

    if (isFirmwareUpdateFinished) {
      console.log(`Written Chunks: ${writtenChunks}`);
      const endMsg = `OTA ends at ${new Date().toLocaleTimeString()}`;
      console.log(endMsg);
      onUpdateResult && onUpdateResult({ isUpdateStopped: true });
    }

    if (
      isUnfinished
    ) {
      const foo = async () => {
        // perform writing
        await characteristic.writeValueWithResponse(
          firmwareChunks[writtenChunks]
        );
        console.debug(`Written chunk ${writtenChunks + 1} of ${firmwareChunks.length}`);
        setWrittenChunks(writtenChunks + 1);
      };

      foo().catch((err) => {
        console.error(err?.message);
        onUpdateResult({ isUpdateStopped: true, isUpdateError: err });
      });
    }
  }, [characteristic, firmwareChunks, onUpdateResult, writtenChunks,]);

  if (!startUpdate) {
    return null;
  }

  return (
    <div className='flex-grow'>
      <Typography className='mb-1'>Update progress:</Typography>
      <FirmwareUpdateProgressbar currentPercent={updateProgress} />
    </div>
  );
}
