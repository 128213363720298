import { Card, CardContent } from '@mui/material';
import { useMediaQueries } from 'styles/breakpoints';
// import Icon from 'components/atomic/Icon';

export const CountCardContainer = ({ number, label, ...rest }) => {
  // const isIncreased = previousNumber !== null && number > previousNumber;
  // const isDecreased = previousNumber !== null && number < previousNumber;
  const isLg = useMediaQueries('lg');
  // const isXl = useMediaQueries('xl');
  // const is2Xl = useMediaQueries('2xl');

  return (
    <Card
      sx={{
        width: isLg ? '180px' : '200px',
        height: '100px',
        borderRadius: '25px',
        boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#fff',
      }}
      data-tip={rest['data-tip']}
    >
      <CardContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              className='text-sm font-light text-center'
              style={{ flex: '1', whiteSpace: 'nowrap', textAlign: 'center' }}
            >
              {label}
            </div>
            <div style={{ marginLeft: '10px' }}>
              {/* {isIncreased && <Icon name='FaArrowUp' />}
              {isDecreased && <Icon name='FaArrowDown' />} */}
            </div>
          </div>
          <div
            className='font-bold text-center'
            style={{ fontSize: 'xx-large' }}
          >
            {Math.floor(number)}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default CountCardContainer;
