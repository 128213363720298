import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useSWRConfig } from "swr";

import Button from "components/atomic/Button";
import BaseModal from "components/molecule/BaseModal";
import { InfoCard } from "components/molecule/InfoCard";
import {
  clearModalData,
  setOpenModal,
} from "domain/customers/lib/redux/customersSlice";
import { fetchUsersList } from "features/kaidu-config-server";
import { CUSTOMERS_LIST_API } from "features/kaidu-config-server/customers-list";
import { useAsync } from "hooks";
import { selectCurrentUser, setCurrentuser } from "providers/redux/globalSlice";
import { AsyncLifecycle } from "utils";
import { deleteCustomer } from "../../api";

export function DeleteCustomerModal({ name, id, ...optionals }) {
  // Hooks
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const currentUser = useSelector(selectCurrentUser);
  const { execute, status, value, error } = useAsync(
    async () => await deleteCustomer(id),
    false
  );

  const handleFulfilled = (value) => {
    console.log("modal request fulfilled");
    const name = value && value?.data && value?.data?.customer_name;

    dispatch(clearModalData());
    dispatch(setOpenModal(false));
    //show toast
    toast.success(`Client ${name ?? ""} is deleted`);

    mutate(CUSTOMERS_LIST_API);
    if (currentUser?.customers_list_id === id) {
      fetchUsersList().then((user) => dispatch(setCurrentuser(user)));
    }
  };

  const handleRejected = (error) => {
    //cleanup
    dispatch(clearModalData());
    dispatch(setOpenModal(false));
    //show toast
    toast.error(`${error?.message}` ?? `Failed to delete client `);
    mutate(CUSTOMERS_LIST_API);
  };

  useEffect(() => {
    status === AsyncLifecycle.FULFILLED && handleFulfilled(value);
    status === AsyncLifecycle.REJECTED && handleRejected(error);
  }, [status]);

  return (
    <BaseModal
      show={true}
      onHide={() => {
        dispatch(setOpenModal(false));
      }}
      title={`Delete Client: ${name}`}
      size="xl"
    >
      <div {...optionals}>
        <p>
          Are you sure you want to delete “{name}”? You cannot undo this action.
        </p>
        <InfoCard
          text={
            'Once you delete a client, all of the information and data of this client will be lost. If you would still want to delete this client, click the "Delete" button'
          }
          subHeadingText={"Warn"}
          icon={"exclamation-triangle"}
        />
      </div>
      <div className="flex justify-end mt-4">
        <Button onClick={execute} variant="danger">
          Delete
        </Button>
      </div>
    </BaseModal>
  );
}
