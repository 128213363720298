// import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EditModal as EditDeviceModal } from "application/pages/ConfigurationPage/EditModal";
import {
  DEVICE_STATUS_MODAL,
  selectDeviceMacAddress,
  selectModal,
  selectedModal,
  setDeviceData,
  setNewCustomerId,
} from "providers/redux/deviceStatusSlice";

/**
 *
 */
export function DeviceEditModal(props) {
  const dispatch = useDispatch();
  const mac_address = useSelector(selectDeviceMacAddress);
  const modal = useSelector(selectedModal);

  const handleCloseEditModal = useCallback(() => {
    dispatch(selectModal(null));
    dispatch(setNewCustomerId(null));
    dispatch(setDeviceData(null));
  }, [dispatch]);

  return (
    <EditDeviceModal
      show={modal === DEVICE_STATUS_MODAL.EDIT}
      mac={mac_address}
      onHide={handleCloseEditModal}
      title="Edit Configuration"
    />
  );
}
