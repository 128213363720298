import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import Image from "components/atomic/Image";
import { LogoutDialog } from "components/organism/LogoutDialog";
import {
  selectCurrentUser,
  selectLoginData,
  selectPartnerCustomer,
} from "providers/redux/globalSlice";
import { isFilledArray } from "utils";
import KaiduLogoImg from "../../../assets/kaidu_logo.jpg";
import NavTab, { StyledLink } from "./NavTab";
import { UserProfile } from "./Profile";
import { SessionSwitcher } from "./SessionSwitcher";

const SideNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 100%;
  min-width: 17vw;
  font-size: 20px;
`;

interface LargeNavProps {
  title: string;
  pages: Array<{ route: string, icon: string, text: string }>;
}

/**
 *
 */
export function LargeNav({ title, pages, ...optionals }: LargeNavProps) {
  const userData = useSelector(selectLoginData);
  const partnerCustomer = useSelector(selectPartnerCustomer);
  const currentUser = useSelector(selectCurrentUser);
  const { pathname } = useLocation();
  const [logoutDialog, setLogout] = useState(false);
  const [sessionSwitcherOpen, setSessionSwitcherOpen] = useState(false);

  let showSessionSwitcher = currentUser?.isSuperUser || false;

  const logoImg = useMemo(
    () => partnerCustomer?.customer_config?.image || KaiduLogoImg,
    [partnerCustomer]
  );
  const toggleLogoutDialog = () =>
    setLogout((prevState: boolean) => !prevState);
  const toggleSessionSwitcher = () =>
    setSessionSwitcherOpen((prevState: boolean) => !prevState);

  return (
    <SideNavWrapper className="pt-6 shadow">
      <div className="w-full px-4">
        {/* <PageTitle>{title}</PageTitle> */}
        <StyledLink to="/">
          <Image src={logoImg} width={250} />
        </StyledLink>
      </div>
      <div className="w-full">
        {isFilledArray(pages) &&
          pages.map((page, index) => {
            const { route, icon, text } = page || {};
            return (
              <NavTab
                to={route}
                isSelected={pathname === route}
                icon={icon}
                text={text}
                key={text}
              />
            );
          })}
      </div>
      <div className="w-full">
        {userData?.isLoggedIn &&  <UserProfile data={userData} />}
        { showSessionSwitcher && (
          <NavTab
            to="Switch Session"
            icon="FaGlobe"
            text="Switch Session"
            isLink={false}
            onClick={toggleSessionSwitcher}
          /> 
        )}
        <NavTab
          to="logout"
          icon="sign-out-alt"
          text="Logout"
          isLink={false}
          onClick={toggleLogoutDialog}
        />
      </div>
      <LogoutDialog isOpen={logoutDialog} toggleModal={toggleLogoutDialog} /> 
      <SessionSwitcher
        isOpen={sessionSwitcherOpen}
        onClose={toggleSessionSwitcher}
      />     
    </SideNavWrapper>
  );
}
