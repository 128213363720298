import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { geocodeByAddress } from "react-places-autocomplete";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSWRConfig } from "swr";

import { OperationIcon } from "components/atomic/OperationIcon";
import {
  putCustomer,
  uploadImage,
  verifyDashboardURL,
} from "domain/customers/api";
import { CustomersListItem } from "features/kaidu-config-server";
import {
  CUSTOMERS_LIST_API,
  CUSTOMER_ITEM_ID_API,
} from "features/kaidu-config-server/customers-list";
import { useAsync } from "hooks";
import { AsyncLifecycle } from "utils";
import { isValidURL } from "utils/helper";
import { ControlledLabelledSwitch } from "../../../../components/molecule/LabelledSwitch";
import { CustomerTypeSelector } from "../../customer-type";
import {
  createCustomerListItemFromForm,
  getFormCustomerListItemFromFetched,
} from "../../lib";
import { clearModalData, setOpenModal } from "../../lib/redux/customersSlice";
import { TimezoneInputGroup } from "../../time-zone";
import { FormCustomerListItem } from "../../types";
import { GoogleAutocomplete } from "../GoogleAutocomplete";
import {
  FormContainer,
  FormLabel,
  FullField,
  Input,
  TextArea,
  Note,
  SaveButton,
  SlimFieldLeft,
  SlimFieldRight,
  Title,
} from "../atomic/ClientInformationModalStyles";
import { HoursOfOperationInput } from "./HoursOfOperationInput";

export function EditCustomerForm({
  customerItem,
  allCustomerNames,
}: {
  customerItem: CustomersListItem;
  allCustomerNames: string[];
  [x: string]: any;
}) {
  //Hooks
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const initialValues = getFormCustomerListItemFromFetched(customerItem);
  const {
    reset,
    handleSubmit,
    register,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
  });

  const [googleAddress, setGoogleAddress] = useState(initialValues.address1);
  const [currentImageURL, setCurrentImageURL] = useState<string | null>(
    initialValues.image
  );
  const [imageFile, setImageFile] = useState<undefined | File>(undefined);

  const handleFinishBtnClick = async () => {
    const result = await trigger();
    if (result) {
      await execute(); //execute submitData
    }
  };

  const submitData = async () => {
    const data = getValues() as FormCustomerListItem;

    const { customer_name, image, dashboard, management_dashboard } = data;
    const found = allCustomerNames.find((name) => name === customer_name);
    if (found && customer_name !== customerItem.customer_name) {
      throw new Error("This client name already exists");
    }

    if (dashboard) {
      if (!isValidURL(dashboard)) {
        throw new Error("Invalid dashboard URL");
      }
      const verified_dashboard = await verifyDashboardURL(dashboard);
      if (
        verified_dashboard &&
        verified_dashboard.customer_id !== data.customer_id
      )
        throw new Error(`Duplicated dashboard URL!`);
    }

    if (management_dashboard) {
      if (!isValidURL(management_dashboard)) {
        throw new Error("Invalid management dashboard URL");
      }
      const verified_management_dashboard = await verifyDashboardURL(
        management_dashboard,
        true
      );
      if (
        verified_management_dashboard &&
        verified_management_dashboard.customer_id !== data.customer_id
      )
        throw new Error(`Duplicated management dashboard URL!`);
    }

    let newImage: string = image;
    if (imageFile) {
      newImage = await uploadImage(imageFile);
    }

    const submitted = createCustomerListItemFromForm({
      ...data,
      address1: googleAddress,
      image: newImage || null,
      dashboard: dashboard || null,
      management_dashboard: management_dashboard || null,
    });

    const res = await putCustomer(submitted);
    reset({ ...data, address1: googleAddress, image: newImage ?? image });
    mutate(CUSTOMERS_LIST_API);
    mutate(
      `${CUSTOMER_ITEM_ID_API}/${encodeURIComponent(customerItem.customer_id)}`
    );

    return res;
  };

  const { execute, status, error } = useAsync(submitData, false);

  useEffect(() => {
    const fetchData = async () => {
      if (status === AsyncLifecycle.FULFILLED) {
        console.log("modal request fulfilled");

        dispatch(clearModalData());
        dispatch(setOpenModal(false));
        toast.success(`Client is updated successfully`);
      }

      if (status === AsyncLifecycle.REJECTED) {
        dispatch(clearModalData());
        dispatch(setOpenModal(false));
        toast.error(
          `${error?.message}` ??
            `Failed to update client ${customerItem?.customer_name}`
        );
      }
    };
    fetchData();
  }, [status]);

  // handler for the Autocomplete onSelect
  const handleAddressSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;
      const getAddressComponent = (type) =>
        addressComponents.find((component) => component.types.includes(type))
          .long_name;

      const fullAddress = `${getAddressComponent(
        "street_number"
      )} ${getAddressComponent("route")}`;

      const city = getAddressComponent("locality");
      const state = getAddressComponent("administrative_area_level_1");
      const postalCode = getAddressComponent("postal_code");
      const country = getAddressComponent("country");
      setGoogleAddress(fullAddress);
      setValue("city", city);
      setValue("state", state);
      setValue("postalCode", postalCode);
      setValue("country", country);
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  const handleUploadClick = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    setCurrentImageURL(URL.createObjectURL(file));
  };

  return (
    <div className="flex justify-center align-items-center">
      <FormContainer
        className="flex justify-content-center align-items-center"
        id="address-form"
        action=""
        method="get"
        autoComplete="off"
      >
        <Title className="title">Client Information</Title>
        <Note className="note">
          <em>
            Edit client information in the Kaidu system. * = required field
          </em>
        </Note>
        <FullField>
          <FormLabel htmlFor="customer-name">Client Name*</FormLabel>
          <Input
            name="customer_name"
            {...register("customer_name", { required: true })}
          />
          {errors.customer_name && <div>Required</div>}
        </FullField>
        <FullField>
          <FormLabel htmlFor="special-note">Special Notes</FormLabel>
          <Input
            name="specialNote"
            placeholder="Optional"
            {...register("specialNote")}
          />
        </FullField>
        <FullField>
          <FormLabel htmlFor="contact-number">Contact Number*</FormLabel>
          <Input
            name="contactNumber"
            placeholder="(xxx)-xxx-xxxx"
            {...register("contactNumber")}
          />
        </FullField>
        <FullField>
          <FormLabel htmlFor="address1">Address*</FormLabel>
          <GoogleAutocomplete
            name="address1"
            value={googleAddress}
            placeholder="Enter address"
            setValue={setValue}
            onChange={(address) => {
              setGoogleAddress(address);
            }}
            onSelect={handleAddressSelect}
            searchOptions={{
              types: ["address"],
              fields: ["address_components", "geometry"],
            }}
          />
        </FullField>
        <FullField>
          <FormLabel htmlFor="address2">
            Apartment, unit, suite, or floor #
          </FormLabel>
          <Input
            name="address2"
            placeholder="Optional"
            {...register("address2")}
          />
        </FullField>
        <FullField>
          <FormLabel htmlFor="city">City*</FormLabel>
          <Input name="city" placeholder="Enter city" {...register("city")} />
        </FullField>
        <SlimFieldLeft>
          <FullField>
            <FormLabel htmlFor="state">State/Province*</FormLabel>
            <Input
              name="state"
              placeholder="Enter state/province"
              {...register("state")}
            />
          </FullField>
        </SlimFieldLeft>
        <SlimFieldRight>
          <FormLabel htmlFor="postcode">Postal code*</FormLabel>
          <Input
            name="postalCode"
            placeholder="Enter postal/zip code"
            {...register("postalCode")}
          />
        </SlimFieldRight>
        <FullField>
          <FormLabel htmlFor="country">Country*</FormLabel>
          <Input
            name="country"
            placeholder="Enter country"
            {...register("country")}
          />
        </FullField>
        <div className={"w-full"}>
          <TimezoneInputGroup control={control} />
        </div>
        <FullField>
          <HoursOfOperationInput control={control} setValue={setValue} />
        </FullField>
        <FullField className="flex items-center">
          <div className="flex-1">
            <ControlledLabelledSwitch
              control={control}
              name={"realtime_flag"}
              label="Enable Real-time"
            />
          </div>
          <div className="flex-1">
            <FormLabel htmlFor="passer_by_interval">
              {"Pass-by Interval (in seconds)"}
            </FormLabel>
            <Input
              name="passer_by_interval"
              type="number"
              {...register("passer_by_interval")}
            />
          </div>
        </FullField>
        <div className={"w-full"}>
          <CustomerTypeSelector control={control} />
        </div>
        {customerItem?.isPartner && (
          <>
            <FullField>
              <FormLabel htmlFor="dashboard">Dashboard URL</FormLabel>
              <Input
                name="dashboard"
                placeholder="Enter Dashboard URL..."
                {...register("dashboard")}
              />
            </FullField>
            <FullField>
              <FormLabel htmlFor="management_dashboard">
                Management Dashboard URL
              </FormLabel>
              <Input
                name="management_dashboard"
                placeholder="Enter Management Dashboard URL..."
                {...register("management_dashboard")}
              />
            </FullField>
          </>
        )}
        <FullField>
          <FormLabel htmlFor="image">Customer Image</FormLabel>
          <Input
            name="image"
            placeholder="Enter Image URL..."
            {...register("image")}
          />
        </FullField>
        <Card className="mt-4">
          {currentImageURL ? (
            <CardActionArea>
              <CardMedia
                component="img"
                height="140"
                image={currentImageURL}
                alt="Customer Image"
              />
              <CardActions className="justify-end">
                <IconButton size="small" component="label">
                  <OperationIcon name="edit" />
                  <input
                    accept=".png, .jpg, .jpeg"
                    className="hidden"
                    multiple
                    type="file"
                    onChange={handleUploadClick}
                  />
                </IconButton>
                <IconButton size="small">
                  <OperationIcon
                    name="trash-alt"
                    onClick={() => {
                      setValue("image", null);
                      setCurrentImageURL(null);
                      setImageFile(undefined);
                    }}
                  />
                </IconButton>
              </CardActions>
            </CardActionArea>
          ) : (
            <CardActionArea>
              <CardActions className="justify-end">
                <IconButton component="label">
                  <OperationIcon
                    name="FaUpload"
                    htmlFor="upload-image"
                  ></OperationIcon>
                  <input
                    accept="image/*"
                    className="hidden"
                    multiple
                    type="file"
                    onChange={handleUploadClick}
                  />
                </IconButton>
              </CardActions>
            </CardActionArea>
          )}
        </Card>
        <FullField>
          <FormLabel htmlFor="dashboard_email_template">Email Template</FormLabel>
          <TextArea
            name="dashboard_email_template"
            placeholder="Enter Email Template..."
            {...register("dashboard_email_template")}
          />
        </FullField>
        <FullField className="flex justify-center items-center">
          <SaveButton
            type="button"
            className="save-button mt-3"
            onClick={handleSubmit(handleFinishBtnClick)}
          >
            Confirm
          </SaveButton>
        </FullField>
      </FormContainer>
    </div>
  );
}

export default EditCustomerForm;
