import { useMachine } from "@xstate/react";
import queryString from "query-string";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { createMachine } from "xstate";

import { Spinner } from "components/atomic/Spinner";
import { logout, setTokenToLocalStorage } from "domain/auth";
import { setAxiosDefault } from "domain/auth/lib/axios";
import { fetchCustomer, fetchUsersList } from "features/kaidu-config-server";
import {
  selectPartnerCustomer,
  setCurrentuser,
  setPartnerCustomer,
} from "providers/redux/globalSlice";

export type StorageProps = {
  token: string;
};

/**
 * get token from url, either from query string or path segment
 */
function getTokenFrom(location, match) {
  // console.log(" getTokenFrom", location, match);
  const { search } = location || {};
  const parsed = queryString.parse(search) || {};
  const { params } = match || {};
  const { token } = params || {};
  const result = token || parsed?.token;
  // console.log(" getTokenFrom result", result);
  // console.log(" getTokenFrom typeof result ", typeof result);
  return result;
}

async function checkIsUserValid() {
  // fetch user
  const users = await fetchUsersList();
  // fetch customer
  const customer = await fetchCustomer(users?.customers_list_id);
  let error;
  if (!users) error = "You're not allowed to access this dashboard";
  // if (!users) error = UNREGISTERED_USER_ERROR_MESSAGE;
  else if (users && !customer) error = "Customer not found";
  return { user: users, customer, error };
}

const loginSuccessMachine = createMachine(
  {
    id: "loginSuccess",
    initial: "unchecked",
    states: {
      unchecked: {
        on: {
          FOUND_TOKEN: { target: "checkIsUserValid", actions: "SET_TOKEN" },
          TOKEN_NOT_FOUND: "failure",
        },
      },
      checkIsUserValid: {
        invoke: {
          src: () => checkIsUserValid(),
          onDone: [
            {
              target: "final",
              cond: (context, event) => {
                const { customer } = event?.data || {};
                return Boolean(customer?.customer_id);
              },
            },
            {
              target: "failure",
            },
          ],
          onError: "failure",
        },
      },
      final: {},
      failure: {},
    },
  },
  {
    actions: {
      SET_TOKEN: (context, event) => {
        const { token, partnerCustomer } = event?.data || {};
        const { customer_id } = partnerCustomer || {};
        const partnerName = partnerCustomer?.customer_name;
        setAxiosDefault(token, customer_id, !customer_id);
        setTokenToLocalStorage(token, partnerName);
      },
    },
  }
);

/**
 * Handle login success result from server
 */
export function LoginSuccess(props: any) {
  const { match } = props || {};
  // const isAuthInLocalStorageValid = checkIsAuthInLocalStorageValid();

  // hooks
  const dispatch = useDispatch();
  const location = useLocation();
  let history = useHistory();
  // const users = useAllUsersList();
  // * Once userData is found load customer Data
  // const { userData } = useUsersList(isAuthInLocalStorageValid);
  // const usersCustomerId = userData?.customers_list_id;
  // const customer = useCustomer(usersCustomerId);
  const [state, send] = useMachine(loginSuccessMachine);
  const token = getTokenFrom(location, match);
  const partnerCustomer = useSelector(selectPartnerCustomer);

  useEffect(() => {
    if (token) {
      send({ type: "FOUND_TOKEN", data: { token, partnerCustomer } });
    } else {
      send("TOKEN_NOT_FOUND");
    }
  }, [send, token, partnerCustomer]);

  if (state.matches("final")) {
    const { user, partnerCustomer } = state.event.data;
    dispatch(setCurrentuser(user));
    dispatch(setPartnerCustomer(partnerCustomer));
    history.push("/");
  } else if (state.matches("failure")) {
    const { error } = state.event.data || {};
    logout();
    const partner_customer_name = partnerCustomer?.customer_name;
    const failedURL = `/login/failure${
      partner_customer_name
        ? `?customer=${encodeURI(partner_customer_name)}`
        : ""
    }`;
    history.push(failedURL, { error });
  }

  return <Spinner style={{ position: "absolute", top: "50%", left: "50%" }} />;
}

export default LoginSuccess;
