import { Nav, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { selectLoginData } from "providers/redux/globalSlice";
import { isFilledArray } from "utils";
import { LogoutNavItem } from "./LogoutNavItem";
import NavTab from "./NavTab";
import { UserProfile } from "./Profile";

export function MobileNav({ title, pages, ...optionals }) {
  // A top app bar showing a menu button and app title
  const userData = useSelector(selectLoginData);
  const { pathname } = useLocation();

  return (
    <Navbar bg="light" expand="lg" className="w-full px-3 flex-grow">
      {/* <div className="pl-2 flex w-full justify-between"> */}
      <Navbar.Brand href="#">
        {/* <PageTitle>{title}</PageTitle> */}
        {userData?.isLoggedIn && <UserProfile data={userData} />}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav
          className="me-auto my-2 my-lg-0"
          style={{ maxHeight: "40vh" }} // max height of expanded nav menu
          navbarScroll
        >
          {isFilledArray(pages) &&
            pages.map((page) => {
              const { route, icon, text } = page || {};
              return (
                <NavTab
                  to={route}
                  isSelected={pathname === route}
                  icon={icon}
                  text={text}
                  key={text}
                />
              );
            })}
          <LogoutNavItem />
        </Nav>
      </Navbar.Collapse>
      {/* </div> */}
    </Navbar>
  );
}
