import { ListGroup } from 'react-bootstrap';
import styled from 'styled-components';

export const ClickableListGroupItem = styled(ListGroup.Item)`
  &:hover {
    cursor: pointer;
    background-color: #ddd;
  }
  background-color: ${(props) => (props.isSelected ? '#aaa' : '')};
`;

export const ListGroupItem = ({ clickable = false, ...optionals }) => {
  const {isSelected, ...rest} = optionals;
  if (clickable) return <ClickableListGroupItem {...rest} />;
  return <ListGroup.Item {...rest} />;
};

export default ListGroupItem;
