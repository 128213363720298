import { convertISO8601ToReadable } from "features/date";
import { convertVersionStringToObject } from "features/firmware";
import { IoTDeviceState } from "features/kaidu-config-server/iot-cloud";
import {
  DEVICE_STATUS,
  DEVICE_STATUS_VALUES,
  convertKaiduDeviceStatusNumToString,
} from "features/kaidu-config-server/kaidu-device-status";
import { NO_VALUE_PLACEHOLDER } from "lib/constants";
import { filterByProperty, isFilledArray } from "utils";
import { StatusRowData } from "./types";

export function filterByStatus(list, selectedStatus) {
  // if (selectedStatus === "ONLINE" || selectedStatus === "OFFLINE") {
  //   if (selectedStatus === "ONLINE") {
  //     return list.filter((item) => item.isOnline);
  //   }
  //   if (selectedStatus === "OFFLINE") {
  //     return list.filter((item) => !item.isOnline);
  //   }
  // }

  if (!isFilledArray(list)) {
    return list;
  }
  return selectedStatus === "all"
    ? list
    : filterByProperty(list, "kaidu_device_status", selectedStatus);
}
export function getDisplayedFirmwareVersion(longVersionString: string) {
  try {
    if (!longVersionString || typeof longVersionString !== "string") {
      console.error(`Invalid version: ${longVersionString}`);
      throw new TypeError(`Not available`);
    }

    if (longVersionString.length < 14) {
      throw new Error("Invalid version. Too short");
    }

    const arr = longVersionString.split("-");
    const versionNumbers = [];
    let prefix = "";
    arr.forEach((item) => {
      const v = Number(item);
      if (!isNaN(v)) versionNumbers.push(v);
      else if (String(item).toLowerCase() === "pre") prefix = "pre-";
    });
    return `${prefix}${versionNumbers.join(".")}`;
  } catch (error) {
    return error?.message;
  }
}

export function getDisplayedVersion(hw_sw_version) {
  try {
    const { hw, sw } = convertVersionStringToObject(hw_sw_version) || {};
    return { hw, sw };
  } catch (error) {
    return { hw: NO_VALUE_PLACEHOLDER, sw: NO_VALUE_PLACEHOLDER };
  }
}

export function convertToReadable(row: any) {
  let result = row;
  const {
    last_seen_timestamp,
    hw_sw_version,
    slave_firmware_version,
    kaidu_device_status,
  } = row || {};
  result.last_seen_timestamp = convertISO8601ToReadable(last_seen_timestamp);
  if (row.hw_sw_version) {
    result.hw_sw_version = `Master Firmware version: ${getDisplayedFirmwareVersion(
      hw_sw_version
    )}`;
  }
  if (row.slave_firmware_version) {
    result.hw_sw_version = `${result.hw_sw_version}${
      result.hw_sw_version ? ";\n" : ""
    }Slave Firmware version: ${getDisplayedFirmwareVersion(
      slave_firmware_version
    )}`;
  }
  if (
    kaidu_device_status ||
    kaidu_device_status === "0" ||
    kaidu_device_status === 0
  ) {
    result.kaidu_device_status =
      convertKaiduDeviceStatusNumToString(kaidu_device_status);
  }
  return result;
}

/**
 * @description add ONLINE/OFFLINE state to each object of the given list
 * @param row
 * @param scannerIoTStates
 * @returns
 */
export function assignOnlineStatusProp(
  row: StatusRowData,
  scannerIoTStates: IoTDeviceState[]
) {
  const found = scannerIoTStates.find(
    (item) => item?.id === row?.mqtt_device_id
  );
  if (found) {
    return { ...row, isOnline: found?.isOnline };
  }
  return { ...row, isOnline: false };
}
