import { BASE_API } from '../constants';
import axios from 'axios';
import { handle } from 'utils';
import _ from 'lodash';

const FOO = '/kaidu_firmware_list';
export const KAIDU_FIRMWARE_LIST_API = `${BASE_API}${FOO}`;
/**
 * @returns Promise<string> firmware_url, e.g. "v0.1.8.bin"
 */
export async function fetchLatestSoftwareVersion(): Promise<string> {
  const [result, resultErr] = await handle(axios.get(KAIDU_FIRMWARE_LIST_API));

  const length = result?.data?.length;
  console.debug(`firmware list length: ${length}`);
  const latest = result?.data[length - 1]?.firmware_url;
  return latest;
}
