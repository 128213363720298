import { User } from 'features/kaidu-config-server/users-list';
import React from 'react';
import { isFilledArray } from 'utils';
import { AddBtn } from 'components/molecule/AddBtn';
import { useDispatch } from 'react-redux';
import {
  setOpenModal,
  setModalData,
} from 'features/users/lib/redux/usersSlice';
import { UserItem } from './UserItem';

function renderUsers(rows: User[]) {
  return rows.map((row, index) => <UserItem key={index} data={row}></UserItem>);
}

/**
 * View for User list, allow adding new user
 */
export function UserList({ users, ...optionals }) {

  // Hooks
  const dispatch = useDispatch();

  const handleAddUser = () => {
    console.log('click add user');
    dispatch(
      //@ts-ignore
      setModalData({
        operationType: 'add',
      })
    );
    //@ts-ignore
    dispatch(setOpenModal(true));
  };

  return (
    <div className='flex flex-col w-full mb-3'>
      <div className='flex justify-end mb-3'>
        <AddBtn onClick={handleAddUser}>Add Pending User</AddBtn>
      </div>
      <table>
        <thead>
          <tr className='flex items-center justify-between w-full p-2'>
            <th className='flex-1 max-w-md'>Email</th>
            <th className='flex-1'>Name</th>
            <th className='flex space-x-2 flex-grow-0 flex-1'>Operations</th>
          </tr>
        </thead>
        <tbody>
          {isFilledArray(users) ? (
            renderUsers(users)
          ) : (
            <p>No User can be found</p>
          )}
        </tbody>
      </table>
    </div>
  );
}
