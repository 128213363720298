import { useEffect, useMemo, useState } from "react";

import { useCustomersList } from "features/kaidu-config-server";
import ErrorMsg from "../atomic/ErrorMsg";
import Spinner from "../atomic/Spinner";
import { LabeledSelect } from "./Select";

export function Filter(props) {
  const { onChangeFilter, children } = props;

  //Hooks
  const {
    customersList,
    isLoading: isCustomersListLoading,
    isError: isCustomersListError,
  } = useCustomersList(true, {
    revalidateOnFocus: false,
    refreshInterval: 0,
  });

  const [selectedCustomer, setSelectedCustomer] = useState("all");

  const filters = useMemo(() => {
    return {
      customer_name: (customer) =>
        customer === selectedCustomer || selectedCustomer === "all",
    };
  }, [selectedCustomer]);

  useEffect(() => {
    //filter all displayed list
    onChangeFilter && onChangeFilter(filters);
  }, [onChangeFilter, filters]);

  if (isCustomersListLoading) {
    return <Spinner animation="border" />;
  }
  if (isCustomersListError)
    return (
      <ErrorMsg text={`An error has occurred while retrieving customer list`} />
    );

  return (
    <div className="flex items-center w-max">
      <LabeledSelect
        options={customersList.map((customer) => customer.customer_name)}
        label="Customer"
        id="select-customer"
        onChange={(e) => setSelectedCustomer(e.target.value)}
        className="mr-2"
      />
      {children}
    </div>
  );
}
