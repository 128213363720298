import { useState } from "react";
import BootstrapBadge from "react-bootstrap/Badge";
import { useDispatch } from "react-redux";

import Icon from "components/atomic/Icon";
import OperationsGroup from "components/molecule/OperationsGroup";
import {
  setModalData,
  setOpenModal,
} from "domain/customers/lib/redux/customersSlice";
import { CustomersListItem } from "features/kaidu-config-server";
import { helpers } from "utils";
import { CustomerSelectBtn } from "./CustomerSelectBtn";

export default function CustomerItem({
  item,
  defaultCustomerID,
  ...optionals
}: {
  item: CustomersListItem;
  [x: string]: any;
}) {
  const { isSelected, ...rest } = optionals;

  //Hooks
  const dispatch = useDispatch();

  const [showCustomerSelectBtn, setShowCustomerSelectBtn] = useState(false);

  // Conditions
  const isValidCustomer = item && item?.customer_name && item?.customer_id;
  if (!isValidCustomer) {
    return null;
  }

  const handleEdit = () => {
    console.log("click edit customer");
    dispatch(
      setModalData({
        operationType: "edit",
        customerItem: item,
      })
    );
    dispatch(setOpenModal(true));
    console.log(`dispatched customer item: ${helpers.inspect(item)}`);
  };

  const handleDelete = () => {
    console.log("click delete customer");
    dispatch(
      setModalData({
        operationType: "delete",
        customerItem: item,
      })
    );
    dispatch(setOpenModal(true));
  };

  return (
    <div className="flex space-x-8 items-center hover:bg-gray-200">
      <div
        className="flex space-x-2 justify-between flex-grow p-2 pl-4"
        onMouseEnter={() => setShowCustomerSelectBtn(true)}
        onMouseLeave={() => setShowCustomerSelectBtn(false)}
      >
        <div className="flex items-center">
          <span className="mr-3">{item?.customer_name}</span>
          {item?.isPartner ? (
            <BootstrapBadge className="mr-3" bg="secondary">
              Partner
            </BootstrapBadge>
          ) : null}
          {isSelected ? <Icon name="check-circle" color="green" /> : null}
        </div>
        {showCustomerSelectBtn ? (
          <CustomerSelectBtn
            isSelected={isSelected}
            customerId={item?.customer_id}
            defaultCustomerID={defaultCustomerID}
          />
        ) : null}
      </div>
      <div className="flex space-x-2 flex-grow-0">
        <OperationsGroup
          onEdit={handleEdit}
          onDelete={handleDelete}
          showDelete={true}
        />
      </div>
    </div>
  );
}
