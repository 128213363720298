import { useDispatch, useSelector } from "react-redux";

import BaseModal from "components/molecule/BaseModal";
import { UpdateFirmwareDialog } from "domain/firmware-update";
import {
  DEVICE_STATUS_MODAL,
  selectModal,
  selectedDeviceData,
  selectedModal,
} from "providers/redux/deviceStatusSlice";

export function DeviceVersionUpdateModal() {
  const dispatch = useDispatch();
  const deviceData = useSelector(selectedDeviceData);
  const modal = useSelector(selectedModal);
  const modalTitle = deviceData?.device_name || "Unnamed Device";
  const {
    mqtt_device_id,
    kaidu_device_list: { source_id },
  } = deviceData || { kaidu_device_list: {} };
  return (
    <BaseModal
      show={modal === DEVICE_STATUS_MODAL.VERSION_UPDATE}
      onHide={() => dispatch(selectModal(null))}
      title={modalTitle}
      showCloseButton={false}
    >
      {deviceData ? (
        <UpdateFirmwareDialog
          identifier={mqtt_device_id || source_id}
          value={deviceData.hw_sw_version}
          onHide={() => dispatch(selectModal(null))}
          data={deviceData}
        />
      ) : (
        "Device not found"
      )}
    </BaseModal>
  );
}
