import {
  getActivatedKaiduDevices,
  KaiduDevicesListItem,
} from '../../../kaidu-devices-list';
import {
  KaiduConfigurationsListItem,
  findByKaiduDeviceId,
} from '../../../kaidu-configurations-list';
import { NULL_VALUE } from '../../../constants';
import { getOnlineItems, IoTDeviceState } from '../../../iot-cloud';
import {
  MQTTConfigurationsListItem,
  findByMQTTConfigurationID,
} from '../../../mqtt-configurations-list';


// TODO: https://deeppixel.atlassian.net/browse/SS-739
// current implementation: have a good KaiduConfigurationsListItem
function checkIsValid(
  item: KaiduDevicesListItem,
  configList: KaiduConfigurationsListItem[]
) {
  let isValid = false;
  const kaiduDeviceId = item?.kaidu_device_id;
  if (kaiduDeviceId && kaiduDeviceId !== NULL_VALUE) {
    const matchedConfig = findByKaiduDeviceId(
      configList,
      item?.kaidu_device_id
    );
    isValid = Boolean(matchedConfig);
  }
  return isValid;
}

export function getValidKaiduDevices(
  list: KaiduDevicesListItem[],
  configList: KaiduConfigurationsListItem[]
): KaiduDevicesListItem[] {
  const activateds = getActivatedKaiduDevices(list);
  const valids = activateds?.filter((item) => checkIsValid(item, configList));
  return valids;
}

export function getInvalidKaiduDevices(
  list: KaiduDevicesListItem[],
  configList: KaiduConfigurationsListItem[]
): KaiduDevicesListItem[] {
  const activateds = getActivatedKaiduDevices(list);
  const valids = activateds?.filter((item) => !checkIsValid(item, configList));
  return valids;
}

export function countValidKaiduDevices(
  list: KaiduDevicesListItem[],
  configList: KaiduConfigurationsListItem[]
): number {
  const activateds = getValidKaiduDevices(list, configList);
  return activateds?.length;
}

export function countInvalidKaiduDevices(
  list: KaiduDevicesListItem[],
  configList: KaiduConfigurationsListItem[]
): number {
  const activateds = getInvalidKaiduDevices(list, configList);
  return activateds?.length;
}

// export function getOnlineKaiduDevices(
//   deviceList: KaiduDevicesListItem[],
//   iotList: IoTDeviceState[],
//   configList: KaiduConfigurationsListItem[]
// ) {
//   const matchedList = matchDeviceListWithIoTList(
//     deviceList,
//     iotList,
//     configList
//   );
//   return getOnlineItems(matchedList);
// }

export function matchDeviceListWithIoTList(
  deviceList: KaiduDevicesListItem[],
  iotList: IoTDeviceState[],
  configList: KaiduConfigurationsListItem[],
  mqttConfigList: MQTTConfigurationsListItem[]
) {
  const matchedList = deviceList?.map((kaiduDeviceItem) => {
    
    const matchedConfig = findByKaiduDeviceId(
      configList,
      kaiduDeviceItem?.kaidu_device_id
    );

    const matchedMQTTConfig = findByMQTTConfigurationID(
      mqttConfigList,
      matchedConfig?.mqtt_configuration_id
    );

    const matchedIoTItem =
      iotList.find(
        (iotItem) => iotItem?.id === matchedMQTTConfig?.mqtt_device_id
      ) || {};
    return { ...matchedIoTItem, ...matchedConfig };
  });
  return matchedList;
}
