import { Label } from "components/atomic/Label";
import { BottomBorderSelect } from "components/atomic/SimpleSelect";
import { Controller } from "react-hook-form";
import { InputContainer } from "./SelectorContainer";

const id = "rssi-selector";
const label = "RSSI Threshold";
const displayedProp = "rssi_thresholds_name";
const controlledProp = "rssi_thresholds_id";

export function RSSIThresholdSelect({
  setValue,
  control,
  options,
  defaultValue,
  ...optionals
}) {
  const handleRSSIChanges = (e) => {
    // const name = e.target.value;
    // console.log(name);
    // const foundID = findRSSIIDByName(options, name);
    // setValue('rssi_thresholds_id', foundID);
    const value = e.target.value;
    const foundOption = options?.find((item) => item[controlledProp] === value);
    setValue(controlledProp, value);
    if (foundOption) setValue("rssi_threshold_value", foundOption.rssi_min);
  };

  return (
    <InputContainer>
      <Label htmlFor={id} className="mr-2 flex items-center">
        {label}
      </Label>
      <Controller
        name={controlledProp}
        control={control}
        rules={{ required: false }}
        render={({ field }) => (
          <BottomBorderSelect
            defaultValue={defaultValue}
            onChange={handleRSSIChanges}
            className="capitalize"
          >
            {options?.map((item) => (
              <option
                value={item[controlledProp]}
                key={`${item[controlledProp]}-key`}
                className="capitalize"
              >
                {item ? item[displayedProp].toLowerCase() : "No data"}
              </option>
            ))}
          </BottomBorderSelect>
        )}
      />
    </InputContainer>
  );
}
