import Label, {LabelForSelect} from '../atomic/Label';
import { SimpleSelect } from 'components/atomic/SimpleSelect';
import { isFilledArray } from 'utils';
import ReactSelect, { Options } from 'react-select'; //https://react-select.com/home

// if no valid options, show a disabled 'No data' option
const nullValueOption = 'No data';

export type Option = {
  value: string | number;
  label: string;
  [x: string]: any;
};

export function Select({
  options = [],
  ...optionals
}: {
  options?: string[];
  [x:string]: any;
}) {
  const { name, disabled = false, showAll = true, defaultValue = 'all', register, ...rest } = optionals;
  const isOptionsValid = isFilledArray(options);
  const registerProps = register ? register(name) : {};

  return (
    <SimpleSelect
      {...rest}
      {...registerProps}
      defaultValue={defaultValue}
      disabled={!isOptionsValid || disabled}
    >
      {showAll && <option value={'all'}>All</option>}
      {isOptionsValid ? (
        options?.map((item, index) => (
          <option value={item ?? ''} key={`${item}-key-${index}`}>
            {item ? item : nullValueOption}
          </option>
        ))
      ) : (
        <option value={''}>{nullValueOption}</option>
      )}
    </SimpleSelect>
  );
}

export default Select;

export function LabeledSelect({ options = [], label = 'Select', ...optionals }) {
  const { id, ...rest } = optionals;

  return (
    <>
      <Label htmlFor={id} className='mr-2 flex items-center'>
        {label}
      </Label>
      <Select options={options} {...rest} id={id} />
    </>
  );
}


export function ZonesSelect({ options = [], label = 'Select', ...optionals }) {
  const { name, disabled = false, setValue, id, ...rest } = optionals;
  const isOptionsValid = isFilledArray(options);

  const handleChange = (event: Event) => {
    const { value } = event.target as HTMLSelectElement;
    if (setValue) {
      console.debug('ZonesSelect: setValue', value);
      setValue(name, value);
    }
  }

  return (
    <>
      <Label htmlFor={id} className='mr-2 flex items-center'>
        {label}
      </Label>
      <SimpleSelect
        {...rest}
        disabled={!isOptionsValid || disabled}
        onChange={handleChange}
      >
        {isOptionsValid ? (
          options.map((item, index) => (
            <option value={item ?? ''} key={`${item}-key-${index}`}>
              {item ? item : nullValueOption}
            </option>
          ))
        ) : (
          <option value={''}>{nullValueOption}</option>
        )}
      </SimpleSelect>
    </>
  );
}

/**
 * Select from a dropdown list, options as object array
 */
export function Select2({
  ...optionals
}: Partial<{
  disabled: boolean;
  value: string;
  showall: boolean;
  label: string;
  options: any;
  selectFirst: boolean;
  placeholder: string;
  [x: string]: any;
}>) {
  const {
    disabled = false,
    showall = false, // should show a "all" option
    selectFirst = true,
    value,
    onChange,
    label,
    options = [],
    className = '',
    selectClassName = '',
    placeholder = 'Select',
    defaultValue,
    ...rest
  } = optionals;

  // Props
  const displayedOptions = showall
    ? [{ label: 'All', value: null }, ...options]
    : options;
  // const isMoreThanOneOption = options && options.length > 1;
  const processedDefaultValue = selectFirst
    ? displayedOptions[0]
    : displayedOptions.find((opt) => opt.value === defaultValue);

  return (
    <div
      className={`flex items-center lg:flex-col lg:items-start w-full lg:w-auto justify-center lg:justify-start ${className}`}
      data-cy={rest['data-cy']}
    >
      {label ? <LabelForSelect className='mb-0 mr-2'>{label}</LabelForSelect> : null}
      <ReactSelect
        options={displayedOptions}
        isDisabled={disabled}
        placeholder={placeholder}
        defaultValue={processedDefaultValue}
        onChange={onChange}
        value={displayedOptions.find((opt) => opt.value === value)}
        className={
          selectClassName
            ? selectClassName
            : 'flex-1 lg:flex-none lg:w-36 xl:w-40'
        }
        {...rest}
      />
    </div>
  );
}
