import { Select, Select2 } from 'components/molecule/Select';
// import { Form } from 'react-bootstrap';
import { Button } from '../../../../components/atomic/Button';
// import { Icon } from '../../../../components/atomic/Icon';
import { getLocalTimeZone } from '../../../../features/date';
import { useController } from 'react-hook-form';
import { getTimeZones } from '@vvo/tzdb'; //https://www.npmjs.com/package/@vvo/tzdb
import { MuiLabel } from 'components/atomic/Label';
import {
  FormLabel,
} from '../../components/atomic/ClientInformationModalStyles';

const timeZonesOptions = getTimeZones().map((item) => {
  return { label: item.currentTimeFormat, value: item.name };
});

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    maxWidth: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-grid',
  }),
};

/**
 * Form name: timezone
 */
export function TimezoneInputGroup({ control, name = 'timezone' }) {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  return (
    <>
      {/* <Form.Label>Time zone</Form.Label> */}
      {/* <MuiLabel className='mb-2'>Time zone</MuiLabel> */}
      <FormLabel htmlFor={name}>Time zone</FormLabel>
      <div className='flex gap-x-4'>
        <Select2
          options={timeZonesOptions}
          value={value}
          onChange={(nextValue) => {
            onChange(nextValue?.value);
          }}
          name={name}
          styles={customStyles}
          selectClassName='flex-1 lg:flex-none lg:w-full'
          className='flex-1'
        />
        <Button
          variant='secondary'
          onClick={() => {
            const localTimezone = getLocalTimeZone();
            onChange(localTimezone);
          }}
        >
          Set as local timezone
        </Button>
      </div>
    </>
  );
}
