import React from 'react';
import { H3 } from 'components/atomic/Headings';

export function SettingsH3({ children, ...optionals }) {
  return (
    <>
      <H3 className='mt-5 mb-3' {...optionals}>{children}</H3>
    </>
  )
}
