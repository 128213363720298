// import { KeyValuePair } from 'components/molecule/KeyValuePair';
import { KeyValuePair } from "components/molecule/KeyValuePair";
import { AddNewCustomerBtn } from "domain/customers/components/AddNewCustomerBtn";
import { CustomerDropdownWithHook } from "domain/customers/components/CustomerDropdown";
import { convertISO8601ToReadableFormat } from "features/date";
import { DeviceName } from "../DeviceName";
import EditScannerStatus from "../EditScannerStatus";

export function DeviceSection({
  deviceName,
  customerName,
  control,
  defaultCustomerId,
  kaidu_device_status,
  last_seen_timestamp,
  onCustomerIdChange,
  ...optionals
}) {
  const { form } = optionals;
  return (
    <section>
      <DeviceName deviceName={deviceName} control={control} />
      <div className="flex w-full flex-col md:flex-row gap-2">
        <div className="flex-auto flex gap-2 flex-col lg:flex-row">
          <div>
            <CustomerDropdownWithHook
              defaultCustomerId={defaultCustomerId}
              onCustomerIdChange={onCustomerIdChange}
              showAll={false}
            />
          </div>
          <AddNewCustomerBtn show className="md:pt-6" />
        </div>
        <div className="flex-auto flex  flex-col gap-2">
          <EditScannerStatus defaultValue={kaidu_device_status} form={form} />
          <KeyValuePair
            className="flex-none"
            name={"Last seen"}
            value={convertISO8601ToReadableFormat(last_seen_timestamp)}
          />
        </div>
      </div>
    </section>
  );
}
