import { useBreakPoints } from "styles/hooks";
import { LargeNav } from "./LargeNav";
import { MobileNav } from "./MobileNav";
import { VERSION } from "lib";

export function Nav({ title, pages, ...optionals }) {
  console.info(`version - ${VERSION}`);
  const { isMobile } = useBreakPoints();

  return (
    <>
      {isMobile ? (
        <MobileNav title={title} pages={pages} />
      ) : (
        <LargeNav title={title} pages={pages} />
      )}
      {/* <MobileNav title={title} pages={pages} /> */}
    </>
  );
}
