import { isFilledArray } from "../../utils";
import { FirmwareDTO } from "../kaidu-config-server/kaidu-firmware-list";
import { Version } from "./types";

/**
 * @param  {string} str
 * @returns {string} firmware file name, e.g. "v1.0.0.bin"
 */
export function formateHardwareVersion(str: string): string {
  if (str.startsWith("v")) {
    return str;
  } else {
    return "v" + str;
  }
}

/**
 * e.g. "00-01-00-01-05" -> '01'
 */
export function extractHardwareVersion(version: string) {
  const arr = version.split("-").map((n) => parseInt(n));
  if (arr.length < 5) {
    throw new Error("Invalid Version string. Too short");
  }
  const hw = arr.slice(0, 2).join("");
  return hw === "00" ? null : hw;
}

export function appendBinAsFileExtension(str: string): string {
  if (str.endsWith(".bin")) {
    return str;
  } else {
    return str + ".bin";
  }
}

export function convertVersionStringToObject(versionString: string): Version {
  const arr = versionString.split("-").map((n) => {
    const v = parseInt(n);
    return isNaN(v) ? n : v;
  });
  if (arr.length < 5) {
    throw new Error("Invalid Version string. Too short");
  }
  let hw, sw;
  if (String(arr[0]).toLowerCase() === "pre") {
    hw = "pre-" + arr.slice(1, 3).join(".");
    sw = "pre-" + arr.slice(3).join(".");
  } else {
    hw = "v" + arr.slice(0, 2).join(".");
    sw = "v" + arr.slice(2).join(".");
  }

  return { hw, sw };
}

// e.g."00-01-00-01-05" -> "hardware: v0.1; software: v0.1.5"
export function convertVersionToReadable(str: string): string {
  let result;
  const { hw, sw } = convertVersionStringToObject(str);
  result = `hardware: ${hw}; software: ${sw}`;
  return result;
}

/**
 * @param  {string} str
 */
export function processVersionReading(str: string): Version {
  console.log(`processVersionReading: ${str}`);
  const buf = Buffer.from(str, "ascii");
  const hw = buf.slice(0, 2).join(".");
  const sw = buf.slice(2).join(".");
  return { hw, sw };
}

export function filterByHardwareVersion(
  firmwareList: FirmwareDTO[],
  hardwareList
) {
  if (!isFilledArray(hardwareList)) {
    return [];
  }

  if (!isFilledArray(firmwareList)) {
    return [];
  }

  const filtered = firmwareList.filter((item) => {
    const hw = extractHardwareVersion(item?.firmware_hw_sw_version);
    const found = hardwareList.find((item) => item === hw);
    return Boolean(found);
  });

  return filtered;
}
