import { createSlice } from "@reduxjs/toolkit";
import type { AppState } from "./store";

const initialState = {
  login: {
    isLoggedIn: false,
    username: "",
    userPicture: "",
    customerId: "",
    isSuper: false,
  },
  currentUser: null,
  partnerCustomer: null,
};

export const globalStatusSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    updateLogin(state, action) {
      state.login = action.payload;
    },
    resetLogin(state) {
      state.login = initialState.login;
    },
    setCurrentuser(state, action) {
      state.currentUser = action.payload;
    },
    setPartnerCustomer(state, action) {
      state.partnerCustomer = action.payload;
    },
  },
});

export default globalStatusSlice.reducer;

export const { updateLogin, resetLogin, setCurrentuser, setPartnerCustomer } =
  globalStatusSlice.actions;

export const selectLoginData = (state: AppState) =>
  state[globalStatusSlice.name].login;
export const selectIsLoggedIn = (state: AppState) =>
  state[globalStatusSlice.name]?.login?.isLoggedIn;
export const selectCurrentUser = (state: AppState) =>
  state[globalStatusSlice.name]?.currentUser;
export const selectPartnerCustomer = (state: AppState) =>
  state[globalStatusSlice.name]?.partnerCustomer;
