import _ from "lodash";
import useSWR, { SWRResponse } from "swr";
import { swrFetcher } from "utils";
import { CUSTOMER_TYPES_LIST_API } from "./api";
import { CustomerTypeDTO } from "./types";

/**
 *
 */
export function useCustomerTypeList(
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const { data: list, error }: SWRResponse<CustomerTypeDTO[], Error> = useSWR(
    CUSTOMER_TYPES_LIST_API,
    swrFetcher,
    options
  );

  return {
    customerTypeList: list && _.sortBy(list, ["name"]),
    isLoadingCustomerTypeList: !error && !list,
    isCustomerTypeListError: error,
  };
}
