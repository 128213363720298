
export const KAIDU_DEVICE_SERVICE_UUIDS = {
  CONFIGURATION: '8f7e1830-70b5-46b4-b09f-eda20e4b6a7b',
  OTA: 'c8659210-af91-4ad3-a995-a58d6fd26145',
};

export const KAIDU_DEVICE_CHARACTERISTICS_UUIDS = {
  HW_SW_VERSION: 'c8659212-af91-4ad3-a995-a58d6fd26145', //read
  OTA_FIRMWARE: 'c8659211-af91-4ad3-a995-a58d6fd26145', //notify, writable with response
  WIFI_SSID: '8f7e1831-70b5-46b4-b09f-eda20e4b6a7b', //read, write, maximum 15 bytes
  WIFI_PASSWORD: '8f7e1832-70b5-46b4-b09f-eda20e4b6a7b', //read, write
  MQTT_CERTIFICATE: '8f7e1833-70b5-46b4-b09f-eda20e4b6a7b', //read, write
  MQTT_DEVICE_ID: '8f7e1834-70b5-46b4-b09f-eda20e4b6a7b',//read, write
  DEVICE_STATISTICS: '8f7e1835-70b5-46b4-b09f-eda20e4b6a7b', //read
  OTA_FIRMWARE_WIFI: '8f7e1836-70b5-46b4-b09f-eda20e4b6a7b'
};
