import PACKAGE_DATA from '../../package.json';

// Global
export const APP_NAME = 'Kaidu Management Dashboard';
export const VERSION = '24022301';
// export const VERSION = PACKAGE_DATA.version;

export const KAIDU_FIRMWARE_CHUNK_SIZE = 512;

// Configs

// Environment variables
//@ts-ignore
const { REACT_APP_BASE_API, REACT_APP_FIRMWARE_HOST_ALT, REACT_APP_LOGIN_API_SUBPATH } = process.env || {};

// Kaidu Server APIs
export const BASE_API = REACT_APP_BASE_API || `REPLACE_BY_BUILD_REACT_APP_BASE_API`;
const LOGIN_API_SUBPATH = REACT_APP_LOGIN_API_SUBPATH || `REPLACE_BY_BUILD_REACT_APP_LOGIN_API_SUBPATH`;

const FIRMWARE_HOST_ALT =
  REACT_APP_FIRMWARE_HOST_ALT ?? `https://dev2.deeppixel.ai`;
export const SERVER_API = {
  BASE: BASE_API,
  CONFIGURATION: `${BASE_API}/kaidu_device_configuration`,
  CONFIGURATION_OF_CUSTOMER: `${BASE_API}/kaidu_device_configuration/customer`,
  CUSTOMERS_LIST: `${BASE_API}/customers_list`,
  CUSTOMER_DEVICE_DATA: `${BASE_API}/customers_device_data`,
  SINGLE_CUSTOMER_DEVICE_DATA: `${BASE_API}/customers_device_data/customer`,
  SINGLE_CUSTOMER_DEVICE_DATA_ITEM: `${BASE_API}/customers_device_data/id`,
  KAIDU_CONFIG_LIST: `${BASE_API}/kaidu_configurations_list`,
  KAIDU_DEVICE_STATUS_ENUM: `${BASE_API}/kaidu_device_status`,
  KAIDU_DEVICE_STATUS_LIST: `${BASE_API}/kaidu_devices_list`,
  KAIDU_PRECONFIGS: `${BASE_API}/kaidu_preconfigs`,
  KAIDU_IOT_STATUS: `${BASE_API}/iot_cloud`,
  KAIDU_UPDATE_FIRMWARE: `${BASE_API}/iot_cloud/update`,
  MQTT_CONFIG_LIST: `${BASE_API}/mqtt_configurations_list`,
  FIRMWARE_LIST: `${BASE_API}/kaidu_firmware_list`,
  AUTHENTICATION: {
    GOOGLE: `${BASE_API}${LOGIN_API_SUBPATH}`,
  },
  FIRMWARE_VERSIONS: `${FIRMWARE_HOST_ALT}/version.json`,
  FIRMWARE_FILE_BASE: `https://dev2.deeppixel.ai`,
  USERS_LIST: `${BASE_API}/users_list`,
  KAIDU_DEVICE_RANDOM_MESSEAG: `${BASE_API}/kaidu_device_random_msg`
};
export const ADD_FLOOR_PLAN_SUBPATH = '/addFloorplan';

// export const BACKEND_API = {
//   SCANNER_STATISTICS: 'https://kaidu-dev1.deeppixel.ai/scannerStatisticsall',
// };

export const SERVER_MQTT_DEVICE_ID = 'safetrackserver01';

export const NO_VALUE_PLACEHOLDER = 'Not available';
export const NO_NAME_PLACEHOLDER = 'No Name Found';

export const NODE_ENV = process.env.NODE_ENV;