import React, { useState } from "react";
import Icon from "../atomic/Icon";

export function SortIcon({ onClick, ...rest }) {
  // An icon component that changes the icon when clicked. 
  const [isAscend, setIsAscend] = useState(true);
  const handleClick = () => {
    const toggle = !isAscend;
    setIsAscend(toggle);
    onClick(toggle);
  };

  return (
    <button onClick={handleClick} {...rest}>
      <Icon name={isAscend ? "caret-square-up" : "caret-square-down"} />
    </button>
  );
}
