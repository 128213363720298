import { isFilledArray } from 'utils';
import { IoTDeviceState } from './types';
import {getDate, createDate} from 'features/date';

/**
 * @description count the number of online plugs in the IoTDeviceState
 * @param  {IoTDeviceState[]} list
 */
export function countOnline(list: IoTDeviceState[]) {
  return isFilledArray(list)
    ? list?.filter((iotState) => iotState.isOnline).length
    : 0;
}

/**
 * @description count the number of offline plugs in the IoTDeviceState
 * @param  {IoTDeviceState[]} list
 */
 export function countOffline(list: IoTDeviceState[]) {
  return isFilledArray(list)
    ? list?.filter((iotState) => !iotState.isOnline).length
    : 0;
}


export function getOnlineItems(list: any[]): any[] {
  return isFilledArray(list)
    ? list?.filter((iotState) => iotState?.isOnline)
    : [];
}

export function getOfflineItems(list: any[]): any[] {
  return isFilledArray(list)
    ? list?.filter((iotState) => !iotState?.isOnline)
    : [];
}