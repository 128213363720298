import Alert from '../../../components/atomic/Alert';
import { useBluetoothAvailability } from 'features/ble';
import styled from 'styled-components';
import { FirmwareFileAlert } from '../../../domain/firmware-update/components/FirmwareAlert';
import { setVariant } from '../../../domain/firmware-update/components/FirmwareAlert';

const Container = styled.div`
  border-radius: 30px;
`;

function BleAvailabilityAlert(props) {
    const {
        isAvailable,
        isLoading: isBleAvailabilityLoading,
        message: bleAvailabilityMsg,
    } = useBluetoothAvailability();
    const bleAvailabilityVariant = isAvailable ? 'success' : 'warning';

    return (
        <Alert
            isLoading={isBleAvailabilityLoading}
            variant={bleAvailabilityVariant}
        >
            {bleAvailabilityMsg}
        </Alert>
    );
}

function BleConnectionAlert({ connectionResult, ...optionals }) {
    const { isLoading, connectedBLEDevice, isError } = connectionResult;
    if (!connectionResult) {
        return null;
    }

    const connectionVariant = setVariant(connectedBLEDevice, isLoading, isError);
    const setText = (connectedBLEDevice, isLoading, isError) => {
        if (connectedBLEDevice && !isLoading && !isError) {
            return `Connected`;
        }
        if (isError) {
            return 'Failed';
        }
        if (isLoading) {
            return 'Connecting...';
        }
    };

    const message = setText(connectedBLEDevice, isLoading, isError);

    return (
        <Alert isLoading={isLoading} variant={connectionVariant}>
            {message}
        </Alert>
    );
}

/**
 * display BLE operation information (current & history)
 */
export function ActivityStatus({
    bleStatus,
    shouldFetchFirmware,
    shouldUpdate,
    shouldConnect,
    isUpdateFinished,
    pairedDevice,
    connectionResult,
    selectedFirmware,
    isConnected,
    ...optionals
}) {

    return (
        <Container>
            {(!(connectionResult.isLoading) && (!isConnected)) &&
                <BleAvailabilityAlert />
            }
            {shouldConnect && !shouldFetchFirmware && (
                <BleConnectionAlert connectionResult={connectionResult} />
            )}
            {!isUpdateFinished &&
                <FirmwareFileAlert
                    show={shouldFetchFirmware}
                    firmwareVersion={selectedFirmware}
                />
            }
            {(isUpdateFinished) &&
                <Alert variant={'success'} defaultShow={isUpdateFinished}>
                    Successfully Updated!
                </Alert>
            }
        </Container>
    );
}

export default ActivityStatus;
