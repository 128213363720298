import axios from 'axios';
import { inspect } from 'utils';
import { BASE_API } from '../constants';
import { wrapResponseErrorHandler } from '../processor';
import { processSubmittedKaiduDeviceConfig } from './pre-request';
import { KaiduDeviceConfiguration } from './types';

const FOO = '/kaidu_device_configuration';
export const DEVICE_CONFIG_API = `${BASE_API}${FOO}`; // return configurations of the default customer
export const DEVICE_CONFIG_CUSTOMER_API = `${DEVICE_CONFIG_API}/customer`;
export const DEVICE_CONFIG_ALL_API = `${DEVICE_CONFIG_API}/all`;

export async function putKaiduConfig(
  data: KaiduDeviceConfiguration
): Promise<any> {
  console.log(
    `putKaiduConfig (old API) is called with input: ${inspect(data)}`
  );
  const { mac_address } = data || {};
  const url = `${DEVICE_CONFIG_API}/${encodeURIComponent(mac_address)}`;
  const processed = processSubmittedKaiduDeviceConfig(data);

  console.log(`putKaiduConfig with data: ${inspect(processed)}`);
  return await wrapResponseErrorHandler(() => axios.put(url, processed));
}