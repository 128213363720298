export type KaiduConfigurationsListItem = {
  id: string;
  kaidu_configuration_id: string;
  kaidu_device_id: string;
  mqtt_configuration_id: string;
  customer_list_id: string;
  customer_device_data_id: string;
};

export const CONFIGURATION_PROPS = [
  'id',
  'kaidu_configuration_id',
  'kaidu_device_id',
  'mqtt_configuration_id',
  'customer_list_id',
  'customer_device_data_id',
];

// {
//   "id": "8282acb8-a878-4c2b-a020-2926b0c461cb",
//   "kaidu_configuration_id": "a81bd1a1-7120-4196-8ffd-a8e662998c02",
//   "kaidu_device_id": "657982b5-80d2-49a7-85d2-e56f8ec0aa32",
//   "mqtt_configuration_id": "4b9c8aed-fac7-4718-a357-907bd59fefc0",
//   "customer_list_id": "647b9112-8305-4ef8-9143-ab63c8f7751f",
//   "customer_device_data_id": "3d1157e5-7a32-49e9-9fdc-0d930eace4ab"
// }
