import axios from "axios";
import _ from "lodash";

import { BASE_API } from "lib/constants";
import { handle } from "utils";
import { getErrorMessageInResponse } from "../processor";
import { KaiduDevicesListItem } from "./types";

const FOO = "/kaidu_devices_list";
export const KAIDU_DEVICES_LIST_API = `${BASE_API}${FOO}`;

function processKaiduDeviceListItem(data) {
  // console.log(`preprocessed KaiduDeviceListItem: ${helpers.inspect(data)}`);

  const result = _.pick(data, [
    "id",
    "kaidu_device_id",
    "mac_address",
    "device_name",
    "hw_sw_version",
    "kaidu_device_status",
    "kaidu_firmware_id",
    "last_seen_timestamp",
  ]);
  return result;
}

//  /kaidu_devices_list
export async function postKaiduDevicesList(data) {
  console.log(`postKaiduConfig with data: `, data);
  const [result, resultErr] = await handle(
    axios.post(KAIDU_DEVICES_LIST_API, data)
  );

  if (resultErr) {
    console.error(resultErr);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return result;
}

export async function putKaiduDevicesList(data) {
  // console.log(`putKaiduDevicesList with data: ${inspect(data)}`);
  // console.log('putKaiduDevicesList with data:', data);
  const url = `${KAIDU_DEVICES_LIST_API}/${encodeURIComponent(
    data?.mac_address
  )}`;
  // console.log(`url: ${url}`);
  const submitted = processKaiduDeviceListItem(data);
  // throw new Error('fuck');

  const [result, resultErr] = await handle(axios.put(url, submitted));

  if (resultErr) {
    console.error(resultErr?.message);
    throw new Error(getErrorMessageInResponse(resultErr));
  }
  return result;
}

export async function fetchKaiduDevicesListItem(mac: string) {
  console.log(`fetchKaiduDevicesListItem with mac: ${mac}`);
  const url = `${KAIDU_DEVICES_LIST_API}/${encodeURIComponent(mac)}`;
  const { data } = (await axios.get(url)) || {};
  return data;
}

// export async function deleteKaiduDevice(mac: string) {
//   console.debug(`deleteKaiduDevice: ${mac}`);
//   const url = `${KAIDU_DEVICES_LIST_API}/${encodeURIComponent(mac)}`;
//   // throw new Error(`test deleteKaiduDevice: ${mac}`);
//   const { data } = (await sendDelete(url)) || {};
//   return data;
// }

export async function fetchKaiduDevicesList(): Promise<KaiduDevicesListItem[]> {
  return await axios.get(KAIDU_DEVICES_LIST_API);
}
