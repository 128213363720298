import { IoTDeviceState } from './types';


export function useIoTCloudStatus() {

  return {
    scannerIoTStates: [],
    isLoading: false,
    isError: null,
  };
}

export function useSingleDeviceIoTCloudStatus(id: string) {
  //TODO: move this function to the server
  const { scannerIoTStates, isLoading, isError } = useIoTCloudStatus();

  const ioTStates: IoTDeviceState | undefined =
    scannerIoTStates && id
      ? scannerIoTStates?.find((item) => item?.id === id)
      : null;
  const notFoundError =
    ioTStates === undefined
      ? new Error(
          `Could not find the device ${id} in the IoT device status list`
        )
      : null;

  return {
    ioTStates,
    isLoading,
    isError: isError || notFoundError,
  };
}