import useSWR from "swr";

import { helpers } from "utils";
import { DEVICE_CONFIG_ALL_API, DEVICE_CONFIG_API } from "../api";
import { fetchConfigurationByMAC } from "../fetch";
import { KaiduDeviceConfiguration } from "../types";
const { swrFetcher: simpleFetcher } = helpers;

/**
 *
 * @param mounted
 * @returns
 */
export function useAllKaiduConfig(
  mounted = true,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const { data, error } = useSWR(
    mounted ? DEVICE_CONFIG_ALL_API : null,
    () => simpleFetcher(DEVICE_CONFIG_ALL_API),
    options
  );
  return {
    allConfig: data as KaiduDeviceConfiguration[],
    isLoading: !error && !data,
    isError: error,
    key: DEVICE_CONFIG_ALL_API,
  };
}

export function useKaiduConfigList(
  mounted = true,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const { data, error } = useSWR(
    mounted ? DEVICE_CONFIG_API : null,
    () => simpleFetcher(DEVICE_CONFIG_API),
    options
  );
  return {
    allConfig: data as KaiduDeviceConfiguration[],
    isLoading: !error && !data,
    isError: error,
  };
}

export function useKaiduConfigByMac(
  mac: string,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const { data, error } = useSWR(
    mac ? mac : null,
    () => fetchConfigurationByMAC(mac),
    options
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}
