import { useState } from 'react';
import styled from 'styled-components';
import PlacesAutocomplete from 'react-places-autocomplete';

const Input = styled.input`
  width: 100%;
  height: 1.2rem;
  margin-top: 0;
  padding: 0.5em;
  border: 0;
  border-bottom: 2px solid gray;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;

  &:focus {
    border-bottom: 4px solid black;
  }
`;

const AutocompleteContainer = styled.div`
  position: relative;
`;

const AutocompleteInput = styled(Input)`
  width: 100%;
`;

const AutocompleteSuggestions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: none;
`;

const AutocompleteSuggestion = styled.div`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export function GoogleAutocomplete({
  placeholder,
  searchOptions,
  name,
  setValue,
  onSelect,
  onChange,
  value,
}) {
  const [googleAddress, setGoogleAddress] = useState('');
  const handleSelect = async (address) => {
    // setValue(name, address);
    setGoogleAddress(address);
    onSelect && onSelect(address);
  };

  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <AutocompleteContainer>
          <AutocompleteInput
            {...getInputProps({
              placeholder: placeholder || '',
              className: 'location-search-input',
              autoComplete: 'off',
              required: true,
            })}
          />
          {suggestions.length > 0 && (
            <AutocompleteSuggestions>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => (
                <AutocompleteSuggestion
                  {...getSuggestionItemProps(suggestion)}
                  key={suggestion.placeId}
                >
                  {suggestion.description}
                </AutocompleteSuggestion>
              ))}
            </AutocompleteSuggestions>
          )}
        </AutocompleteContainer>
      )}
    </PlacesAutocomplete>
  );
}

export default GoogleAutocomplete;
