import {Icon} from 'components/atomic/Icon';
import MuiChip from '@mui/material/Chip';
import MuiStack from '@mui/material/Stack';

function BaseIcon({ name, ...optionals }) {
  return <Icon name={name} color='white' />;
}

/**
 * Chip
 */
export function Chip({ label, color, ...optionals }: {[x: string]: any}) {
  return (
    <MuiStack direction="row" spacing={1} {...optionals}>
      <MuiChip label={label} color={color} />
    </MuiStack>
  );
}
