import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppState, AppThunk } from '../../../../providers/redux/store';
import { ScannerForSelect } from '../../../../types/interfaces';
import produce from 'immer';
import { helpers, inspect } from '../../../../utils';
import {Size} from 'types';

const INIT_SIZE: Size = {
  width: 0,
  height: 0,
}

const initialState = {
  addedScanners: [] as ScannerForSelect[],
  unselectedScanners: [] as ScannerForSelect[],
  previewDimensions: INIT_SIZE,
};

//Mock
// const initialState = {
//   addedScanners: [
//     { device_name: 'DevName1', mqtt_device_id: 'devId1', x: 150, y: 10 },
//     { device_name: 'DevName2', mqtt_device_id: 'devId2', x: 10, y: 10 },
//     { device_name: 'DevName3', mqtt_device_id: 'devId3', x: 280, y: 10 },
//     { device_name: 'DevName4', mqtt_device_id: 'devId4', x: 400, y: 10 },
//   ] as ScannerForSelect[],
// };

export const floorplanSlice = createSlice({
  name: 'floorplan',
  initialState,
  reducers: {
    addScanner(state, action) {
      console.log(`addScanner was dispatched`);
      const scanner = action.payload;

      // assign default x, y values
      scanner.x = scanner?.x ?? 0;
      scanner.y = scanner?.y ?? 0;
      console.log(`added scanner: ${helpers.inspect(scanner)}`);

      //update addedScanners
      state.addedScanners = produce(state.addedScanners, (draftState) => {
        draftState.push(action.payload);
      });

      // remove the added scanner from unselectedScanners
      if (state.unselectedScanners.length < 1) {
        return;
      }
      const addedDeviceName = scanner.device_name;
      console.log(`addedDeviceName: ${addedDeviceName}`);
      const removedArr = state?.unselectedScanners?.filter(
        (item) => item.device_name !== addedDeviceName
      );
      console.log(`removedArr length: ${removedArr.length}`);

      state.unselectedScanners = removedArr;
      console.log(`addScanner finished`);
    },
    removeScanner(state, action) {
      const index = action.payload;
      if (index > -1) {
        const removed = state.addedScanners[index];
        state.addedScanners = produce(state.addedScanners, (draftState) => {
          draftState.splice(index, 1);
        });
        state.unselectedScanners = produce(
          state.unselectedScanners,
          (draftState) => {
            draftState.push(removed);
          }
        );
      }
    },
    moveScanner(state, action) {
      const { id, x, y } = action.payload;
      const items = state.addedScanners;
      const itemIndex = items.findIndex((item) => id === item.mqtt_device_id);
      // console.log(`Moved box index: ${itemIndex}`);
      const newItem = Object.assign(items[itemIndex], { x, y });
      // console.log(`newItem: ${helpers.inspect(newItem)}`);
      const newArray = [...items];
      newArray[itemIndex] = newItem;
      state.addedScanners = newArray;
    },
    updateAddedScanner(state, action) {
      state.addedScanners = action.payload;
    },
    setUnselectedScanners(state, action) {
      state.unselectedScanners = action.payload;
    },
    setPreviewDimensions(state, action) {
      console.debug(`setPreviewDimensions: ${inspect(action.payload)}`);
      state.previewDimensions = action.payload;
    },
    clearAddedScanners(state) {
      state.addedScanners = [];
    }
  },
});

export default floorplanSlice.reducer;

export const {
  addScanner,
  removeScanner,
  moveScanner,
  updateAddedScanner,
  setUnselectedScanners,
  setPreviewDimensions,
  clearAddedScanners,
} = floorplanSlice.actions;

export const selectAddedScanners = (state: AppState) => {
  // console.log(`state: ${helpers.inspect(state[floorplanSlice.name])}`);
  return state[floorplanSlice.name].addedScanners;
};
export const selectScanner = (state: AppState) => {
  //TODO:
  return;
};
export const selectUnselectedScanners = (state: AppState) =>
  state[floorplanSlice.name].unselectedScanners;
export const selectPreviewDimensions = (state: AppState) => state[floorplanSlice.name].previewDimensions;