import useSWR from "swr";
import { fetchFirmWare } from "./fetchers";

export function useFirmwareFile(
  version?: string,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  // console.log(`useFirmwareFile: ${version}`);
  const { data, error } = useSWR(
    version ? ["firmwareFile", version] : null,
    () => fetchFirmWare(version),
    options
  );

  return {
    data,
    isReady: !!data,
    isLoading: !error && !data,
    isError: error,
  };
}
