import axios from "axios";
import { helpers } from "utils";
import { handle } from "utils/helper";
import { getErrorMessageInResponse, handleAxiosResultData } from "../processor";
import { CUSTOMERS_LIST_API, CUSTOMER_ITEM_ID_API } from "./api";
import { CustomersListItem } from "./types";

/**
 * @description fetch the customer list from server
 * @returns Promise
 */

// Function to fetch the superAdmin customer list
export async function fetchCustomersList(
  accessToken?: string,
  isSuperAdmin?: boolean
): Promise<CustomersListItem[]> {
  const [result, resultErr] = await handle(
    axios.get(CUSTOMERS_LIST_API, { 
      ...helpers.createAxiosTokenConfig(accessToken), 
      params: { 
        isSuperAdmin: isSuperAdmin 
      }
    })
  );

  if (!result) {
    console.error(`fetchCustomersList failed`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  return handleAxiosResultData(result);
}

export const fetchCustomer = async (
  customerId: string
): Promise<CustomersListItem> => {
  if (!customerId) return null;
  return await axios
    .get(`${CUSTOMER_ITEM_ID_API}/${customerId}`)
    .then((res) => res.data);
};

export async function fetchCustomerByName(
  name: string
): Promise<CustomersListItem> {
  const response = await axios.get(
    `${CUSTOMERS_LIST_API}/name/${encodeURIComponent(name)}`
  );

  return handleAxiosResultData(response);
}
