// import React, { useState } from "react";
// import {Icon} from "components/atomic/Icon";

export default function CheckboxIcon({ onChange, checked, ...optionals }) {

  const handleChange = () => {
    const newValue = !checked;
    // setChecked(newValue);
    onChange && onChange(newValue);
  };

  // return <button onClick={handleChange}>{checked ? <Icon name='check-square' /> : <Icon name='FaRegSquare' />}</button>
  return <input type="checkbox" checked={checked} onChange={handleChange} />;
}
