export type KaiduDevicesListItem = {
  id: string;
  kaidu_device_id: string;
  mac_address: string;
  device_name: string;
  hw_sw_version: string;
  kaidu_device_status: number;
  kaidu_firmware_id: string;
  last_seen_timestamp: string;
  slave_firmware_id?: string;
  slave_firmware_version?: string;
  partner_customer_id?: string;
  [x: string]: any;
};

export const UpdateKaiduDevicesListItemProps = [
  "id",
  "kaidu_device_id",
  "mac_address",
  "device_name",
  "hw_sw_version",
  "kaidu_device_status",
  "kaidu_firmware_id",
  "last_seen_timestamp",
  "source_id",
  "rssi_threshold_value",
  "partner_customer_id",
];
