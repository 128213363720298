import _ from "lodash";
import { replaceEmptyStringWithNull, trimObjectProperties } from "utils";
import { NULL_VALUE } from "../constants";
import { CONFIGURATION_PROPS } from "./types";

export function processKaiduConfigListSubmission(data) {
  const trimmed = trimObjectProperties(data);
  const replaced = replaceEmptyStringWithNull(trimmed);
  const result = _.pick(replaced, CONFIGURATION_PROPS) as any;
  if (!result.mqtt_configuration_id) result.mqtt_configuration_id = NULL_VALUE;
  return result;
}

/**
 * @description reset the customer_device_data_id
 * @param data
 * @returns
 */
export function resetCustomersDeviceDataId(data) {
  const result = { ...data, customer_device_data_id: NULL_VALUE };
  return result;
}
