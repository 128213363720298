import {
  CredentialResponse,
  GoogleLogin as GoogleLoginBtn,
} from "@react-oauth/google";
import axios from "axios";
import { BASE_API } from "features/kaidu-config-server";
import { useHistory } from "react-router-dom";

import {
  AuthProvider,
  LOGIN_ERROR_MESSAGE,
  UNREGISTERED_USER_ERROR_MESSAGE,
} from "../lib";
import { LoginAuthPayload } from "../types";

export function GoogleLogin({ onError }) {
  // Hooks
  const history = useHistory();

  const handleGoogleResponse = (response: CredentialResponse) => {
    const { credential } = response;

    const loginPayload: LoginAuthPayload = {
      provider: AuthProvider.GOOGLE,
      token: credential,
    };

    axios
      .post(`${BASE_API}/auth/login`, loginPayload)
      .then((res) => {
        const hasToken = res?.data && res.data.jwt;
        if (!hasToken) {
          console.log("No token!");
          onError(UNREGISTERED_USER_ERROR_MESSAGE);
        } else {
          history.push(`/login/success?token=${res.data.jwt}`);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        onError(LOGIN_ERROR_MESSAGE);
      });
  };

  return (
    <>
      <GoogleLoginBtn
        containerProps={{ className: "w-full" }}
        onSuccess={handleGoogleResponse}
        onError={() => {
          onError(
            "Failed to login with Google. Please complete the Google login process to continue."
          );
        }}
      />
    </>
  );
}
