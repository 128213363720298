import React from "react";
import Button from "components/atomic/Button";
import Select from "components/molecule/Select";
import { useKaiduFirmwareList, extractFirmwareUrls } from "features/kaidu-config-server/kaidu-firmware-list";
import { useForm } from "react-hook-form";

export function HeaderBtnGroup({ onUpdateSelected, ...rest }) {
  const { firmwareList } = useKaiduFirmwareList();
  const { register, handleSubmit } = useForm();

  return (
    <div className="absolute -top-8 right-0 flex">
      <Button onClick={handleSubmit(onUpdateSelected)} className="mr-3">
        Update
      </Button>
      <Select
        showAll={false}
        options={extractFirmwareUrls(firmwareList) ?? []}
        register={register}
        name="version" />
    </div>
  );
}
