import axios from "axios";

export function setAxiosDefault(
  token: string,
  partner_customer_id?: string,
  isSuperAdmin?: boolean
) {
  // console.debug(`setting axios default header with token: ${token}`);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  // Axios default timeout time
  if (partner_customer_id) {
    axios.defaults.params = { partner_customer_id };
  }
  if (isSuperAdmin) {
    axios.defaults.params = { isSuperAdmin: true };
  }
}

export function getAxiosDefaultHeader() {
  return axios.defaults.headers;
}

// return the value in Authorization header
export function getAxiosAuthToken(): string {
  const header = getAxiosDefaultHeader();
  const authString = header.common["Authorization"] as string;
  const result = authString
    ? authString.replace("Bearer", "").trim()
    : authString;
  return result;
}

export function addAxiosInterceptor(errorCallback: Function) {
  return axios.interceptors.response.use(
    function (response) {
      // console.log(response.request._url);
      // console.log(response?.headers);
      // console.log(response?.request?._headers?.authorization);
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // TODO: if it is invalid token error
      errorCallback(error);
      return Promise.reject(error);
    }
  );
}
