import Icon from 'components/atomic/Icon';
import styled from 'styled-components';

const StyledIcon = styled(Icon)`
  font-size: 24px;
  &:hover {
    cursor: pointer;
  }
`;

export function OperationIcon({ name, ...optionals }) {
  //
  const { detailText, onClick, ...rest } = optionals;
  return (
    <>
      <StyledIcon name={name} onClick={onClick} {...rest} />
    </>
  );
}
