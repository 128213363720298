import _ from "lodash";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSWRConfig } from "swr";

import Toast from "components/atomic/Toast";
import { KAIDU_CONFIG_MUTATE_KEYS } from "features/kaidu-config-server/composition";
import { EditModalContent } from "./EditModalContent";
import { updatePlugConfigWithCompositeAPIs } from "./writer";

/**
 * mutate config of device
 */
export function EditModalView({
  show,
  onHide,
  title,
  mac,
  serverConfig,
  data,
  optionLists,
  ...optionals
}) {
  // Props
  const { ...rest } = optionals;

  //hooks
  const { mutate } = useSWRConfig();

  // States
  const [formStatus, setFormStatus] = useState("idle");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (formStatus === "resolved") {
      setTimeout(() => {
        setFormStatus("idle");
      }, 1000);
      onHide();
    }
  }, [formStatus]);

  // save changes to Kaidu config server, depends on existing config, existing deviceListItem
  const submitData = async (data, serverConfig) => {
    try {
      // return;
      if (!_.isEmpty(data)) {
        // return;
        if (data.kaidu_device_status) {
          data.kaidu_device_status = Number(data.kaidu_device_status);
        }
        const isCustomerChanged =
          serverConfig.customer_id &&
          serverConfig.customer_id !== data.customer_id;
        const shouldUpdateKaiduDevicesList =
          serverConfig.device_name !== data.device_name ||
          serverConfig.kaidu_device_status !== data.kaidu_device_status ||
          serverConfig.partner_customer_id !== data.next_partner_customer_id ||
          data.rssi_threshold_value !== undefined;
        const combined = {
          ...serverConfig,
          ...data,
          isCustomerChanged,
          shouldUpdateKaiduDevicesList,
        };
        await updatePlugConfigWithCompositeAPIs(combined);
        // await updateConfigurationWithKaiduDeviceConfigurationAPI(combined);
      }

      //mutate
      KAIDU_CONFIG_MUTATE_KEYS.forEach((key) => {
        mutate(key);
      });

      setFormStatus("resolved");
      return;
    } catch (error) {
      // console.error(error?.message);
      setErrorMsg(error?.message);
      setFormStatus("rejected");
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered size="xl" {...rest}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <EditModalContent
          mac={mac}
          onHide={onHide}
          onSubmit={submitData}
          data={serverConfig}
          optionLists={optionLists}
        />
      </Modal>
      {formStatus === "resolved" && (
        <Toast
          title="Success"
          variant="success"
          text="Updated config data was sent to the server"
        />
      )}
      {formStatus === "rejected" && (
        <Toast title="Error" variant="danger" text={errorMsg} />
      )}
    </>
  );
}
