import { useState } from "react";
import { useController } from "react-hook-form";
import { useTheme } from "styled-components";

import { ModalH2 } from "components/atomic/Headings";
import Icon from "components/atomic/Icon";
import Input from "components/atomic/Input";

/**
 * Editable Device Name
 */
export function DeviceName({ control, ...optionals }) {
  // Hooks
  const theme = useTheme();
  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController({
    name: "device_name",
    control,
    // rules: { required: true },
    defaultValue: "",
  });

  const [isEditing, setIsEditing] = useState(false);

  const handleClick = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className={`flex items-center ${isEditing ? "mb-3" : ""}`}>
      {isEditing ? (
        <Input
          className="mr-2 text-2xl w-fit"
          value={value}
          onChange={onChange}
        />
      ) : (
        <ModalH2 className="mr-2">{value}</ModalH2>
      )}
      <Icon
        name={isEditing ? "check" : "edit"}
        color={isEditing ? theme.palette.primary : "gray"}
        onClick={handleClick}
        size="lg"
        className={isEditing ? "" : "mb-3"}
      />
    </div>
  );
}
