import { BASE_API } from '../constants';
import { handle } from 'utils';
import { sendPut, sendDelete } from '../handler';
import { FloorplanData, Zone } from './types';
import {NIL as NIL_UUID} from 'uuid';

const FOO = '/customers_list';
export const ADD_FLOOR_PLAN_SUBPATH = `/addFloorplan`;
export const CUSTOMERS_LIST_API = `${BASE_API}${FOO}`;
export const CUSTOMER_ITEM_ID_API = `${CUSTOMERS_LIST_API}/id`;
export const CUSTOMER_ITEM_NAME_API = `${CUSTOMERS_LIST_API}/name`;

export async function addFloorPlan(customerId: string, json: FloorplanData) {
  if (!customerId) {
    throw new Error('Invalid input. No customer ID');
  }

  const url = `${CUSTOMERS_LIST_API}/${encodeURIComponent(
    customerId
  )}${ADD_FLOOR_PLAN_SUBPATH}`;
  console.log(`url: ${url}`);
  // console.log(`json: ${inspect(json)}`);
  const [result, resultErr] = await handle(sendPut(url, json));
  if (resultErr) {
    throw resultErr;
  }
  return result;
}

/**
 * 
 * @param customerID 
 * @param floorplanID 
 * @returns 
 */
export async function removeFloorplan(customerID: string, floorplanID: string) {
  if (!customerID) {
    throw new Error('Invalid input. No customer ID');
  }

  const url = `${CUSTOMERS_LIST_API}/${encodeURIComponent(customerID)}/floorplan/${encodeURIComponent(floorplanID)}`;
  console.log(`url: ${url}`);
  const [result, resultErr] = await handle(sendDelete(url));
  if (resultErr) {
    throw resultErr;
  }
  return result;
}


export async function updateDefaultFloorplan(customerId: string, zones: Zone[]) {
  console.log('updateDefaultFloorplan zones: ', zones);
  const floorplan = {
    name: 'default',
    building: null,
    customers_floor_id: NIL_UUID,
    zones,
  };

  const res = await addFloorPlan(customerId, floorplan);
  return res;
}