import {KaiduDeviceStatus} from './types';

export function convertKaiduDeviceStatusNumToString(
  num: number | string
): KaiduDeviceStatus {

  let myNum = num;
  if (typeof num === 'string') {
    myNum = parseInt(num);
  }

  switch (myNum) {
    case 0:
      return 'NEW';
    case 1:
      return 'ACTIVATED';
    case 2:
      return 'CONFIGURED';
    case 3:
      return 'ONLINE';
    case 4:
      return 'OFFLINE';
    case 5:
      return 'DEACTIVATED';
    case 6:
      return 'BROKEN';
    default:
      return 'NEW';
  }
}