import { isFilledArray } from "utils";
import { KaiduConfigurationsListItem } from "./types";

export function findAllByKaiduDeviceId(
  list: KaiduConfigurationsListItem[],
  id: string
): KaiduConfigurationsListItem[] {
  const foundConfig = list?.filter((item) => item?.kaidu_device_id === id);
  return foundConfig;
}

/**
 * @description if there are more than one items, return the first valid one
 * @param  {KaiduConfigurationsListItem[]} list
 * @param  {string} id
 * @returns KaiduConfigurationsListItem
 */
export function findByKaiduDeviceId(
  list: KaiduConfigurationsListItem[],
  id: string
): KaiduConfigurationsListItem {
  if (isFilledArray(list)) {
    const foundList = findAllByKaiduDeviceId(list, id);
    if (isFilledArray(foundList)) {
      return foundList[0];
    }
  }

  return null;
}

export function findByCustomerId(
  list: KaiduConfigurationsListItem[],
  id: string
): KaiduConfigurationsListItem {
  if (isFilledArray(list)) {
    if (isFilledArray(list)) {
      const foundConfig = list?.find((item) => item?.customer_list_id === id);
      return foundConfig;
    }
  }

  return null;
}
