import { createSlice } from "@reduxjs/toolkit";
import type { AppState } from "./store";

const initialState = {
  selected: {
    customer: "all",
    building: "all",
    floor: "all",
    location: "all",
  },
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setSelectedCustomer(state, action) {
      state.selected.customer = action.payload;
    },
  },
});

export default filterSlice.reducer;

export const { setSelectedCustomer } = filterSlice.actions;

export const selectSelectedCustomer = (state: AppState) =>
  state[filterSlice.name].selected.customer;
