import {
  KAIDU_DEVICE_SERVICE_UUIDS,
  KAIDU_DEVICE_CHARACTERISTICS_UUIDS,
} from '../../../lib/ble/kaidu-device/constants';
import { MOKO_PRIMARY_SERVICE_UUID } from '../../../lib/ble/moko-device/constants';
import {
  getTotalChunks,
  sliceIntoChunks,
  getPercentage,
} from '../../../lib/data-operations';
import { ServerFetcher } from '../../../lib/kaidu-server';
import { KAIDU_FIRMWARE_CHUNK_SIZE as CHUNK_SIZE } from '../../../lib/constants';
import { helpers, inspect } from 'utils';

declare namespace navigator {
  let bluetooth: any;
}

const KAIDU_SERVICES_ARRAY = Object.values(KAIDU_DEVICE_SERVICE_UUIDS);

// BLE scanning filters
const bleFilters = [
  { namePrefix: 'KaiduScanner' },
  { services: KAIDU_SERVICES_ARRAY },
  // { namePrefix: 'MK' },
];

/**
 * @description request BLE devices with Kaidu and Moko primary services
 * @returns {Promise} device
 */
export async function requestBLEDevices() {
  const device = await navigator?.bluetooth.requestDevice({
    // acceptAllDevices: true,
    filters: bleFilters,
    optionalServices: [
      'battery_service',
      'device_information',
      ...KAIDU_SERVICES_ARRAY,
      MOKO_PRIMARY_SERVICE_UUID,
    ],
  });
  return device;
}

export async function requestBLEDevicesFilter(filter) {
  const device = await navigator?.bluetooth.requestDevice({
    // acceptAllDevices: true,
    filters: filter,
    optionalServices: [
      'battery_service',
      'device_information',
      ...KAIDU_SERVICES_ARRAY,
      MOKO_PRIMARY_SERVICE_UUID,
    ],
  });
  return device;
}





/**
 * @description connect to the given device
 */
export async function connect(device) {
  if (!device) {
    return null;
  }
  console.log(`Device: `, inspect(device));
  return await device.gatt.connect();
}

export async function fetchFirmwareFile(
  versionStr?: string
): Promise<ArrayBuffer> {
  let version;
  if (versionStr) {
    version = versionStr;
  } else {
    //fetch latest compatible firmware version
  }

  //fetch firmware file
  const firmware = await ServerFetcher.fetchFirmWare(version);
  console.log('Retrieved firmware file');

  return firmware;
}

/**
 * 
 * @returns the OTA characteristic on Kaidu Scanner
 */
export async function getOTAChar(bluetoothRemoteGATTServer: BluetoothRemoteGATTServer | null) {
  if (bluetoothRemoteGATTServer) {
    // connect device if it's not
    !bluetoothRemoteGATTServer.connected &&
      (await bluetoothRemoteGATTServer.connect());
  }

  const service = await bluetoothRemoteGATTServer.getPrimaryService(
    KAIDU_DEVICE_SERVICE_UUIDS.OTA
  );

  const characteristic = await service.getCharacteristic(
    KAIDU_DEVICE_CHARACTERISTICS_UUIDS.OTA_FIRMWARE
  );
  return characteristic;
}

export function getFileChunks(firmware: ArrayBuffer) {
  if (!firmware) {
    console.log('No available firmware file found');
    return;
  }

  const firmwareBytes = new Uint8Array(firmware);
  const firmwareChunks = sliceIntoChunks(firmwareBytes, CHUNK_SIZE);
  const result = {
    chunks: firmwareChunks,
    totalChunks: firmwareChunks?.length,
  };
  return result;
  // const file = document.getElementById('file').files[0];
  // if (!file) {
  //   console.log('No available firmware file found');
  //   return;
  // }
  // const totalChunks = getTotalChunks(file, CHUNK_SIZE);
  // console.log(`Total chunks: ${totalChunks}`);

  // const firmwareBytes = new Uint8Array(firmware);
  // const firmwareChunks = sliceIntoChunks(firmwareBytes, CHUNK_SIZE);

  // return { firmwareChunks, totalChunks };
}

export async function preprocessFirmwareUpdate(
  firmware: ArrayBuffer,
  bluetoothRemoteGATTServer: BluetoothRemoteGATTServer | null
) {
  // Input validation
  if (!firmware) {
    console.log('No available firmware file found');
    return;
  }
  if (!bluetoothRemoteGATTServer) {
    console.log('Bad bluetoothRemoteGATTServer');
    return;
  }

  const {chunks: firmwareChunks} = getFileChunks(firmware);

  //start notification, add event listener
  // let otaListenerErrorCount = 0;
  // const otaListener = (err, char) => {
  //   console.log(`OTA listener is called`);
  //   if (err) {
  //     otaListenerErrorCount++;
  //     if (otaListenerErrorCount > 1) {
  //       alert(`OTA Listener error ${err.message}`);
  //     }
  //   }
  // };

  const characteristic = await getOTAChar(bluetoothRemoteGATTServer)

  return { firmwareChunks, characteristic };
}

/* SendFileOverBluetooth(data)
 * Figures out how large our update binary is, attaches an eventListener to our dataCharacteristic so the Server can tell us when it has finished writing the data to memory
 * Calls SendBufferedData(), which begins a loop of write, wait for ready flag, write, wait for ready flag...
 */
export async function sendFileOverBLE(firmwareChunks, characteristic) {
  try {
    console.log(`OTA starts at ${new Date().toLocaleTimeString()}`);
    //send all the chunks as buffered data
    const totalChunks = firmwareChunks.length;
    let writtenChunk = 0;
    for (let i = 0; i < totalChunks; i++) {
      // perform writing
      await characteristic.writeValueWithResponse(firmwareChunks[i]);
      writtenChunk++;
      const percent = getPercentage(totalChunks, i);
      console.log(`Progress: ${percent}`);
    }

    console.log(`Written Chunks: ${writtenChunk}`);
    console.log(`OTA ends at ${new Date().toLocaleTimeString()}`);
  } catch (err) {
    console.error(err.message);
    alert(`OTA failed ${err.message}`);
  } finally {
    //disconnect
    // bluetoothRemoteGATTServer.disconnect();
    // console.log(`Device is disconnected.`);
  }
}
