import Fuse from "fuse.js";
import { isFilledArray } from "utils";

export function searchThroughRows(term: string, list: any[], keys?: string[]) {
  if (!isFilledArray(list)) {
    return list;
  }
  const options = {
    keys: keys ?? Object.keys(list[0]),
    threshold: 0,
    isCaseSensitive: false,
    includeMatches: true,
    ignoreLocation: true,
  };
  const fuse = new Fuse(list, options);

  //filter input
  if (term) {
    const filtered = fuse.search(term).map((data) => data.item);
    return filtered;
  } else {
    return list;
  }
}
