import { KAIDU_DEVICE_LAST_UPDATE_CMD } from './api';
import useSWR, { SWRResponse } from 'swr';
import { getData } from 'features/axios';
import { isNil } from 'lodash';

export function useFirmwareUpdateResult(mac: string) {
  const url = `${KAIDU_DEVICE_LAST_UPDATE_CMD}/${mac}`;
  const { data, error } = useSWR<any>(mac ? url : null, getData);

  return {
    data,
    error,
    isLoading: isNil(data) && !error,
  };
}
