import usePartnerSession from "hooks/usePartnerSession";
import React from "react";
import styled from "styled-components";

const PopupWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

interface SessionSwitcherProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SessionSwitcher: React.FC<SessionSwitcherProps> = ({ isOpen, onClose }) => {
  const { partners, switchPartner } = usePartnerSession();

  if (!isOpen) return null;

  return (
    <>
      <Overlay onClick={onClose} />
      <PopupWrapper>
        <h2>Session Switcher</h2>
        <div>
          <select
            id="partnerSelect"
            onChange={(e) => switchPartner(e.target.value)}
            defaultValue=""
          >
            <option value="" disabled>
              Choose a partner
            </option>
            {partners.map((partner, index) => (
              <option key={index} value={partner}>
                {partner} 
              </option>
            ))}
          </select>
        </div>
        <button onClick={onClose}>Close</button>
      </PopupWrapper>
    </>
  );
};
