import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppState, AppThunk } from '../../../../providers/redux/store';
import { PURGE } from 'redux-persist';
import { modalInitialState, modalReducers } from 'providers/redux/modal';

const initialState = {
  modal: modalInitialState,
  selectedCustomer: {},
  selectedCustomerForConfig: {},
};

export const customersSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    ...modalReducers,
    setSelectedCustomer(state, action) {
      state.selectedCustomer = action.payload;
    },
    setSelectedCustomerForConfig(state, action) {
      state.selectedCustomerForConfig = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      state = initialState;
    });
  },
});

export default customersSlice.reducer;

export const {
  toggleModal,
  setOpenModal,
  setModalData,
  setToast,
  setOpenToast,
  clearModalData,
  setSelectedCustomer,
} = customersSlice.actions as any;

export const selectModal = (state: AppState) =>
  state[customersSlice.name].modal;
export const selectModalData = (state: AppState) =>
  state[customersSlice.name].modal.data;
export const selectIsModalOpened = (state: AppState) =>
  state[customersSlice.name].modal.isOpened;
export const selectIsToastOpen = (state: AppState) =>
  state[customersSlice.name].modal.toast.isOpen;
export const selectToastContent = (state: AppState) =>
  state[customersSlice.name].modal.toast.content;
export const selectSelectedCustomer = (state: AppState) =>
  state[customersSlice.name].selectedCustomer;
export const selectSelectedCustomerForConfig = (state: AppState) => state[customersSlice.name].selectedCustomerForConfig;