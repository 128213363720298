import { useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

import { ErrorMsg } from "components/atomic/ErrorMsg";
import LoadingSpinner from "components/molecule/LoadingSpinner";
import { selectedDeviceData } from "providers/redux/deviceStatusSlice";
import { useEditModalData } from "../hooks";
import { EditModalView } from "./EditModalView";

/**
 * A modal controller for display and edit Configuration data
 */
export function EditModal({ show, onHide, title, mac, ...optionals }) {
  // Props
  const { ...rest } = optionals;

  // Hooks
  const deviceData = useSelector(selectedDeviceData);
  const { serverConfig, data, isLoading, isError, optionLists } =
    useEditModalData(mac);
  const isDifferentCustomer = useMemo(
    () =>
      (deviceData?.customer_name || "") !== (serverConfig?.customer_name || ""),
    [deviceData, serverConfig]
  );

  console.log({ isLoading, isDifferentCustomer, serverConfig, deviceData });

  // Conditional rendering
  if (isLoading || isDifferentCustomer) {
    return (
      <Modal show={show} onHide={onHide} centered size="xl" {...rest}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <div className={"p-4 max-w-full"}>
          <LoadingSpinner />
        </div>
      </Modal>
    );
  }

  if (isError)
    return (
      <Modal show={show} onHide={onHide} centered size="xl" {...rest}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <div className={"p-4 max-w-full"}>
          <ErrorMsg text={isError?.message} />
        </div>
      </Modal>
    );

  return (
    <>
      <EditModalView
        show={show}
        onHide={onHide}
        title={title}
        mac={mac}
        serverConfig={serverConfig}
        data={data}
        optionLists={optionLists}
        {...rest}
      />
    </>
  );
}
