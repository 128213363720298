import axios from "axios";
import AppleSignin, { AppleAuthResponse } from "react-apple-signin-auth";
import { useHistory } from "react-router-dom";

import { BASE_API } from "lib";
import {
  AuthProvider,
  LOGIN_ERROR_MESSAGE,
  UNREGISTERED_USER_ERROR_MESSAGE,
} from "../lib";
import { LoginAuthPayload } from "../types";

export function AppleLogin({ initProps, onError, ...rest }) {
  const history = useHistory();

  const handleAppleResponse = (response: AppleAuthResponse) => {
    const { authorization, user } = response;
    const loginPayload: LoginAuthPayload = {
      provider: AuthProvider.APPLE,
      token: authorization.id_token,
    };
    if (user) {
      const { email: user_email, name } = user;
      const { firstName, lastName } = name || {};
      loginPayload.user = {
        email: user_email,
        name: `${firstName} ${lastName}`,
      };
    }

    axios
      .post(`${BASE_API}/auth/login`, loginPayload)
      .then((res) => {
        const hasToken = res?.data && res.data.jwt;
        if (!hasToken) {
          console.log("No token!");
          onError(UNREGISTERED_USER_ERROR_MESSAGE);
        } else {
          history.push(`/login/success?token=${res.data.jwt}`);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        onError(LOGIN_ERROR_MESSAGE);
      });
  };

  const handleAppleError = (err) => {
    console.error(err);
    onError(
      "Failed to login with Apple. Please complete the Apple login process to continue."
    );
  };

  return (
    <>
      <div className="mt-4" {...rest}>
        <AppleSignin
          /** Auth options passed to AppleID.auth.init() */

          authOptions={{
            /** Client ID - eg: 'com.example.com' */
            clientId: initProps.clientId, // This is the service ID we created.
            /** Requested scopes, seperated by spaces - eg: 'email name' */
            scope: initProps.scope,
            /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
            redirectURI: initProps.redirectURI,
            /** State string that is returned with the apple response */
            // state: "state",
            /** Nonce */
            // nonce: "nonce",
            /** Uses popup auth instead of redirection */
            usePopup: true,
            // responseMode: "query",
          }} // REQUIRED
          /** General props */
          uiType="light"
          /** className */
          className="w-full px-8"
          /** Removes default style tag */
          noDefaultStyle={false}
          /** Allows to change the button's children, eg: for changing the button text */
          buttonExtraChildren="Sign in with Apple"
          /** Extra controlling props */
          /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
          onSuccess={handleAppleResponse} // default = undefined
          /** Called upon signin error */
          onError={handleAppleError} // default = undefined
          /** Skips loading the apple script if true */
          skipScript={false} // default = undefined
          /** Apple image props */
          iconProps={{ style: { marginRight: "10px" } }} // default = undefined
        /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
        // render={(props) => <button {...props}>My Custom Button</button>}
        // render={(props) => (
        //   <Button
        //     // variant="outlined"
        //     className="w-full"
        //     variant="outline-secondary"
        //     // color=""
        //     // startIcon={<Icon name="FaApple" />}
        //     {...props}
        //   >
        //     <Icon name="FaApple" size="2em" />
        //     Sign in with Apple
        //   </Button>
        // )}
        />
      </div>
    </>
  );
}
