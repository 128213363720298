import { useState } from "react";
import { useSelector } from "react-redux";

import { LogoutDialog } from "components/organism/LogoutDialog";
import { selectLoginData } from "providers/redux/globalSlice";
import { useBreakPoints } from "styles";
import NavTab from "./NavTab";
import { UserProfile } from "./Profile";

export function LogoutNavItem() {
  const userData = useSelector(selectLoginData);
  const { isMobile } = useBreakPoints();

  const [logoutDialog, setLogout] = useState(false);

  const toggleLogoutDialog = () =>
    setLogout((prevState: boolean) => !prevState);

  return (
    <div>
      <div className="w-full">
        {!isMobile && userData?.isLoggedIn && <UserProfile data={userData} />}
        <NavTab
          to="logout"
          icon="sign-out-alt"
          text="Logout"
          isLink={false}
          onClick={toggleLogoutDialog}
        />
      </div>
      <LogoutDialog isOpen={logoutDialog} toggleModal={toggleLogoutDialog} />
    </div>
  );
}
