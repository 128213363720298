import React from 'react';
import { RootFetcher as CustomerView } from 'domain/customers';
import { UserMainView } from 'features/users/components/UserMainView';
import Container from 'components/atomic/Container';
import {H2} from 'components/atomic/Headings';
import { Card, CardContent } from '@mui/material';
import { BLEPage } from 'application/pages/BLEPage';
import { ErrorBoundary } from 'features/error-handling';

export default function Settings(props) {
  return (
    <div>
      <Container className='my-4'>
        <H2>Settings</H2>
        <Card
          className='mt-4'
          sx={{
            borderRadius: '25px',
            boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#fff',
          }}
        >
          <ErrorBoundary>
            <CardContent>
              <BLEPage />
            </CardContent>
          </ErrorBoundary>
        </Card>
      </Container>
        <CustomerView />
        <Container className={'mt-10'}>
          <UserMainView />
        </Container>
    </div>
  );
}
