import { useEffect } from "react";

import ErrorMsg from "components/atomic/ErrorMsg";
import { ControlledLabelledSwitch } from "components/molecule/LabelledSwitch";
import LoadingSpinner from "components/molecule/LoadingSpinner";
import {
  FormLabel,
  FullField,
  Input,
} from "domain/customers/components/atomic/ClientInformationModalStyles";
import { HoursOfOperationInput } from "domain/customers/components/modal/HoursOfOperationInput";
import { CustomerTypeSelector } from "domain/customers/customer-type";
import { getFormCustomerListItemFromFetched } from "domain/customers/lib";
import { TimezoneInputGroup } from "domain/customers/time-zone";
import { useCustomersListById } from "features/kaidu-config-server";

export function CustomerConfigSection({ form, customerId, ...optionals }) {
  const { control, register, setValue } = form;
  const { className, setInitialCustomerConfig } = optionals;
  const {
    customer,
    isLoading,
    isError: error,
  } = useCustomersListById(customerId);

  useEffect(() => {
    if (customer) {
      const formValues = getFormCustomerListItemFromFetched(customer);
      const {
        passer_by_interval,
        customer_type,
        realtime_flag,
        start,
        end,
        timezone,
        customer_name,
        partner_customer_id,
      } = formValues;

      setValue("passer_by_interval", passer_by_interval);
      setValue("customer_type", customer_type);
      setValue("start", start);
      setValue("end", end);
      setValue("timezone", timezone);
      setValue("realtime_flag", realtime_flag);
      setValue("customer_name", customer_name);
      setValue("customer_name", customer_name);
      setValue("partner_customer_id", partner_customer_id);
      setInitialCustomerConfig({
        passer_by_interval,
        customer_type,
        realtime_flag,
        start,
        end,
        timezone,
        customer_name,
      });
    }
  }, [customer, setValue, setInitialCustomerConfig]);

  if (isLoading) {
    return (
      <section>
        <LoadingSpinner />
      </section>
    );
  }

  if (error) {
    return (
      <section>
        <ErrorMsg
          text={`Failed to load customer config data. ${error.message}`}
        />
      </section>
    );
  }

  return customer ? (
    <section>
      <div className={className}>
        <div className="flex-auto">
          <div className={"w-full"}>
            <TimezoneInputGroup control={control} />
          </div>
          <FullField>
            <HoursOfOperationInput control={control} setValue={setValue} />
          </FullField>
        </div>
        <div className="flex-auto">
          <FullField className="flex items-center">
            <div className="flex-1">
              <ControlledLabelledSwitch
                control={control}
                name={"realtime_flag"}
                label="Enable Real-time"
              />
            </div>
            <div className="flex-1">
              <FormLabel htmlFor="passer_by_interval">
                {"Pass-by Interval (in seconds)"}
              </FormLabel>
              <Input
                name="passer_by_interval"
                type="number"
                {...register("passer_by_interval")}
              />
            </div>
          </FullField>
          <div className={"w-full"}>
            <CustomerTypeSelector control={control} />
          </div>
        </div>
      </div>
    </section>
  ) : null;
}
