import { Card } from "@mui/material";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

import ErrorMsg from "components/atomic/ErrorMsg";
import { Spinner } from "components/atomic/Spinner";
import { DeviceStatusView } from "domain/device-status/components/DeviceStatusView";
import { useNewHomePageData } from "../hooks";
import { CountCardContainer } from "./CountCardContainer";
import { DeviceStatusChart } from "./DeviceStatusChart";
import { InvalidDeviceContainer } from "./InvalidDeviceContainer";

const MainCardContainer = styled(Card)`
  width: 100%;
  height: 380px;
  border: 1px solid lightgrey;
`;

/**
 *
 */
export function MainContainer(props) {
  // Hooks
  const {
    isLoadingCounts,
    counts,
    statusRows,
    statusCodeAndCountMap,
    validConfigNum,
    isError,
  } = useNewHomePageData({
    revalidateOnFocus: false,
    revalidateIfStale: true,
    refreshInterval: 5 * 60 * 1000,
    revalidateOnMount: "always",
  });

  if (isLoadingCounts) {
    return <Spinner />;
  }

  if (isError) {
    return <ErrorMsg text={isError?.message} />;
  }

  return (
    <>
      <div className="flex flex-col justify-around">
        <div className={"flex-1"}>
          <MainCardContainer>
            <div className="flex flex-row justify-content-around mt-5">
              <CountCardContainer
                label="Total"
                data-tip="Total: all registered scanners"
                number={isLoadingCounts ? <Spinner /> : `${counts?.total}`}
              />
              <CountCardContainer
                label="New"
                data-tip="New: scanners without any configuration"
                number={isLoadingCounts ? <Spinner /> : `${counts?.new}`}
              />
              <CountCardContainer
                label="Activated"
                data-tip="Activated: scanners with incomplete or invalid configurations"
                number={isLoadingCounts ? <Spinner /> : `${counts?.activated}`}
              />
              <CountCardContainer
                label="Configured"
                data-tip="Configured: scanners with complete configurations but not found in Google IoT Cloud"
                number={isLoadingCounts ? <Spinner /> : `${counts?.configured}`}
              />
            </div>
            <div className="flex flex-row mt-[4rem] w-full justify-content-around align-items-center">
              <DeviceStatusChart className="w-80" />
              <InvalidDeviceContainer
                className="w-20"
                data={[
                  {
                    label: "Deactivated",
                    number: isLoadingCounts ? (
                      <Spinner />
                    ) : (
                      `${counts?.deactivated}`
                    ),
                    tip: "Deactivated Device",
                  },
                  {
                    label: "Broken",
                    number: isLoadingCounts ? <Spinner /> : `${counts?.broken}`,
                    tip: "Broken Device",
                  },
                ]}
              />
            </div>
          </MainCardContainer>
        </div>
        <DeviceStatusView
          className="max-w-full"
          formattedRows={statusRows}
          statusCodeAndCountMap={statusCodeAndCountMap}
          validConfigNum={validConfigNum}
        />
      </div>
      <ReactTooltip />
    </>
  );
}
