import axios from "axios";
import _ from "lodash";

import { handleAxiosResultData } from "features/axios";
import { handle, helpers, inspect } from "utils";
import { BASE_API } from "../constants";
import { sendPost } from "../handler";
import { checkIsValidID, getErrorMessageInResponse } from "../processor";
import { processSubmittedCustomerDeviceData } from "./pre-request";

export const CUSTOMERS_DEVICE_DATA = "/customers_device_data";
export const CUSTOMERS_DEVICE_DATA_LIST_API = `${BASE_API}${CUSTOMERS_DEVICE_DATA}`;
export const CUSTOMERS_DEVICE_DATA_OF_CUSTOMER_API = `${BASE_API}${CUSTOMERS_DEVICE_DATA}/customer`;
export const CUSTOMERS_DEVICE_DATA_ITEM_API = `${BASE_API}${CUSTOMERS_DEVICE_DATA}/id`;

export async function postCustomersDeviceData(customersDeviceData) {
  // console.log(
  //   `postCustomersDeviceData input:`,
  //   customersDeviceData
  // );
  const processed = _.omit(
    processSubmittedCustomerDeviceData(customersDeviceData),
    ["id", "customer_device_data_id", "building", "floor", "location"]
  );

  const res = await sendPost(CUSTOMERS_DEVICE_DATA_LIST_API, processed);
  return res?.data;
}

export async function putCustomersDeviceData(customersDeviceData) {
  console.log(
    `putCustomersDeviceData is called with input: ${helpers.inspect(
      customersDeviceData
    )}`
  );
  const itemID = customersDeviceData?.customer_device_data_id;
  if (checkIsValidID(itemID)) {
    const url = `${CUSTOMERS_DEVICE_DATA_ITEM_API}/${encodeURIComponent(
      itemID
    )}`;
    const processed = customersDeviceData;
    // //_.omit(
    //   processSubmittedCustomerDeviceData(customersDeviceData),
    //   ['id'],
    // );
    console.log(
      `putCustomersDeviceData with data: ${helpers.inspect(processed)}`
    );
    const res = await axios.put(url, processed);
    return res?.data;
  }
}

export async function fetchCustomersDeviceDataItem(id: string) {
  if (checkIsValidID(id)) {
    const url = `${CUSTOMERS_DEVICE_DATA_ITEM_API}/${encodeURIComponent(id)}`;
    const res = await axios.get(url);
    return res?.data;
  }
  return null;
}

/**
 * @param  {} json
 * @param  {number} errorLevel default 3
 * @param  {string} accessToken?
 * @returns Promise
 */
export async function postNewPreconfiguration(
  json,
  errorLevel: number = 3
): Promise<any> {
  console.log(`calling postNewPreconfiguration`);
  console.log(`postNewPreconfiguration data: ${inspect(json)}`);

  const customerDeviceDataId = json?.customer_device_data_id;
  if (!checkIsValidID(customerDeviceDataId)) {
    throw new Error(
      "Attempting to write customer device data without valid customer_device_data_id"
    );
  }

  let result;
  let resultErr;
  let n = errorLevel;
  while (!result && n > 0) {
    [result, resultErr] = await handle(
      axios.post(CUSTOMERS_DEVICE_DATA_LIST_API, json)
    );
    n--;
  }

  if (!result) {
    console.error(`postNewPreconfiguration failed: ${resultErr?.message}`);
    throw new Error(getErrorMessageInResponse(resultErr));
  }

  console.log(`postNewPreconfiguration finished`);
  return handleAxiosResultData(result);
}
