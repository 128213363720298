import {Switch} from '../atomic/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useController, useForm } from "react-hook-form";


export function LabelledSwitch(props) {
  const {label, ...rest} = props;
  return (<FormControlLabel control={<Switch {...rest} />} label={label} />)
}

export function ControlledLabelledSwitch(props){
  const {control, name, label, rules, ...rest} = props;

  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules,
  });

  return (<FormControlLabel control={<Switch {...field} {...rest} />} label={label} />)
}