import jwt_decode from "jwt-decode";
import { getNow } from "features/date";
import { AUTH_KEY } from "./constants";
import { JWT } from "../types";
import { setToLocalStorage, getLocalStorage } from "features/local-storage";
import { removeAxiosAuthHeader } from "features/axios";

export function checkIsJWTTokenValid(token): boolean {
  try {
    if (!token) return false;
    const { exp } = jwt_decode(token) || ({} as any);
    const now = new Date().getTime() / 1000;
    const isValid = Boolean(exp && now < exp);
    !isValid && console.warn(`Token expired `, token);
    return isValid;
  } catch (error) {
    // console.error("checkIsJWTTokenValid - Invalid token!", error);
    return false;
  }
}

export function decodeUserData(jwtToken) {
  try {
    if (!jwtToken) return null;
    const data: any = jwt_decode(jwtToken);
    // console.log(`decoded JWT data: ${helpers.inspect(data)}`);

    if (!data || !data.user || !data.user.displayName || !data?.user.picture) {
      return null;
    }

    const userDataInRes = {
      isLoggedIn: true,
      username: data.user.displayName,
      userPicture: data.user.picture,
      isSuper: false,
      customerId: "",
    };
    return userDataInRes;
  } catch (error) {
    // console.error(`decodeUserData - Invalid token!`, error);
    return null;
  }
}

export function getLocalStorageAuthItem(partnerName: string): object {
  // console.log(`getLocalStorageAuthItem is called`);
  const authToken = getAuthTokenFromLocalStorage(partnerName);
  if (authToken) {
      const userData = decodeUserData(authToken);
      return userData;
    }
  return null;
}

export function isTokenExpired(exp: number): boolean {
  const now = Math.floor(getNow().getTime() / 1000);
  const result = exp < now;
  result && console.warn("Token expired!", exp, now);
  return result;
}
// Sets the token to local storage in the context of the current partner session
export function setTokenToLocalStorage(token: string, partnerName: string) {
  const storedTokens = getLocalStorage(AUTH_KEY) || [];
  if (!storedTokens.find((item) => item[partnerName])) {
    storedTokens.push({ [partnerName]: token });
  }
  return setToLocalStorage(AUTH_KEY, storedTokens);
}

// Gets the token from local storage in the context of the current partner session
export function getAuthTokenFromLocalStorage(partnerName: string): string {
  const storedTokens = getLocalStorage(AUTH_KEY) || [];
  const tokenEntry = storedTokens.find((item) => item[partnerName]) || {};
  const token = tokenEntry[partnerName];
  return token;
}

export function getDecodeAuthTokenFromLocalStorage(partnerName: string) {
  try {
    const token = getAuthTokenFromLocalStorage(partnerName);
    // console.debug('getDecodeAuthTokenFromLocalStorage', token);
    if (!token) return null;
    const tokenObj: JWT = jwt_decode(token);
    return tokenObj;
  } catch (error) {
    return null;
  }
}

// Remove ALL token from local storage
export function clearAuthTokensInLocalStorage(): void {
  setToLocalStorage(AUTH_KEY, []);
}

export function checkIsTokenValid(token: string): boolean {
  try {
    if (!token) return false;
    const tokenObj: any = jwt_decode(token);
    const { exp } = tokenObj || { exp: true };
    return exp && !isTokenExpired(exp);
  } catch (error) {
    // console.warn("checkIsTokenValid gets error", error);
    return false;
  }
}

export function checkIsAuthInLocalStorageValid(partnerName: string): boolean {
  const token = getAuthTokenFromLocalStorage(partnerName);
  return checkIsTokenValid(token);
}

export function logout() {
  clearAuthTokensInLocalStorage();
  sessionStorage.clear();
  localStorage.clear();
  removeAxiosAuthHeader();
  // window.location.reload();
}
