import { useHomePageData } from '../hooks';
import Spinner from 'components/atomic/Spinner';

export function DeviceStatusChart(props) {
  const { isLoadingCounts, counts } = useHomePageData();
  const total = counts?.online + counts?.offline;
  const onlineWidth = (counts?.online / total) * 100;
  const offlineWidth = (counts?.offline / total) * 100;

  return (
    <div
      className='flex flex-column'
      style={{
        width: '500px',
      }}
    >
      <div className='flex flex-row justify-content-between'>
        <div className='label font-light' style={{ color: '#0160F3' }}>
          Online
        </div>
        <div className='label font-light' style={{ color: '#F5C828' }}>
          Offline
        </div>
      </div>
      <div className='flex flex-row' style={{ height: '40px' }}>
        <div
          className='flex'
          style={{
            width: `${onlineWidth}%`,
            height: '100%',
            backgroundColor: '#0160F3',
            borderTopLeftRadius: '12px',
            borderBottomLeftRadius: '12px',
          }}
        />
        <div
          className='flex'
          style={{
            width: `${offlineWidth}%`,
            height: '100%',
            backgroundColor: '#F5C828',
            borderTopRightRadius: '12px',
            borderBottomRightRadius: '12px',
          }}
        />
      </div>

      <div className='flex flex-row justify-content-between'>
        <div className='flex font-bold bottom-label'>
          {isLoadingCounts ? (
            <Spinner />
          ) : (
            <span style={{ fontSize: 'larger' }}>
              {counts?.online} /{' '}
              <span className='text-sm'>{counts?.total}</span>
            </span>
          )}
        </div>
        <div className='flex font-bold bottom-label'>
          {isLoadingCounts ? (
            <Spinner />
          ) : (
            <span style={{ fontSize: 'larger' }}>
              {counts?.offline} /{' '}
              <span className='text-sm'>{counts?.total}</span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default DeviceStatusChart;
