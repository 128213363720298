import {
  selectIsModalOpened, selectModalData
} from 'features/users/lib/redux/usersSlice';
import { useSelector } from 'react-redux';
import { inspect } from 'utils';
import { ModalContainer } from './ModalContainer';
import { UserToastContainer } from './UserToastContainer';

export function ModalRoot({ customers, defaultCustomer, ...optionals }) {
  // Root component for Modal, including modal and toast

  // Global States
  const modalData = useSelector(selectModalData);
  const isOpen = useSelector(selectIsModalOpened);

  console.log(`modalData: ${inspect(modalData)}`);

  return (
    <>
      <UserToastContainer />
      {isOpen ? (
        <ModalContainer modalData={modalData} customers={customers} defaultCustomer={defaultCustomer} />
      ) : null}
    </>
  );
}
