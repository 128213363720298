import {
  format,
  formatRFC3339,
  fromUnixTime,
  parseISO,
  differenceInMinutes,
  differenceInHours,
  differenceInCalendarDays,
  differenceInDays,
  subDays,
  addDays,
  subMilliseconds,
  addMilliseconds,
  startOfDay,
  subWeeks,
  subMonths,
} from 'date-fns';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
import { getUnixTimeByDate } from './getters';

/**
 * @description parse ISO string, e.g. 2022-04-27T12:50:00.000Z
 */
export function parseString(input: string): Date {
  return parseISO(input);
}

export function parseEpochNumber(input: number) {
  // return parse(input);
}

export function createDateByUnixTime(unixTime: number): Date {
  if (!unixTime) {
    return null;
  }
  const result = fromUnixTime(unixTime);
  // console.debug('createDateByUnixTime output:', result);
  return result;
}

/**
 *
 * @param date1
 * @param date2
 * @returns number of minutes = date1 - date2
 */
export function diffInMinutes(
  date1: Date | number,
  date2: Date | number
): number {
  return differenceInMinutes(date1, date2);
}

/**
 * date1 minus date2
 */
export function diffInHours(
  date1: Date | number,
  date2: Date | number
): number {
  return differenceInHours(date1, date2);
}

/**
 * @description if same day, return 0
 * @returns date1 - date2
 */
export function diffInCalendarDays(date1, date2) {
  return differenceInCalendarDays(date1, date2);
}

export function addDaysToUnixTime(d: number, num: number) {
  const date = createDateByUnixTime(d);
  const result = addDays(date, num);
  return result;
}

/**
 * @param d unix timestamp
 */
export function subDaysToUnixTime(d: number, num: number): Date {
  const date = createDateByUnixTime(d);
  const result = subDays(date, num);
  return result;
}

export function subMs(date: Date | number, amount: number) {
  return subMilliseconds(date, amount);
}


export function shiftDaysBackward(unix: number, daysNum: number): number {
  if (!unix) return null;

  const date = createDateByUnixTime(unix);
  const nextDate = subDays(date, daysNum);
  return getUnixTimeByDate(nextDate);
}

export function shiftMonthsBackward(unix: number, num: number): number {
  if (!unix) return null;

  const date = createDateByUnixTime(unix);
  const nextDate = subMonths(date, num);
  return getUnixTimeByDate(nextDate);
}

export function calculateDiffInCalendarDaysForDateRange(
  endDate: number,
  startDate?: number
): number {
  if (!startDate) {
    return 1;
  } else {
    return diffInCalendarDays(
      createDateByUnixTime(endDate),
      createDateByUnixTime(startDate)
    );
  }
}

/**
 * 
 */
export function calculateDiffInDaysForDateRange(
  endDate: number,
  startDate?: number
): number {
  if (!startDate) {
    return 0;
  } else {
    return differenceInDays(
      createDateByUnixTime(endDate),
      createDateByUnixTime(startDate)
    );
  }
}

/**
 * return 0 when start date is missing
 */
export function calculateDiffInHoursForDateRange(
  endDate: number,
  startDate?: number
): number {
  if (!startDate) {
    return 0;
  } else {
    return diffInHours(
      createDateByUnixTime(endDate),
      createDateByUnixTime(startDate)
    );
  }
}