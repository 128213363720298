import { KaiduDevicesListItem } from './types';
import { isFilledArray } from 'utils';
import { DEVICE_STATUS } from '../kaidu-device-status';
// return an object, e.g. {"0":38}
export function countDeviceNumInStatus(deviceList: any[]): Object {
  const result = deviceList.reduce(
    (acc, o) => (
      (acc[o?.kaidu_device_status] = (acc[o?.kaidu_device_status] || 0) + 1),
      acc
    ),
    {}
  );
  return result;
}

// e.g."00-01-00-01-05" -> "hardware: v0.1; software: v0.1.5"
export function convertVersionToReadable(str: string): string {
  let result;
  const arr = str.split('-').map((n) => parseInt(n));
  if (arr.length < 5) {
    throw new Error('Invalid Version string. Too short');
  }
  const hw = 'v' + arr.slice(0, 2).join('.');
  const sw = 'v' + arr.slice(2).join('.');
  result = `hardware: ${hw}; software: ${sw}`;
  return result;
}

export function countKaiduDevicesWithConfiguredStatus(
  deviceList: KaiduDevicesListItem[]
): number {
  if (!isFilledArray(deviceList)) {
    return 0;
  }
  const total = deviceList.filter(
    (item) => item.kaidu_device_status === DEVICE_STATUS.CONFIGURED
  );
  return total?.length;
}

/**
 * @description
 * @param  {KaiduDevicesListItem[]} deviceList
 * @returns {number} the number of devices which are not labelled as BROKEN or DEACTIVATED
 */
export function countKaiduDevicesWithDeactivatedStatus(
  deviceList: KaiduDevicesListItem[]
): number {
  if (!isFilledArray(deviceList)) {
    return 0;
  }
  const count = getDeactivatedKaiduDevices(deviceList)?.length;

  return count;
}

/**
 * @description
 * @param  {KaiduDevicesListItem[]} deviceList
 * @returns {number} the number of devices which are not labelled as BROKEN or DEACTIVATED
 */
 export function countKaiduDevicesWithActivatedStatus(
  deviceList: KaiduDevicesListItem[]
): number {
  if (!isFilledArray(deviceList)) {
    return 0;
  }
  const count = getActivatedKaiduDevices(deviceList)?.length;

  return count;
}

/**
 * @param  {KaiduDevicesListItem} item
 * @returns {boolean} check if it's labelled as BROKEN or DEACTIVATED
 */
function checkIsDeactivated(item: KaiduDevicesListItem): boolean {
  return (
    item?.kaidu_device_status &&
    (item?.kaidu_device_status === DEVICE_STATUS.BROKEN ||
      item?.kaidu_device_status === DEVICE_STATUS.DEACTIVATED)
  );
}

export function getDeactivatedKaiduDevices(
  list: KaiduDevicesListItem[]
): KaiduDevicesListItem[] {
  const deactivated = list?.filter((item) => checkIsDeactivated(item));
  return deactivated;
}

export function getActivatedKaiduDevices(
  list: KaiduDevicesListItem[]
): KaiduDevicesListItem[] {
  const deactivated = list?.filter((item) => !checkIsDeactivated(item));
  return deactivated;
}

