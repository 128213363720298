import useSWR, { SWRResponse } from "swr";

import { helpers } from "utils";
import { KAIDU_DEVICES_LIST_API } from "./api";
import { KaiduDevicesListItem } from "./types";

const { swrFetcher } = helpers;

export function useKaiduDevicesList(
  mounted = true,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
): {
  kaiduDevicesList: KaiduDevicesListItem[] | undefined;
  isLoading: boolean;
  isError: Error | undefined;
} {
  const { data, error }: SWRResponse<KaiduDevicesListItem[], Error> = useSWR(
    mounted ? KAIDU_DEVICES_LIST_API : null,
    () => swrFetcher(KAIDU_DEVICES_LIST_API),
    // swrFetcher,
    options
  );

  return {
    kaiduDevicesList: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useSingleKaiduDevice(
  mac: string,
  options: any = {}
): {
  kaiduDevice: KaiduDevicesListItem | undefined;
  isLoading: boolean;
  isError: Error | undefined;
} {
  // console.log(`useSingleKaiduDevice called with mac: ${mac}`);
  const { data, error }: SWRResponse<KaiduDevicesListItem, Error> = useSWR(
    mac ? `${KAIDU_DEVICES_LIST_API}/${encodeURIComponent(mac)}` : null,
    swrFetcher,
    {
      ...options,
      revalidateOnFocus: false,
      refreshInterval: 0,
    }
  );

  return {
    kaiduDevice: data,
    isLoading: mac && !error && !data,
    isError: error,
  };
}
