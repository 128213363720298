import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

type ModalSizes = 'lg' | 'sm' | 'xl';

const CustomModal = styled(Modal)`
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
  }

  .modal.d-block {
    opacity: 1;
    pointer-events: auto;
  }
`;

export function BaseModal({ title, ...optionals }) {
  const {
    children,
    show = false,
    onHide = () => {},
    size = 'lg' as ModalSizes,
    showCloseButton = true,
    showBodyContainer = true,
    ...rest
  } = optionals;

  return (
    <>
      <CustomModal show={show} onHide={onHide} centered size={size} {...rest}>
        {title || showCloseButton ? (
          <CustomModal.Header closeButton={showCloseButton}>
            {title && <Modal.Title>{title}</Modal.Title>}
          </CustomModal.Header>
        ) : null}
        {showBodyContainer ? (
          <CustomModal.Body>{children}</CustomModal.Body>
        ) : (
          children
        )}
      </CustomModal>
    </>
  );
}

export default BaseModal