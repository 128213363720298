import { useDispatch } from "react-redux";

import { AddBtn } from "components/molecule/AddBtn";
import {
  setModalData,
  setOpenModal,
} from "domain/customers/lib/redux/customersSlice";

export function AddNewCustomerBtn({ show = true, ...optionals }) {
  const { className } = optionals;
  const dispatch = useDispatch();

  const handleClick = () => {
    console.log("click add customer");
    dispatch(
      //@ts-ignore
      setModalData({
        operationType: "add",
      })
    );
    //@ts-ignore
    dispatch(setOpenModal(true));
  };

  return (
    <div className={className}>
      {show ? <AddBtn onClick={handleClick}>Add New Client</AddBtn> : null}
    </div>
  );
}
