import { Route } from "react-router";

import { AuthenticationHandler } from "domain/auth/components/AuthenticationHandler";
import { NotificationGroup } from "features/notification";
import { handleNewVersion } from "lib/services";
import { AppRoutes, pages } from "./AppRoutes";
import { LoginSuccess } from "./application/pages/LoginSuccess";
import { Nav } from "./components/organism/Nav";
import { PageTemplate } from "./components/template/PageTemplate";
import { ReduxGroupProvider } from "./providers/Redux";
import Theme from "./providers/Theme";
// import { Login } from './application/pages/Login';
// import { checkIsAuthInLocalStorageValid } from './domain/auth';
handleNewVersion();

function App() {
  // const isAuthValid = checkIsAuthInLocalStorageValid();
  return (
    <ReduxGroupProvider>
      <Theme>
        <AuthenticationHandler>
          <PageTemplate header={<Nav pages={pages} title={"Kaidu"} />}>
            <AppRoutes isAuthenticated={true} />
          </PageTemplate>
        </AuthenticationHandler>
        <NotificationGroup />
        <Route exact={false} path={"/login/success"}>
          <LoginSuccess />
        </Route>
      </Theme>
    </ReduxGroupProvider>
  );
}

export default App;
