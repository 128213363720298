import useDocumentIcon from "hooks/useDocumentIcon";
import useDocumentTitle from "hooks/useDocumentTitle";
import Spinner from "../atomic/Spinner";

export default function SpinnerWithLayout(props) {
  const { className, ...rest } = props;

  useDocumentIcon(null, true);
  useDocumentTitle("Loading...");

  return (
    <div className={`w-full p-2 pt-4 flex justify-center ${className}`}>
      <Spinner />
    </div>
  );
}
