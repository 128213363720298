export const modalInitialState = {
  isOpened: false,
  data: {} as any,
  toast: {
    content: {
      title: 'Success',
      variant: 'success',
      text: 'Changed data submitted to the server',
    },
    isOpen: false,
  } as any,
};

export const modalReducers = {
  toggleModal(state) {
    const isOpen = state.modal.isOpened;
    state.modal.isOpened = !isOpen;
  },
  setOpenModal(state, action) {
    state.modal.isOpened = action.payload;
  },
  setModalData(state, action) {
    state.modal.data = action.payload;
  },
  setToast(state, action) {
    state.modal.toast = action.payload;
  },
  setOpenToast(state, action) {
    state.modal.toast.isOpen = action.payload;
  },
  clearModalData(state) {
    state.modal.data = modalInitialState;
  },
}