import { BASE_API } from '../kaidu-config-server/constants';

const LOGIN_SUBPATH = process.env?.REACT_APP_LOGIN_API_SUBPATH || 'REPLACE_BY_BUILD_REACT_APP_LOGIN_API_SUBPATH';

export const GOOGLE_LOGIN_API = process.env?.REACT_APP_LOGIN_API_SUBPATH
  ? `${BASE_API}${LOGIN_SUBPATH}`
  : `${BASE_API}/auth/google`;

export const USER_PROFILE_PIC_PLACEHOLDER_URL =
  'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg';

const APPLE_REDIRECT_URI = `${BASE_API}/auth/client_dashboard/callback`;

export const APPLE_AUTH_OPTIONS = {
  clientId: 'com.kaidu.dashboard', // This is the service ID we created.
  scope: 'name email', // To tell apple we want the user name and emails fields in the response it sends us.
  redirectURI: APPLE_REDIRECT_URI, // As registered along with our service ID
  usePopup: false, // Important if we want to capture the data apple sends on the client side.
};

export const AUTH_KEY = 'auth';
