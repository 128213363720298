import React from 'react';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';

const BaseLabel = styled.label`
  &::after {
    content: ':';
  }
  text-transform: capitalize;
  font-weight: 500;
  color: ${(props) => props.theme.palette.grayscale[2]};
  /* min-width: 8rem; */
`;

const RequiredLabel = styled(BaseLabel)`
  ${({ theme }) => `
    &::before {
      content: "*";
      color: ${theme.palette.danger};
    }
  `}
`;

export function Label({ ...optionals }) {
  const { required = false, ...rest } = optionals;

  if (required) {
    return <RequiredLabel {...rest} />;
  }

  return <BaseLabel {...rest} />;
}

export default Label;

/**
 *
 */
export function MuiLabel(props) {
  return <InputLabel {...props} />;
}

export const LabelForSelect = styled.p`
  color: ${(props) => props.theme.colors.darkGrey};
`;