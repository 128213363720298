import { BASE_API } from 'features/kaidu-config-server';

const BASE = BASE_API;

export const PRE_CONFIG_APIS = {
  BUILDING: `${BASE}/customers_building`,
  FLOOR: `${BASE}/customers_floor`,
  LOCATION: `${BASE}/customers_location`,
};

export const DEFAULT_FLOOR_RANGE = [
  'B3',
  'B2',
  'B1',
  'Lower Level',
  'Ground',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
];

export const BASE_PROPS = [
  'default_wifi_ssid',
  'default_wifi_password',
  'id',
  'customers_list_id',
];

export const LOCATION_PROPS = [
  ...BASE_PROPS,
  'customers_location_id',
  'customers_floor_id',
  'location_name',
];

export const FLOOR_PROPS = [
  ...BASE_PROPS,
  'customers_building_id',
  'customers_floor_id',
  'floor_name',
];

export const BUILDING_PROPS = [
  ...BASE_PROPS,
  'customers_building_id',
  'building_name',
];
