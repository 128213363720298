import {FirmWareVersionJson, FirmwareDTO} from './types';
import {isFilledArray} from 'utils';
import _ from 'lodash';

// get latest compatible version from online JSON file
export function findLatestCompatibleSoftwareVersion(
  hardWareVersion: string,
  data: FirmWareVersionJson,
): string | undefined {
  const compatibleFirmware = data?.firmware?.find(firmware =>
    firmware?.hardware?.some(hw => hw === hardWareVersion),
  );

  return compatibleFirmware?.software;
}

export function extractFirmwareUrls(list) {
  if (isFilledArray(list)) {
    return list.map((item) => item.firmware_url);
  }
  return null;
}

export function sortFirmwareList(list) {
  if (!isFilledArray(list)) {
    return null;
  }

  return _.sortBy(list, 'firmware_url').reverse() as FirmwareDTO[];
}