import _ from "lodash";
import useSWR from "swr";

import { getAxiosAuthToken } from "domain/auth";
import { helpers } from "utils";
import { USERS_LIST_ALL_API, USERS_LIST_API } from "./api";
import { User } from "./types";
const { swrFetcher } = helpers;

export function useUsersList(
  mounted = true,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const token = getAxiosAuthToken();
  const { data, error } = useSWR<User, Error>(
    mounted && token ? USERS_LIST_API : null,
    swrFetcher,
    options
  );
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useAllUsersList(
  mounted = true,
  options: any = {
    revalidateOnFocus: false,
    refreshInterval: 0,
  }
) {
  const { data, error } = useSWR<User[], Error>(
    mounted ? USERS_LIST_ALL_API : null,
    swrFetcher,
    options
  );
  return {
    data: _.sortBy(data, "user_email"),
    isLoading: !error && !data,
    isError: error,
  };
}
