import { useEffect, useState } from 'react';
// import { readHwSwVersions } from './processor';
import {
  readHwSwVersions,
  readAllConfigurationServiceData,
} from '../../../lib/ble/kaidu-device/kaidu-operations';
import produce from 'immer';
import { KaiduScannerBLEData } from '../../../types/interfaces';
import useSWR from 'swr';
import {
  requestBLEDevices,
  connect,
  preprocessFirmwareUpdate,
} from './processor';

/**
 * use connected GattServer to fetch BLE data
 */
export function useKaiduScannerData(connectedGattServer) {
  const [error, setError] = useState(null);
  const [deviceData, setDeviceData] = useState<KaiduScannerBLEData | null>(
    null
  );

  useEffect(() => {
    const fetchData = async (connectedGattServer) => {
      try {
        const versions = await readHwSwVersions(connectedGattServer);
        const configData = await readAllConfigurationServiceData(
          connectedGattServer
        );

        const data = { ...versions, ...configData };

        if (!deviceData) {
          setDeviceData(data);
        } else {
          const nextState = produce(deviceData, (draftState) => {
            draftState = Object.assign(draftState, versions);
          });
          setDeviceData(nextState);
        }
        // setStatus('resolved');
      } catch (err) {
        console.error('useKaiduScannerData: ', err?.message);
        setError(err);
      }
    };
    if (connectedGattServer) {
      fetchData(connectedGattServer);
    }
  }, [connectedGattServer, deviceData, setDeviceData]);

  return {
    deviceInfo: deviceData,
    isLoading: !deviceData && !error,
    isError: error,
  };
}

// version, e.g. 'v0.1.8'
export function useFirmwareChunks(firmwareFile, connectedBLEDevice) {
  // preprocess firmware file
  const { data, error } = useSWR(
    firmwareFile && connectedBLEDevice?.gatt ? 'firmwareUpdate' : null,
    async () => preprocessFirmwareUpdate(firmwareFile, connectedBLEDevice.gatt)
  );
  // firmwareChunks, characteristic

  return {
    data,
    characteristic: data?.characteristic,
    firmwareChunks: data?.firmwareChunks,
    totalChunks: data?.firmwareChunks?.length,
    isError: error,
  };
}

export function useRequestDevice(shouldScan) {
  const { data: device, error } = useSWR(
    shouldScan ? 'scan' : null,
    requestBLEDevices
  );

  return {
    device,
    isCancelled: error && error.code === 8, // assume NotFoundError (code: 8) equals user cancelled connection
    error,
  };
}

// return {id, name, gatt}
async function scanAndConnect(device) {
  try {
    // const device = await requestBLEDevices();
    const connectedGattServer = await connect(device);
    return { id: device.id, name: device.name, gatt: connectedGattServer };
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function useConnectedBLEDevice(shouldConnect, device) {
  const { data, error, mutate } = useSWR(
    shouldConnect && device ? device.id : null,
    () => scanAndConnect(device),
    { revalidateOnFocus: true }
  );

  return {
    connectedBLEDevice: data,
    isLoading: !data && !error,
    isError: error,
    mutate,
  };
}
