import { getLocalTimeZone } from "../../../features/date";

export const FOO = "";

export const DEFAULT_CUSTOMER_VALUES = {
  customer_name: "",
  contactNumber: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  postalCode: "",
  country: "",
  specialNote: "",
  start: "09:00",
  end: "17:00",
  realtime_flag: true,
  passer_by_interval: 30,
  timezone: getLocalTimeZone(),
  image: null,
  dashboard: null,
  management_dashboard: null,
  dashboard_email_template: "",
};
