/// <reference types="web-bluetooth" />
// declare using web Bluetooth API

import Container from 'components/atomic/Container';
import { FullLineH2 } from 'components/atomic/Headings';
import { MainContainer } from './components/OverviewCardsGroup';

/**
 * Home page
 */
export function HomePage(props) {

  return (
    <>
      <Container>
        <FullLineH2 className='flex-grow w-full'>Overview</FullLineH2>
      </Container>
      <Container className='w-full'>
        <MainContainer />
      </Container>
    </>
  );
}

export default HomePage;
