import { Form } from 'react-bootstrap';
import styled, { useTheme } from 'styled-components';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const StyledSimple = styled(Form.Select)`
  max-width: 300px;
`;

const StyledBottonBorder = styled(StyledSimple)`
  border-width: 0px;
  border-bottom: 1px solid ${(props) => props.theme.palette.grayscale[4]};
  border-radius: 0px;
  padding: 4px 0;
  min-width: 14rem;
  color: ${(props) => props.theme.palette.fourth};
`;

export function SimpleSelect(props) {
  return <StyledSimple {...props} />;
}

/**
 *
 */
export function BottomBorderSelect(props) {
  return <StyledBottonBorder {...props} />;
}

/**
 *
 */
export function MuiSelect({ value, onChange, options = [], ...optionals }) {
  const { id, label, ...rest } = optionals;
  const labelId = `${id}-label`;
  const theme = useTheme();

  return (
    <div className='w-48'>
      <FormControl fullWidth>
        <InputLabel id={labelId} style={{backgroundColor: theme.colors.white}} className={'px-2'}>{label}</InputLabel>
        <Select value={value} onChange={onChange} id={id} labelId={labelId} margin={'dense'} {...rest}>
          {options.map((option) => {
            return (
              <MenuItem value={option?.value} key={`${option?.value}-key`}>
                {option?.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
