import React, { useEffect, useState } from 'react';
import {
  postCode,
  NewCustomerCodeRequestData,
  NewCustomerCodeResponseData
} from 'features/kaidu-config-server/users-list';
import { useDispatch } from 'react-redux';
import { AsyncLifecycle, useAsync } from 'utils';
import { useForm } from 'react-hook-form';
import {
  clearModalData,
  setOpenModal,
} from '../../lib/redux/customersSlice';
import BaseModal from 'components/molecule/BaseModal';
import Button from 'components/atomic/Button';
import Typography, { Subtitle2 } from 'components/atomic/Typography';
import InputGroup from 'components/molecule/InputGroup';
import { InfoCard } from 'components/molecule/InfoCard';
import { toast } from 'react-toastify';

const defaults: NewCustomerCodeRequestData = {
  user_name: '',
};

export function CodeModal({ ...optionals }) {
  // Hooks
  const dispatch = useDispatch();
  const {
    reset,
    control,
    trigger,
    getValues,
    setValue,
    handleSubmit,
  } = useForm({ defaultValues: defaults });
  const { execute, status, value, error } = useAsync(
    async () => await postCode(getValues()),
    false
  );

  // State
  const [code, setCode] = useState('');

  const handleFulfilled = (value) => {
    console.log('modal request fulfilled');
    const data: NewCustomerCodeResponseData = value?.data;
    const { kaidu_code } = data || {};

    console.log('modal request fulfilled', kaidu_code);
    setCode(kaidu_code);
    return;
  };

  const handleRejected = (error) => {
    //cleanup
    dispatch(clearModalData());
    dispatch(setOpenModal(false));
    toast.error(`${error?.message}` ?? `Failed to generate register code`);
  };

  const handleHide = () => {
    setCode('');
    dispatch(clearModalData());
    dispatch(setOpenModal(false));
  };

  useEffect(() => {
    status === AsyncLifecycle.FULFILLED && handleFulfilled(value);
    status === AsyncLifecycle.REJECTED && handleRejected(error);
  }, [status]);

  return (
    <BaseModal
      show={true}
      onHide={handleHide}
      title={`Get Register Code`}
      size='xl'
    >
      <div {...optionals}>
        <Typography>
          You will get a 6-digit code which can be used to register a new
          client and a user account later.
        </Typography>
      <div className='mt-8'>
        <Typography>Please enter the user name of the
          Google account you wish to register:</Typography>
        <InputGroup
          name='user_name'
          control={control}
          label={'User Name'}
          required={true}
        />
      </div>
      {code ? <div className='mt-12'>
        <InfoCard
          text={
            `Register code: ${code}`
          }
          subHeadingText={'Success'}
          icon={'check'}
        />
        <Subtitle2>This code can only be used once</Subtitle2>
      </div> : null}
      <div className='flex justify-end mt-4 space-x-2'>
        <Button onClick={execute}>Request</Button>
        {/* <Button onClick={handleHide} variant='secondary'>Close</Button> */}
      </div>
      </div>

    </BaseModal>
  );
}
