import _ from "lodash";

import { getHoursOfOperations } from "features/hours-of-operation";
import { CustomersListItem } from "features/kaidu-config-server";
import { isFilledArray } from "utils";
import { FormCustomerListItem } from "../types";

export function getFormCustomerListItemFromFetched(
  fetched: CustomersListItem
): FormCustomerListItem {
  const { customer_id, customer_config, partner_customer_id, ...rest } =
    fetched || {};

  const {
    contactNumber,
    address1,
    address2,
    city,
    state,
    postalCode,
    country,
    specialNote,
    flags,
    passer_by_interval,
    customer_type,
    image,
    dashboard,
    management_dashboard,
    dashboard_email_template
    // hoursOfOperation,
  } = customer_config || {};

  const { realtime_flag } = flags || {};

  //@ts-ignore
  const formCustomerListItem: FormCustomerListItem = {
    ...rest,
    ...getHoursOfOperations(fetched),
    customer_id,
    contactNumber: contactNumber,
    address1: address1,
    address2: address2,
    city: city,
    state: state,
    postalCode: postalCode,
    country: country,
    specialNote: specialNote,
    realtime_flag,
    passer_by_interval,
    customer_type,
    partner_customer_id,
    image,
    dashboard,
    management_dashboard,
    dashboard_email_template: dashboard_email_template,
  };
  return formCustomerListItem;
}

export function pickPropsInCustomersList(fetched: CustomersListItem[]) {
  if (!isFilledArray(fetched)) {
    return fetched;
  }

  const result = fetched?.map((item) => {
    const picked = _.pick(item, [
      "id",
      "customer_id",
      "customer_name",
      "customer_config",
      "isPartner",
      "partner_customer_id",
    ]);
    return picked;
  });

  return result;
}
