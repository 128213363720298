import { useState, useEffect } from 'react';
import Input from '../atomic/Input';

export function SearchBar(props) {
  const { input, onChange, onChangeTerm, ...rest } = props;

  const [value, setValue] = useState('');

  // useEffect(() => {
  //   onChange && onChange(input);
  //   setValue('');
  // }, [input]);

  const handleChange = (e) => {
    const searchedTerm = e.target.value;
    onChangeTerm(searchedTerm);
    // console.log(`searchedTerm: ${searchedTerm}`);
    setValue(searchedTerm);
  };

  return (
    <div data-cy={'search-bar'} {...rest}>
      {/* <Icon icon='search' /> */}
      <Input
        value={value}
        onChange={handleChange}
        size='lg'
        placeholder='search'
      ></Input>
    </div>
  );
}

export default SearchBar