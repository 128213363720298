import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { purgeStoredState } from "redux-persist";

import { logout } from "domain/auth";
import { getSessionStorage } from "features/local-storage";
import {
  resetLogin,
  setCurrentuser,
  setPartnerCustomer,
} from "providers/redux/globalSlice";
import { customerPersistConfig } from "providers/redux/store";

export function LogoutDialog({
  isOpen,
  toggleModal,
}: {
  isOpen: boolean;
  toggleModal: () => void;
}) {
  const dispatch = useDispatch();
  let history = useHistory();
  // const [, setAuth] = useLocalStorage("auth", { token: null });
  const partnerCustomerName = getSessionStorage("partnerCustomer");

  const onLogout = () => {
    logout();
    // setAuth({ token: null });
    purgeStoredState(customerPersistConfig);
    dispatch(resetLogin());
    dispatch(setCurrentuser(null));
    dispatch(setPartnerCustomer(null));
    let nextURL = "/login";
    if (partnerCustomerName) {
      nextURL += `?customer=${encodeURIComponent(partnerCustomerName)}`;
    }
    history.push(nextURL);
  };

  return (
    <Modal
      show={isOpen}
      onHide={toggleModal}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Body>Are you sure you want to logout</Modal.Body>

      <Modal.Footer>
        <Button onClick={toggleModal} variant="outline-primary">
          Cancel
        </Button>
        <Button onClick={() => onLogout()} variant="primary" autoFocus>
          Logout
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
