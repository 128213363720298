// @ts-nocheck
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSWRConfig } from "swr";

import Button from "components/atomic/Button";
import { SimpleSelect } from "components/atomic/SimpleSelect";
import BaseModal from "components/molecule/BaseModal";
import InputGroup from "components/molecule/InputGroup";
import { CustomersListItem } from "features/kaidu-config-server/customers-list";
import {
  NewUser,
  USERS_LIST_ALL_API,
  User,
  postUser,
} from "features/kaidu-config-server/users-list";
import {
  clearModalData,
  setOpenModal,
} from "features/users/lib/redux/usersSlice";
import { useAsync } from "hooks";
import { toast } from "react-toastify";
import { AsyncLifecycle } from "utils";
import Label from "../../../components/atomic/Label";

const label = "Client";
const controlledProp = "customer_id";
const displayedProp = "customer_name";

/**
 *
 */
export function AddUserModal({
  customers,
  defaultCustomer,
  ...optionals
}: {
  customers: CustomersListItem[];
  defaultCustomer: CustomersListItem;
}) {
  const defaultCompanyId = defaultCustomer?.customer_id;

  // Hooks
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();
  const { reset, control, trigger, getValues, setValue } = useForm({
    defaultValues: { companyId: defaultCompanyId, isSuperUser: false },
  });
  const submitData = async () => {
    const data: NewUser = getValues() as NewUser;
    data.userEmail = data?.userEmail.trim();
    const selectedCustomer = customers.find(
      (cc) => cc.customer_id === data.companyId
    );
    if (selectedCustomer?.isPartner) data.is_partner = true;
    const res = await postUser(data);
    return res;
  };

  const { execute, status, value, error } = useAsync(submitData, false);

  //handlers
  const handleFinishBtnClick = async () => {
    console.log("handleFinishBtnClick is called");
    const result = await trigger();
    if (result) {
      await execute(); //execute submitData
    }
  };

  const handleFulfilled = (value) => {
    console.log("modal request fulfilled");
    const { data }: { data: User } = value || {};
    const { user_email } = data;

    reset(); //cleanup
    dispatch(clearModalData());
    dispatch(setOpenModal(false));

    //show toast
    toast.success(`New user ${user_email} is created`);

    mutate(USERS_LIST_ALL_API);
  };

  const handleRejected = (error) => {
    //show toast

    //cleanup
    dispatch(clearModalData());
    dispatch(setOpenModal(false));
    toast.error(`${error?.message}` ?? "Failed to register new user");
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setValue("companyId", value);
  };

  useEffect(() => {
    status === AsyncLifecycle.FULFILLED && handleFulfilled(value);
    status === AsyncLifecycle.REJECTED && handleRejected(error);
  }, [status, error, handleRejected, handleFulfilled]);

  return (
    <BaseModal
      show={true}
      onHide={() => {
        reset();
        dispatch(setOpenModal(false));
      }}
      title={"Register new user"}
      size="xl"
    >
      <div className="mt-2">
        <InputGroup
          name="userEmail"
          control={control}
          label={"Email"}
          required={true}
        />
        <div className="flex mt-4">
          <Label
            htmlFor={"customer-id-for-new-user"}
            className="mr-2 flex items-center"
          >
            {label}
          </Label>
          <SimpleSelect defaultValue={defaultCompanyId} onChange={handleChange}>
            {customers?.map((item) => (
              <option
                value={item[controlledProp]}
                key={`${item[controlledProp]}-key`}
              >
                {item ? item[displayedProp] : "No data"}
              </option>
            ))}
          </SimpleSelect>
        </div>
        <div className="flex justify-end mt-4">
          <Button onClick={handleFinishBtnClick}>Confirm</Button>
        </div>
      </div>
    </BaseModal>
  );
}
