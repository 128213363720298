
export const FIRMWARE_FILE_BASE =  process.env.REACT_APP_FIRMWARE_FILE_BASE || `REPLACE_BY_BUILD_REACT_APP_FIRMWARE_FILE_BASE`;
export const FIRMWARE_VERSIONS_JSON = `${FIRMWARE_FILE_BASE}/version.json`; // the json file contains firmware versions

//Target Firmware for Moko to Kaidu
export const FIRMWARE_HOST='3.97.223.71';
export const FIRMWARE_PORT='80';
export const FIRMWARE_SUBPATH='/v0.4.1.bin';

export const KAIDU_FIRMWARE_URL = {
  HOST: FIRMWARE_HOST,
  PORT: FIRMWARE_PORT,
  SUBPATH: FIRMWARE_SUBPATH,
};