import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { HomePage } from "application/pages/HomePage";
// import PreconfigPage from 'application/pages/PreconfigPage';
import PrivateRoute from "components/template/PrivateRoute";
// import {LoginSuccess} from 'application/pages/LoginSuccess';
import Settings from "application/pages/Settings";
import {
  fetchCustomerByName,
  useUsersList,
} from "features/kaidu-config-server";
import { getSessionStorage } from "features/local-storage";
import useDocumentIcon from "hooks/useDocumentIcon";
import useDocumentTitle from "hooks/useDocumentTitle";
import {
  selectCurrentUser,
  selectPartnerCustomer,
  setCurrentuser,
  setPartnerCustomer,
} from "providers/redux/globalSlice";
import usePartnerSession from "hooks/usePartnerSession";

export type PageProps = {
  text: string;
  icon: string;
  route: string;
  component: React.FC;
  isPublic?: boolean;
};

export const pages = [
  {
    text: "Home",
    icon: "home",
    route: "/",
    component: <HomePage />,
    isPublic: false,
  },
  // {
  //   text: 'Configuration',
  //   icon: 'list',
  //   route: '/scanners',
  //   component: <ConfigurationPage />,
  //   isPublic: false,
  // },
  // {
  //   text: 'Status',
  //   icon: 'adjust',
  //   route: '/devicestatus',
  //   component: <DeviceStatusPage />,
  //   isPublic: false,
  // },
  // {
  //   text: 'Pre-configuration',
  //   icon: 'tools',
  //   route: '/preconfig',
  //   component: <PreconfigPage />,
  //   isPublic: false,
  // },
  // {
  //   text: 'BLE',
  //   icon: 'bluetooth',
  //   route: '/bluetooth',
  //   component: <BLEPage />,
  // },
  {
    text: "Settings",
    icon: "cog",
    route: "/settings",
    component: <Settings />,
    isPublic: false,
  },
];



export function AppRoutes({ isAuthenticated, ...optionals }) {
  const { addPartnerId } = usePartnerSession();

  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const { data } = useUsersList(isAuthenticated && !user);

  const partnerCustomer = useSelector(selectPartnerCustomer);
  const localPartnerCustomerName = getSessionStorage("partnerCustomer");

  useDocumentIcon(partnerCustomer?.image);
  useDocumentTitle(`${partnerCustomer?.customer_name || "Kaidu"} Management`);

  const loadPartnerCustomerByName = useCallback(
    (customer_name: string) =>
      fetchCustomerByName(customer_name).then((partner) => {
        if (partner) {
          dispatch(setPartnerCustomer(partner));
        }
        addPartnerId(partner?.customer_id || null);
      }),
    [dispatch]
  );

  useEffect(() => {
    if (!user && data) {
      dispatch(setCurrentuser(data));
    }
  }, [user, data, dispatch]);

  useEffect(() => {
    if (!partnerCustomer && localPartnerCustomerName) {
      loadPartnerCustomerByName(localPartnerCustomerName);
    }
  }, [partnerCustomer, localPartnerCustomerName, loadPartnerCustomerByName]);

  return (
    <>
      <Switch>
        {pages.map((page, index) => {
          // public pages
          if (page?.isPublic) {
            return (
              <Route
                exact={index === 0}
                path={page.route}
                key={`route-${page.route}`}
              >
                {page.component}
              </Route>
            );
          }

          // private pages
          return (
            <PrivateRoute
              exact={index === 0}
              path={page.route}
              key={`route-${page.route}`}
              isAuthenticated={isAuthenticated}
              component={page.component}
            />
          );
        })}
        {/* <Route exact={false} path={'/login/success'}>
          <LoginSuccess />
        </Route> */}
      </Switch>
    </>
  );
}
