import React from "react";
import { Route, Redirect } from "react-router-dom";

const publicRoute = "/login";

const PrivateRoute = ({ component, isAuthenticated, ...rest }: any) => {
  return isAuthenticated ? (
    <Route {...rest}>{component}</Route>
  ) : (
    <Redirect to={{ pathname: publicRoute }} />
  );
};

export default PrivateRoute;
