import styled from "styled-components";

import Image from "components/atomic/Image";
import { useBreakPoints } from "styles";

const Profile = styled.div`
  background: inherit;
  width: 100%;
  display: flex;
  align-items: center;
  border: 3px solid transparent;
  padding-left: 50px;
  color: ${(props) => props.theme.palette.grayscale[2]};
  .name {
    margin: 0px;
    margin-left: 15px;
  }
`;

export function UserProfile({ data, ...optionals }) {
  const { isMobile } = useBreakPoints();

  return (
    <>
      <Profile className={isMobile ? "p-0 h-6" : "h-16"}>
        <Image className={"h-8 rounded-full"} src={data.userPicture || ""} />
        {!isMobile && <div className="name">{data.username}</div>}
      </Profile>
    </>
  );
}
