import { Buffer } from 'buffer';
import { formatInTimeZone } from 'date-fns-tz';
import moment from 'moment';
import _ from 'lodash';

// return true if exp is earlier than now
export function isNumericDatePassed(exp: number) {
  if (!exp) {
    return false;
  }
  const expTime = moment.unix(exp);
  const now = moment();
  // const expTime = new Date(exp * 1000);
  console.log(`exp time: ${expTime.toString()}`);
  return now.diff(expTime) > 0;
}

// date1 - date2
export function diffInSeconds(date1, date2): number {
  return date1.diff(date2, 'seconds');
}

export function getDate() {
  return moment();
}

export function convertISO8601ToReadable(iso8601String: string): string {
  const result = moment(iso8601String, moment.ISO_8601).calendar().toString();
  return result;
}

export function convertISO8601ToReadableFormat(
  iso8601String: string,
  precision: string = 'm'
): string {
  const result = moment(iso8601String, moment.ISO_8601);
  if (precision === 'm') {
    return result.format('MMM Do, YYYY, h:mm A');
  } else {
    return result.calendar().toString();
  }
}

export function createDate(input) {
  return moment(input);
}

// return a time string in 24hr format, e.g. "20:17"
export function convertTimeNumToString(num: number): string {
  let hours1 = Math.floor(num / 60);
  let minutes1 = num - hours1 * 60;

  let hourStr;
  if (hours1 < 10) {
    hourStr = '0' + hours1;
  } else {
    hourStr = String(hours1);
  }

  let minutesStr;
  if (minutes1 === 0) {
    minutesStr = '00';
  } else if (minutes1 < 10) {
    minutesStr = '0' + minutes1;
  } else {
    minutesStr = String(minutes1);
  }

  return hourStr + ':' + minutesStr;
}

// return a time string in 24hr format, e.g. "20:17"
export function convertHourNumToString(num: number): string {
  let hours1 = num;

  let hourStr;
  if (hours1 < 10) {
    hourStr = '0' + hours1;
  } else {
    hourStr = String(hours1);
  }

  return hourStr + ':' + '00';
}

export function getHourByTimezone(date: Date, timezone: string): number {
  const dateHour = Number(formatInTimeZone(date, timezone, 'HH'));
  return dateHour;
}

export function getMinutesByTimezone(date: Date, timezone: string): number {
  const dateMinute = Number(formatInTimeZone(date, timezone, 'mm'));
  return dateMinute;
}

/**
 * e.g. '01:30' -> 90
 */

export function parseTimeStringToMinuteNum(time: string) {
  if (!time || typeof time !== 'string') {
    return 0;
  }
  const hoursMinutes = time?.split(/[.:]/);
  const hours = parseInt(hoursMinutes[0], 10);
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  return hours * 60 + minutes;
}
