import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppState, AppThunk } from 'providers/redux/store';
// import produce from 'immer';
// import { Zone } from 'features/kaidu-config-server';
import { DispatchedScanner } from './types';

const example = {
  scanners: {
    byId: {
      dev01: {
        device_name: 'Test01',
        x: 42,
        y: 42,
        isSelected: false,
      },
      dev02: {
        device_name: 'Test02',
        x: 42,
        y: 42,
        isSelected: false,
      },
    },
    allIds: ['dev01', 'dev02'],
  },
};

const initialState = {
  scanners: {
    byId: {},
    allIds: [],
  },
};

export const scannersSlice = createSlice({
  name: 'scanners',
  initialState,
  reducers: {
    setAllScanners(state, action: PayloadAction<DispatchedScanner[]>) {
      // available scanners of a customer should be sent here, so that user can select scanners from this list
      // do not overwrite isSelected property
      const scanners = action.payload;
      for (let scanner of scanners) {
        const { device_name, mqtt_device_id, x, y, isSelected } = scanner || {};
        const prevScanner = state.scanners.byId[mqtt_device_id];
        state.scanners.byId[mqtt_device_id] = {
          device_name,
          x: x ?? prevScanner?.x ?? 0,
          y: y ?? prevScanner?.y ?? 0,
          isSelected: isSelected ?? prevScanner?.isSelected ?? false,
        };
      }
      state.scanners.allIds = scanners.map((s) => s.mqtt_device_id);
    },
    addScanner(state, action: PayloadAction<DispatchedScanner>) {
      // Put a scanner to the added scanner list
      // i.e. should change isSelected to true
      // console.debug(`addScanner was dispatched`);
      const scanner = action.payload;
      const { device_name, mqtt_device_id, x, y } = scanner || {};

      const prevScanner = state.scanners.byId[mqtt_device_id];
      if (!prevScanner) {
        console.debug(`prevScanner ${mqtt_device_id} does not exist`);
        state.scanners.byId[mqtt_device_id] = {
          device_name,
          x: x ?? 0,
          y: y ?? 0,
          isSelected: true,
        };
        state.scanners.allIds.push(mqtt_device_id);
      } else {
        console.debug(`prevScanner ${mqtt_device_id} exists`);
        state.scanners.byId[mqtt_device_id].x = x ?? 0;
        state.scanners.byId[mqtt_device_id].y = y ?? 0;
        state.scanners.byId[mqtt_device_id].isSelected = true;
      }
    },
    removeScanner(state, action: PayloadAction<string>) {
      // Remove a scanner from the added scanner list
      // i.e. should change isSelected to false
      const id = action.payload;
      console.debug(`removeScanner ${id}`);
      state.scanners.byId[id] = {
        ...state.scanners.byId[id],
        isSelected: false,
        x: 0,
        y: 0,
      };
    },
    moveScanner(state, action) {
      const { id, x, y } = action.payload || {};
      // const scanner = state.scanners.byId[id];
      state.scanners.byId[id].x = x;
      state.scanners.byId[id].y = y;
    },
    updateAddedScanner(state, action: PayloadAction<{mqtt_device_id, x, y}[]>) {
      // state.addedScanners = action.payload;
      for (let i = 0; i < action.payload?.length; i++) {
        const { mqtt_device_id, x, y } = action.payload[i] || {};
        state.scanners.byId[mqtt_device_id] = {
          ...state.scanners.byId[mqtt_device_id],
          x,
          y,
          isSelected: true,
        };
      }
    },
    clearAddedScanners(state) {
      const allIds = state.scanners.allIds;
      for (let i = 0; i < allIds.length; i++) {
        const id = allIds[i];
        state.scanners.byId[id].isSelected = false;
      }
    },
    clearAllScanners(state) {
      state = initialState;
    },
  },
});

export default scannersSlice.reducer;

export const {
  setAllScanners,
  addScanner,
  removeScanner,
  moveScanner,
  updateAddedScanner,
  clearAddedScanners,
  clearAllScanners,
} = scannersSlice.actions;

export const selectAllScanners = (state: AppState) => {
  return state[scannersSlice.name].scanners.allIds.map((id) => {return {...state.scanners.scanners.byId[id], mqtt_device_id: id}});
};
export const selectAddedScanners = (state: AppState) => {
  const allScanners = state[scannersSlice.name].scanners.allIds.map((id) => {return {...state.scanners.scanners.byId[id], mqtt_device_id: id}});
  return allScanners.filter((scanner) => scanner.isSelected);
};
export const selectScanner = (state: AppState) => {
  //TODO:
  return;
};
export const selectUnselectedScanners = (state: AppState) => {
  const allScanners = state[scannersSlice.name].scanners.allIds.map((id) => {return {...state.scanners.scanners.byId[id], mqtt_device_id: id}});
  return allScanners.filter((scanner) => !scanner.isSelected);
};
