import { H3 } from "components/atomic/Headings";
import { CustomersListItem } from "features/kaidu-config-server";
import CustomerItem from "./CustomerItem";

export function CustomerListView({
  customersList,
  customerId,
  defaultCustomerID,
  ...optionals
}: {
  customersList: CustomersListItem[];
  customerId: string;
  [x: string]: any;
}) {
  return (
    <div className="mt-3">
      <H3>Client Lists</H3>
      {customersList?.map((customer) => (
        <CustomerItem
          item={customer}
          isSelected={
            customer?.customer_id &&
            defaultCustomerID &&
            customer.customer_id === defaultCustomerID
          }
          key={customer.customer_id}
          defaultCustomerID={defaultCustomerID}
        />
      ))}
    </div>
  );
}
