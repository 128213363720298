import { ModalH2 } from "components/atomic/Headings";
// import Form from 'react-bootstrap/Form';
import { BottomBorderInput } from "components/atomic/Input";
import Label from "components/atomic/Label";
import { useController } from "react-hook-form";
import { RSSIThresholdSelect } from "../../Selects/RSSIThresholdSelect";
import { InputContainer } from "../../Selects/SelectorContainer";

export function ConfigurationSection({
  form,
  data,
  optionLists,
  ...optionals
}: {
  form: any;
  data: any;
  optionLists: any;
}) {
  // Props
  const { setValue, control } = form || {};
  const { rssi_thresholds_id, wifi_ssid, wifi_password } = data || {};
  const { rssiList } = optionLists || {};

  // Hooks
  const {
    field: { onChange: onChangeSSID, value: wifiSSIDValue },
  } = useController({
    name: "wifi_ssid",
    control,
    defaultValue: wifi_ssid,
  });

  const {
    field: { onChange: onChangeWifiPassword, value: wifiPasswordValue },
  } = useController({
    name: "wifi_password",
    control,
    defaultValue: wifi_password,
  });

  return (
    <section>
      <ModalH2>Configuration</ModalH2>
      <div className="w-full flex flex-col gap-4">
        <div className="flex gap-4">
          <InputContainer>
            <Label className="mr-3" htmlFor={"wifi_ssid"}>
              Wi-Fi SSID
            </Label>
            <BottomBorderInput
              className={"flex"}
              value={wifiSSIDValue}
              onChange={onChangeSSID}
              id={"wifi_ssid"}
            />
          </InputContainer>
          <InputContainer>
            <Label className="mr-3" htmlFor={"wifi_password"}>
              Wi-Fi Password
            </Label>
            <BottomBorderInput
              className={"flex"}
              value={wifiPasswordValue}
              onChange={onChangeWifiPassword}
              id={"wifi_password"}
            />
          </InputContainer>
        </div>
        <div>
          <RSSIThresholdSelect
            setValue={setValue}
            defaultValue={rssi_thresholds_id}
            control={control}
            options={rssiList}
          />
        </div>
      </div>
    </section>
  );
}
