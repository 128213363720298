import { useDispatch } from "react-redux";

import { Button } from "components/atomic/Button";
import {
  DEVICE_STATUS_MODAL,
  selectModal,
  setDeviceData,
  updateDeviceMacAddress,
} from "providers/redux/deviceStatusSlice";

/**
 * Operation components for the Status page table only
 */
export default function DeviceStateOperations({ mac, data }) {
  const dispatch = useDispatch();

  const handleDetails = () => {
    dispatch(updateDeviceMacAddress(mac));
    dispatch(selectModal(DEVICE_STATUS_MODAL.DETAILS));
  };

  const handleEditBtnClick = () => {
    dispatch(updateDeviceMacAddress(mac));
    dispatch(selectModal(DEVICE_STATUS_MODAL.EDIT));
    dispatch(setDeviceData(data));
  };

  const handleUpdateBtnClick = () => {
    dispatch(updateDeviceMacAddress(mac));
    dispatch(selectModal(DEVICE_STATUS_MODAL.VERSION_UPDATE));
    dispatch(setDeviceData(data));
  };

  return (
    <div className="flex flex-column gap-1">
      <div className="flex gap-1">
        <Button variant="outline-secondary" onClick={handleDetails}>
          Details
        </Button>
        <Button variant="outline-secondary" onClick={handleEditBtnClick}>
          Edit
        </Button>
      </div>
      <div>
        <Button variant="outline-secondary" onClick={handleUpdateBtnClick}>
          Update
        </Button>
      </div>
    </div>
  );
}
