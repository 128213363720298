import { useController } from "react-hook-form";

import ErrorMsg from "components/atomic/ErrorMsg";
import { SimpleSelect } from "components/atomic/SimpleSelect";
import { Dt } from "components/molecule/KeyValuePair";
import SpinnerWithLayout from "components/molecule/SpinnerWithLayout";
import { useKaiduDeviceStatusEnum } from "features/kaidu-config-server/kaidu-device-status";
import { isFilledArray } from "utils";

/**
 * A component for updating scanner status in configuration page
 */
export default function EditScannerStatus({ defaultValue, ...optionals }) {
  const { className, form, ...rest } = optionals;
  const { control } = form || {};

  // Hooks
  const { statusEnum, isLoading, isError } = useKaiduDeviceStatusEnum();
  const {
    field: { onChange, value },
  } = useController({
    name: "kaidu_device_status",
    control,
    rules: { required: true },
    defaultValue,
  });

  if (isLoading) return <SpinnerWithLayout />;
  if (isError)
    return (
      <ErrorMsg
        text={"An error has occurred while retrieving status categories"}
      />
    );
  if (!isFilledArray(statusEnum)) {
    return null;
  }

  const statusOptions = statusEnum?.map((item) => ({
    label: item?.status_description,
    value: item?.status_id,
  }));

  return (
    <div
      className={`max-w-full items-center ${className}`}
      // className={`flex flex-row flex-wrap max-w-full items-center ${className}`}
    >
      <Dt className={"w-48"}>Device status</Dt>
      {/* <Badge>{statusTexts[defaultValue]}</Badge> */}
      <SimpleSelect
        id="kaidu-device-status-select"
        {...optionals}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
      >
        {statusOptions.map((option) => (
          <option value={option.value} key={"status-" + option.value}>
            {option["label"]}
          </option>
        ))}
      </SimpleSelect>
      {/* <Select options={statusOptions} value={value} onChange={handleChange} /> */}
    </div>
  );
}
