/* eslint-disable no-control-regex */
/**
 * @description Module: synchronous data operations
 */
import { Buffer } from "buffer";
import _ from "lodash";
import moment from "moment";
import {
  BFLMap,
  CustomersDeviceData,
  DeviceStatistics,
  FirmWareVersionJson,
  Version,
} from "../types/interfaces";

export function decodeBase64(str: string): string {
  return Buffer.from(str, "base64").toString("utf8");
}

export function encodeBase64(str: string): string {
  return Buffer.from(str, "utf8").toString("base64");
}

/**
 * @description encode bytes into base64
 * @param  {Uint8Array} bytes
 * @returns a string in Base64
 */
export function convertByteArrayToBase64(bytes: Uint8Array): string {
  return Buffer.from(bytes).toString("base64");
}

// type IntegerArray =
//   | Int8Array
//   | Uint8Array
//   | Int16Array
//   | Uint16Array
//   | Int32Array
//   | Uint32Array
//   | Uint8ClampedArray;

export function sliceIntoChunks(arr, chunkSize: number) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    // @ts-ignore
    res.push(chunk);
  }
  return res;
}
/**
 * @param  {string} str must be
 * @returns object
 */
export function processDeviceStatisticReading(
  str: string
): DeviceStatistics | null {
  console.log(`processDeviceStatisticReading is called. input: ${str}`);
  let json;
  try {
    json = JSON.parse(
      removeTraillingStringInJSON(
        str
          .replace(/\u0000d\":\"\"}/g, "")
          .replace(/\u0000/g, "")
          .normalize()
      )
    );
  } catch (error) {
    console.error(`processDeviceStatisticReading error: input is ${str}`);
    throw new Error(`Device Statistic Reading error. read value: ${str}`);
  }
  // const json = JSON.parse(
  //   (str.normalize()),
  // );

  // Convert each timestamp property to a readable date string;
  const oldTimestampProperties = ["start_timestamp", "end_timestamp"];
  const newTimestampProperties = ["start_time", "end_time"];
  const result = convertTimeStampPropertiesToReadableString(
    json,
    oldTimestampProperties,
    newTimestampProperties
  );

  console.log(`processDeviceStatisticReading finished.`);
  return result as DeviceStatistics;
}

export function removeTraillingStringInJSON(str: string): string {
  const left = (str.match(/{/g) || []).length;
  const right = (str.match(/}/g) || []).length;
  if (right > left) {
    let start = 0;
    for (let i = 0; i < left; i++) {
      start = str.indexOf("}", start);
    }
    return str.slice(0, start + 1);
  }
  return str;
}

/**
 * @param  {object} obj must have all the properties in oldPropertyNames
 * @param  {Array<string>} oldPropertyNames
 * @param  {Array<string>} newPropertyNames
 */
export function convertTimeStampPropertiesToReadableString(
  obj: object,
  oldPropertyNames: Array<string>,
  newPropertyNames: Array<string>
) {
  console.log(`convertTimeStampPropertiesToReadableString is called`);
  // if obj doesn't have the properties, throw errors
  if (oldPropertyNames.some((prop) => obj[prop] === undefined)) {
    throw new Error("The object does not have the asked properties");
  }

  const output = Object.assign({}, obj);

  for (let i = 0; i < oldPropertyNames.length; i++) {
    console.log(`TimeStamp: ${output[oldPropertyNames[i]]}`);
    output[newPropertyNames[i]] = new Date(
      parseInt(output[oldPropertyNames[i]])
    ).toString();
  }
  oldPropertyNames.forEach((oldProperty) => delete output[oldProperty]);

  return output;
}

export function removeNULLvalues(str: string): string {
  return str.replace(/\u0000/g, "");
}

/**
 * @param  {string} str
 * @returns object
 */
export function processVersionReading(str: string): Version {
  console.log(`processVersionReading: ${str}`);
  const buf = Buffer.from(str, "ascii");
  const hw = buf.slice(0, 2).join(".");
  const sw = buf.slice(2).join(".");
  return { hw, sw };
}

export function getDistinctProperties(
  objects: object[] | null | undefined,
  property: string
): Array<any> | null {
  if (objects === null || objects === undefined || objects.length === 0) {
    return null;
  }
  const uniques = [...new Set(objects.map((item) => item[property]))].filter(
    (item) => item !== null
  );
  // console.log('getDistinctProperties: ' + uniques);
  return uniques;
}

/**
 * @param  {string} hardWareVersion
 * @param  {FirmWareVersionJson} data
 * @returns string | undefined compatible Firmware version
 */
export function findLatestCompatibleSoftwareVersion(
  hardWareVersion: string,
  data: FirmWareVersionJson
): string | undefined {
  const compatibleFirmware = data.firmware.find((firmware) =>
    firmware.hardware.some((hw) => hw === hardWareVersion)
  );

  return compatibleFirmware?.software;
}

export function formateHardwareVersion(str: string): string {
  if (str.startsWith("v")) {
    return str;
  } else {
    return "v" + str;
  }
}

export function convertArrayBufferToBase64(arr: ArrayBuffer): string {
  const view = new Uint8Array(arr);
  // console.log(`${view}`);
  return convertByteArrayToBase64(view);
}

export function splitNumberIntoTwoDigitsArray(num: number): number[] {
  let current = num;
  let result = [] as number[];
  let e: number;
  while (current > 0) {
    e = current % 100;
    result.push(e);
    current = Math.floor(current / 100);
  }
  return result.reverse();
}

/**
 * @description return the percentage (%)number
 * @param  {number} divider
 * @param  {number} dividend
 */
export function getPercentage(divider: number, dividend: number) {
  return (dividend / divider) * 100;
}

/**
 * @description get the number of chunks
 * @param  {ArrayBuffer} bytes
 * @param  {number} chunkSize
 * @returns number
 */
export function getTotalChunks(bytes: ArrayBuffer, chunkSize: number): number {
  const totalSize = bytes.byteLength;
  // console.log(`Firmware size: ${totalSize}`);
  const totalChunks = Math.ceil(totalSize / chunkSize);
  return totalChunks;
}

export function convertISO8601ToReadable(iso8601String: string): string {
  const result = moment(iso8601String, moment.ISO_8601).calendar().toString();
  return result;
}

// e.g."00-01-00-01-05" -> "hardware: v0.1; software: v0.1.5"
export function convertVersionToReadable(str: string): string {
  let result;
  const arr = str.split("-").map((n) => parseInt(n));
  if (arr.length < 5) {
    throw new Error("Invalid Version string. Too short");
  }
  const hw = "v" + arr.slice(0, 2).join(".");
  const sw = "v" + arr.slice(2).join(".");
  result = `hardware: ${hw}; software: ${sw}`;
  return result;
}

/**
 * @description filter the array of objects by the given property,
 * @param  {any[]} rows
 * @param  {string} propertyName
 * @param  {} propertyValue if the value is 'all', return all the objects
 */
export function filterByProperty(
  rows: any[],
  propertyName: string,
  propertyValue: any
): any[] {
  if (propertyValue.toUpperCase() === "ALL") {
    return rows;
  }
  const filtered = rows.filter((row) => row[propertyName] === propertyValue);
  return filtered;
}

/**
 * @param  {Object[]} rows
 * @param  {string} property
 * @returns array of uniq values for given property
 */
export function getUniques(rows: Object[], property: string) {
  const uniqs = _.uniqBy(rows, property).map((item) => item[property]);

  return uniqs;
}

export function createFormattedPreConfigMap(
  customerDeviceData: CustomersDeviceData[]
): BFLMap {
  let result;
  const buildings = getUniques(customerDeviceData, "building");
  result = buildings.map((buildingItem) => {
    // assign floors to each building
    const filteredBuilding = customerDeviceData.filter(
      (item) => item.building === buildingItem
    );
    const uniqFloors = getUniques(filteredBuilding, "floor");
    const floors = uniqFloors.map((floorValue) => {
      const filteredFloor = filteredBuilding.filter(
        (item) => item.floor === floorValue
      );
      const uniqLocations = getUniques(filteredFloor, "location");
      const locations = uniqLocations.map((locationValue) => {
        const filteredLocation = filteredFloor.filter(
          (item) => item.location === locationValue
        ); //original data items with the same location
        const uniqWifis = uniqByWiFi(filteredLocation);

        return { location: locationValue, wifis: uniqWifis };
      });
      return { floor: floorValue, locations: locations };
    });
    // const filteredFloor = filteredBuilding

    return { building: buildingItem, floors };
  });
  // console.log(`createBuildingFloorLocationMap ${helpers.inspect(result)}`);
  return result;
}

export function uniqByWiFi(
  customerDeviceData: CustomersDeviceData[]
): CustomersDeviceData[] {
  return _.uniqWith(
    customerDeviceData,
    (a, b) => a.wifi_ssid === b.wifi_ssid && a.wifi_password === b.wifi_password
  );
}

/**
 * @param  {File} file
 * @returns Promise
 */
export function convertFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export function removeConvertedBase64Overhead(str: string) {
  const header = "base64,";
  const LastIndexoOfOverhead = str.indexOf(header) + 7;
  const removed = str.substring(LastIndexoOfOverhead);
  return removed;
}
