export function findCustomerDeviceDataItem(list, obj) {
  // console.log('findCustomerDeviceDataItem obj', obj);
  const found = list?.find((item) => checkHaveSameProperties(item, obj));
  return found;
}

/**
 * @description rudimentary check if the 2 objects have the same properties
 * @param obj1
 * @param obj2
 * @returns
 */
export function checkHaveSameProperties(obj1, obj2) {
  // remember that NaN === NaN returns false
  // and isNaN(undefined) returns true
  if (
    isNaN(obj1) &&
    isNaN(obj2) &&
    typeof obj1 === "number" &&
    typeof obj2 === "number"
  ) {
    return true;
  }

  // Check if both arguments link to the same object.
  // Especially useful on the step where we compare prototypes
  if (obj1 === obj2) {
    return true;
  }

  return Object.keys(obj1).every(
    (key) =>
      obj2[key] === obj1[key] || (Boolean(!obj1[key]) && Boolean(!obj2[key]))
  );
}
