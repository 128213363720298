import { useDispatch } from "react-redux";

import BaseModal from "components/molecule/BaseModal";
import { setOpenModal } from "domain/customers/lib/redux/customersSlice";
import { CustomersListItem } from "features/kaidu-config-server";
import { useCustomersListById } from "features/kaidu-config-server/customers-list";
import EditCustomerForm from "./EditCustomerForm";

export function EditCustomerModal({
  id,
  customerItem,
  allCustomerNames,
  ...optionals
}: {
  customerItem: CustomersListItem;
  allCustomerNames: string[];
  [x: string]: any;
}) {
  // Hooks
  const dispatch = useDispatch();
  const { customer_name, customer_id } = customerItem || {};
  const customerInfo = useCustomersListById(customer_id);

  return (
    <BaseModal
      show={true}
      onHide={() => {
        dispatch(setOpenModal(false));
      }}
      title={`Edit client: ${customer_name} `}
    >
      <div className="mt-2">
        {customerInfo.customer && (
          <EditCustomerForm
            allCustomerNames={allCustomerNames}
            customerItem={customerInfo.customer}
          />
        )}
      </div>
    </BaseModal>
  );
}
