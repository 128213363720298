import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setToLocalStorage, getLocalStorage, getSessionStorage } from "features/local-storage";
import { setPartnerCustomer, selectPartnerCustomer, selectCurrentUser } from "providers/redux/globalSlice";
import { fetchCustomersList } from "features/kaidu-config-server/customers-list/fetch";
import { getAuthTokenFromLocalStorage } from "domain/auth/lib/processors";

interface UsePartnerSessionReturn {
  partners: string[];
  setPartners: React.Dispatch<React.SetStateAction<string[]>>;
  addPartners: () => void;
  switchPartner: (partner: string) => void;
  addPartnerId: (partner_id: string) => void;
  getPartnerId: () => string | undefined;
}

const usePartnerSession = (): UsePartnerSessionReturn => {
  const [partners, setPartners] = useState<string[]>([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const partnerCustomer = useSelector(selectPartnerCustomer);
  const currentUser = useSelector(selectCurrentUser);
  
  // Get the current partner name from the session storage or the redux store
  const localPartnerCustomerName = getSessionStorage("partnerCustomer");
  const partnerName = localPartnerCustomerName || partnerCustomer?.customer_name;

  // Add all partners to the session switcher from the API if the user is a super user
  const addPartners = useCallback(async () => {
    const isSuperUser = currentUser?.isSuperUser || false;
    const defaultPartnerName = 'undefined';
    const authToken = getAuthTokenFromLocalStorage(partnerName);

    if (authToken && isSuperUser) {
      try {
        const allCustomers = await fetchCustomersList(authToken, isSuperUser);
        const allPartners = allCustomers.filter(customer => customer.isPartner === true);
        const updatedPartners = allPartners.map(partner => partner.customer_name);
        updatedPartners.push(defaultPartnerName);

        setPartners(updatedPartners);
        setToLocalStorage('addedPartners', updatedPartners);
      } catch (error) {
        console.error('Failed to fetch partners:', error);
      }
    }
  }, [currentUser?.isSuperUser, partnerName]);

  useEffect(() => {
    const storedPartners = getLocalStorage('addedPartners') || [];
    setPartners(storedPartners);
    addPartners();
  }, [addPartners]);

  const switchPartner = (partner: string) => {
    dispatch(setPartnerCustomer(partner));
   
    let newUrl = '/login';
    if (partner !== 'undefined') {
      sessionStorage.setItem('partnerCustomer', JSON.stringify(partner));
      newUrl += `?customer=${partner}`;
    }
  
    // Redirect to the login page with the partner name as a query parameter. If partner is 'undefined' then go back to the default login page
    history.push(newUrl);
  };
  
  //Add a partner id to the local storage
  const addPartnerId = (partner_id: string) => {
    if (!partner_id) return;
    const storedPartnerIds = getLocalStorage('partner_customer_id') || [];

    const existingEntry = storedPartnerIds.find(entry => entry[partnerName]);

    if (!existingEntry && partnerCustomer?.customer_name) {
      storedPartnerIds.push({ [partnerName]: partner_id });
      setToLocalStorage('partner_customer_id', storedPartnerIds);
    }
  };

  const getPartnerId = useCallback((): string | undefined => {
    const storedPartnerIds: Array<{ [key: string]: string }> = getLocalStorage('partner_customer_id') || [];
    const partnerEntry = storedPartnerIds.find(entry => entry[partnerName]);

    return partnerEntry ? partnerEntry[partnerName] : undefined;
  }, [partnerName]);

  return {
    partners,
    setPartners,
    addPartners,
    switchPartner,
    addPartnerId,
    getPartnerId,
  };
};

export default usePartnerSession;
