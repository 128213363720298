import { createSlice } from "@reduxjs/toolkit";
import { modalInitialState, modalReducers } from "providers/redux/modal";
import { PURGE } from "redux-persist";
import type { AppState } from "../../../../providers/redux/store";

const initialState = {
  modal: modalInitialState,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    ...modalReducers,
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      state = initialState;
    });
  },
});

export default usersSlice.reducer;

export const {
  toggleModal,
  setOpenModal,
  setModalData,
  setToast,
  setOpenToast,
  clearModalData,
} = usersSlice.actions;

export const selectModal = (state: AppState) => state[usersSlice.name].modal;
export const selectModalData = (state: AppState) =>
  state[usersSlice.name].modal.data;
export const selectIsModalOpened = (state: AppState) =>
  state[usersSlice.name].modal.isOpened;
export const selectIsToastOpen = (state: AppState) =>
  state[usersSlice.name].modal.toast.isOpen;
export const selectToastContent = (state: AppState) =>
  state[usersSlice.name].modal.toast.content;
export const selectSelectedCustomer = (state: AppState) =>
  state[usersSlice.name].selectedCustomer;
