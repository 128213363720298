import styled from 'styled-components';

export const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 520px;
  margin-top: 10px;
`;

export const Input = styled.input`
  width: 100%;
  height: 1.2rem;
  margin-top: 0;
  padding: 0.5em;
  border: 0;
  border-bottom: 2px solid gray;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;

  &:focus {
    border-bottom: 4px solid black;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 5.0 rem;
  margin-top: 0;
  padding: 0.5em;
  border: 0;
  border-bottom: 2px solid gray;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;

  &:focus {
    border-bottom: 4px solid black;
  }
`;

export const ResetButton = styled.input`
  width: auto;
  height: auto;
  border-bottom: 0;
  background-color: transparent;
  color: rgb(104, 104, 104);
  font-size: 14px;
`;

export const Title = styled.p`
  width: 100%;
  margin-block-end: 0;
  font-weight: 600;
  font-size: 24px;
`;

export const Note = styled.p`
  width: 100%;
  margin-block-start: 0;
  font-size: 12px;
`;

export const FormLabel = styled.label`
  width: 100%;
  padding: 0.5em;
`;

export const FullField = styled.div`
  flex: 400px;
  margin: 7px 0;
`;

export const SlimFieldLeft = styled.div`
  flex: 1 150px;
  margin: 15px 15px 15px 0px;
`;

export const SlimFieldRight = styled.div`
  flex: 1 150px;
  margin: 15px 0px 15px 15px;
`;

export const SaveButton = styled.button`
  background-color: #000;
  border-radius: 6px;
  color: #fff;
  padding: 6px 24px;
  text-decoration: none;

  &:hover {
    background-color: #666;
  }

  &:active {
    position: relative;
    top: 1px;
  }
`;
