import { useWindowSize } from 'react-use';
import { sizes } from './constants';

export function useBreakPoints() {
  const { width } = useWindowSize();
  const isMobile = width <= sizes.tablet;
  const isTablet = width <= sizes.desktop;
  const isDesktop = width > sizes.desktop;
  return { width, isMobile, isTablet, isDesktop };
}
