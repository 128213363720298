import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Container from "components/atomic/Container";
import { H2 } from "components/atomic/Headings";
import { AddBtn } from "components/molecule/AddBtn";
import { Filter } from "components/molecule/Filter";
import { SearchBar } from "components/molecule/SearchBar";
import { AddNewCustomerBtn } from "domain/customers/components/AddNewCustomerBtn";
import { selectIsModalOpened as selectIsCustomerModalOpend } from "domain/customers/lib/redux/customersSlice";
import { searchThroughRows } from "features/search";
import {
  DEVICE_STATUS_MODAL,
  selectModal,
  selectedModal,
} from "providers/redux/deviceStatusSlice";
import { selectCurrentUser } from "providers/redux/globalSlice";
import { isFilledArray } from "utils";
import {
  colTexts,
  displayedCols,
} from "../../../application/pages/DeviceStatusPage/constants";
import { filterByStatus } from "../../../application/pages/DeviceStatusPage/processors";
import { StatusTable as DeviceStatusTable } from "./DeviceStatusTable";
import { AddNewCustomerModal } from "./DeviceStatusTable/AddNewCustomerModal";
import { DeviceCreateModal } from "./DeviceStatusTable/DeviceCreateModal";
import { DeviceEditModal } from "./DeviceStatusTable/DeviceEditModal";
import { DeviceStatusDetailModal } from "./DeviceStatusTable/DeviceStatusDetailModal";
import { DeviceVersionUpdateModal } from "./DeviceStatusTable/DeviceVersionUpdateModal";
import { filterByPreconfigs } from "./processors";
import { DEVICE_STATUS_VALUES } from "features/kaidu-config-server";
import { LabeledSelect } from "components/molecule/Select";

/**
 * View for Device status data
 * Table can be filtered by selected customer, building, floor, location, status, and searched keywords
 */
export function DeviceStatusView({
  formattedRows,
  statusCodeAndCountMap,
  validConfigNum,
  details = [],
  ...optionals
}) {
  // calculated const
  // const totalNum = statusCodeAndCountMap?.total;
  // console.log(`Customer names: ${customerNames}`);

  //States
  const dispatch = useDispatch();
  const isCustomerModalOpend = useSelector(selectIsCustomerModalOpend);
  const currentUser = useSelector(selectCurrentUser);
  const modal = useSelector(selectedModal);
  const isAdminUser = useMemo(() => {
    const { isSuperUser, is_partner } = currentUser || {};
    return isSuperUser || is_partner;
  }, [currentUser]);
  const [selectedStatus, setDeviceStatus] = useState("all");
  const [searchedTerm, setSearchedTerm] = useState("");
  const [preconfigFilter, setPreconfigFilter] = useState(null);

  const processRows = useMemo(() => {
    // Update data list by selected customer, building, floor, location, status
    const filteredByPreconfigs = filterByPreconfigs(
      preconfigFilter,
      formattedRows
    );
    const statusFiltered = filterByStatus(filteredByPreconfigs, selectedStatus);
    // console.debug("statusFiltered", statusFiltered?.length);
    const searched = searchThroughRows(searchedTerm, statusFiltered);
    // console.debug("searched", searched?.length);
    return searched;
  }, [formattedRows, searchedTerm, selectedStatus, preconfigFilter]);

  return (
    <>
      <Container className="flex flex-no-wrap pt-4 mb-4 justify-between max-w-full md:flex-col xl:flex-col">
        <H2 className="min-w-min whitespace-nowrap flex-grow">Device Status</H2>
        <div className="flex">
          <Filter
            data={processRows}
            onChangeFilter={(filter) => setPreconfigFilter(filter)}
          />
          <div className="mr-2 flex items-center w-max">
            <LabeledSelect
              options={DEVICE_STATUS_VALUES}
              label="Status"
              id="select-device-status"
              onChange={(e) => setDeviceStatus(e.target.value)}
            />
          </div>
          <div className="flex gap-2">
            <AddNewCustomerBtn show />
            {isAdminUser && (
              <AddBtn
                onClick={() =>
                  dispatch(selectModal(DEVICE_STATUS_MODAL.ADD_DEVICE))
                }
              >
                Add New Scanner
              </AddBtn>
            )}
          </div>
        </div>
      </Container>
      <Container>
        <SearchBar
          className="mb-2"
          input={formattedRows}
          onChangeTerm={(searched) => {
            setSearchedTerm(searched);
          }}
        />
        {isFilledArray(processRows) ? (
          <>
            <DeviceStatusTable
              rows={processRows}
              cols={displayedCols}
              colTexts={colTexts}
            />
          </>
        ) : (
          <p>No data</p>
        )}
      </Container>
      {modal === DEVICE_STATUS_MODAL.DETAILS && <DeviceStatusDetailModal />}
      {modal === DEVICE_STATUS_MODAL.EDIT && <DeviceEditModal />}
      {modal === DEVICE_STATUS_MODAL.ADD_DEVICE && isAdminUser && (
        <DeviceCreateModal />
      )}
      {modal === DEVICE_STATUS_MODAL.VERSION_UPDATE && (
        <DeviceVersionUpdateModal />
      )}
      {isCustomerModalOpend && <AddNewCustomerModal />}
    </>
  );
}
