import axios from "axios";
import {
  CUSTOMERS_LIST_API,
  CUSTOMER_ITEM_ID_API,
  CustomersListItem,
} from "features/kaidu-config-server/customers-list";
import { BASE_API } from "lib/constants";
import { getErrorMessageInResponse } from "lib/kaidu-server/processor";
import { VerifyDashboardResponse } from "lib/services/dns-redirection";
import { handle } from "utils";
import { addIdsToCustomer, processSubmittedCustomer } from "../lib";

export async function postCustomer(data) {
  const addedIds = addIdsToCustomer(data);
  const submitted: CustomersListItem = processSubmittedCustomer(addedIds);
  // console.log(`Submitted data in postCustomer: ${inspect(data)}`);
  const [result, resultErr] = await handle(
    axios.post(CUSTOMERS_LIST_API, submitted)
  );
  if (resultErr) {
    const errMsgInResponse = getErrorMessageInResponse(resultErr);
    throw new Error(errMsgInResponse);
  }
  return result;
}

export async function putCustomer(data) {
  // console.log(`putCustomer called with input: ${inspect(data)}`);
  const submitted: CustomersListItem = processSubmittedCustomer(data);
  // console.log(`submitted in putCustomer: ${inspect(submitted)}`);

  const url = `${CUSTOMERS_LIST_API}/${submitted?.customer_id}`;

  const [result, resultErr] = await handle(axios.put(url, submitted));
  if (resultErr) {
    const errMsgInResponse = getErrorMessageInResponse(resultErr);
    throw new Error(errMsgInResponse);
  }
  return result;
}

export async function deleteCustomer(id) {
  const url = `${CUSTOMER_ITEM_ID_API}/${id}`;
  const [result, resultErr] = await handle(axios.delete(url));
  if (resultErr) {
    const errMsgInResponse = getErrorMessageInResponse(resultErr);
    throw new Error(errMsgInResponse);
  }
  return result;
}

export async function uploadImage(image: File): Promise<string> {
  const url = `${BASE_API}/upload`;
  const formData = new FormData();
  formData.append("image", image);
  const [result, resultErr] = await handle(axios.post(url, formData));
  if (resultErr) {
    const errMsgInResponse = getErrorMessageInResponse(resultErr);
    throw new Error(errMsgInResponse);
  }
  const { data } = result || { upload: undefined };
  return data.upload as string;
}

export async function verifyDashboardURL(dashboard_url:string, isManagementDashboard?:boolean): Promise<VerifyDashboardResponse> {
  const partner_dashboard: VerifyDashboardResponse = await axios
    .post(`${BASE_API}/verify_dashboard`, {
      dashboard_url,
      isManagementDashboard,
    })
    .then((response) => response.data);

  return partner_dashboard
}
