import React from 'react';
import { Button as BootstrapBtn } from 'react-bootstrap'; //https://react-bootstrap.github.io/components/buttons/

export function Button(props) {
  const { className, ...rest } = props;
  return (
    <>
      <BootstrapBtn className={`w-max ${className}`} {...rest} />
    </>
  );
}

export default Button