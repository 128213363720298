import { Button } from "components/atomic/Button";
import { Icon } from "components/atomic/Icon";
import { FirmwarePicker } from "./FirmwarePicker";

/**
 * Update button group
 */
export function FirmwareUpdateInputGroup({ register, name, ...optionals }) {
  const {
    className,
    isExpanded = false,
    onClick,
    disabled,
    firmwareList,
  } = optionals;

  const handleClickUpdateBtn = () => {
    onClick && onClick();
  };

  return (
    <div
      className={className}
      data-tip={disabled ? "Please wait until last update is finished" : null}
    >
      {!isExpanded ? (
        <Button
          variant="outline-primary"
          onClick={handleClickUpdateBtn}
          disabled={disabled}
        >
          Update
        </Button>
      ) : null}
      {isExpanded ? (
        <div className="flex items-center gap-x-2">
          <Icon name={"FaArrowAltCircleRight"} size={24} />
          <FirmwarePicker list={firmwareList} register={register} name={name} />
        </div>
      ) : null}
    </div>
  );
}
