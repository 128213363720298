import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free-solid";
import {
  FaTools,
  FaRegSquare,
  FaBluetooth,
  FaLayerGroup,
  FaInfoCircle,
  FaArrowUp,
  FaArrowDown,
  FaUserPlus,
  FaGlobe,
  FaArrowAltCircleRight,
  FaUpload,
} from "react-icons/fa";

export function Icon({ name, ...rest }) {
  if (name === "tools") {
    return <FaTools {...rest} />;
  } else if (name === "FaRegSquare") {
    return <FaRegSquare {...rest} />;
  } else if (name === "bluetooth") {
    return <FaBluetooth {...rest} />;
  } else if (name === "floor") {
    return <FaLayerGroup {...rest} />;
  } else if (name === "FaInfoCircle") {
    return <FaInfoCircle {...rest} />;
  } else if (name === "FaArrowUp") {
    return <FaArrowUp {...rest} />;
  } else if (name === "FaArrowDown") {
    return <FaArrowDown {...rest} />;
  } else if (name === "FaUserPlus") {
    return <FaUserPlus {...rest} />;
  } else if (name === "FaGlobe") {
    return <FaGlobe {...rest} />;
  } else if (name === "FaArrowAltCircleRight") {
    return <FaArrowAltCircleRight {...rest} />;
  } else if (name === "FaUpload") {
    return <FaUpload {...rest} />;
  } else {
    return <FontAwesomeIcon icon={name} {...rest} />;
  }
}

export default Icon;
