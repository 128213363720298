import { DefaultTheme } from 'styled-components';

const palette = {
  primary: '#1976d2',
  secondary: '#0160f3',
  tertiary: '#1C1D21',
  fourth: '#8181A5',
  fifth: '#2481cc',
  danger: '#d32f2f',
  alert: '#ffa000',
  success: '#388e3c',
  white: '#fff',
  grayscale: [
    '#212121',
    '#414141',
    '#616161',
    '#9e9e9e',
    '#bdbdbd',
    '#e0e0e0',
    '#eeeeee',
    '#ffffff',
  ],
  secondaryText: '#6C757D'
};

const fonts = {
  primary: 'Helvetica Neue, Helvetica, Roboto, sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
  quote: 'Georgia, serif',
};

export const sizes = {
  maxWidth: 1100,
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560
};

export const breakpoints = {
  sm: sizes.mobileL,
  md: sizes.tablet,
  lg: sizes.laptop,
  xl: sizes.laptopL,
};

export const device = {
  mobileS: createMinWidthMediaQuery(sizes.mobileS),
  mobileM: createMinWidthMediaQuery(sizes.mobileM),
  mobileL: createMinWidthMediaQuery(sizes.mobileL),
  tablet: createMinWidthMediaQuery(sizes.tablet),
  laptop: createMinWidthMediaQuery(sizes.laptop),
  laptopL: createMinWidthMediaQuery(sizes.laptopL),
  desktop: createMinWidthMediaQuery(sizes.laptopL),
};

function createMinWidthMediaQuery(size: number) {
  return `(min-width: ${size}px)`;
}

export const theme: DefaultTheme = {
  palette,
  colors: palette,
  fonts,
  sizes,
  breakpoints,
  device,
};



export const headingFontFamily = `
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
`;