import { BASE_API } from '../constants';
import axios from 'axios';
import { getErrorMessageInResponse } from 'features/kaidu-config-server/processor';
// import _ from 'lodash';
import { handleAxiosResultData } from '../processor';
import { handle } from 'utils';

const SUBPATH = '/iot_cloud';
export const IOT_CLOUD = `${BASE_API}${SUBPATH}`;
export const FIRMWARE_UPDATE = `${IOT_CLOUD}/update`;

/**
 * 
 */
export async function sendIoTCloudUpdateCommand(deviceId: string, versionMsg: string) {
  console.log(`deviceId: ${deviceId}, versionMsg: ${versionMsg}`);
  const url = FIRMWARE_UPDATE;
  const [result, resultErr] = await handle(
    axios.put(url, { deviceId, msg: versionMsg })
  );
  if (resultErr) {
    const errMsgInResponse = getErrorMessageInResponse(resultErr);
    throw new Error(errMsgInResponse);
  }
  return handleAxiosResultData(result);
}
